import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties } from '../../utilities';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';
import { createTupleOfUnionValidator } from '../../../UtilityTypes/createTupleOfUnionValidator/createTupleOfUnionValidator';

type FullUserRoleDto<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> &
  UserRolePermissionFields & {
    name: string | null;
    automaticallyAssignToNewUsers: boolean;
  };

export type UserRolePermissionFields = UserRoleGeneralPermissionFields &
  UserRoleEntitySpecificPermissionFields;

export type UserRoleEntitySpecificPermissionFields = ValidatePermissionFields<{
  canCreateDefectComments: boolean;
  canUpdateDefectComments: boolean;
  canDeleteDefectComments: boolean;
  canCreateDefects: boolean;
  canUpdateDefects: boolean;
  canDeleteDefects: boolean;
  canCreateEntries: boolean;
  canUpdateEntries: boolean;
  canDeleteEntries: boolean;
  canCreateMapLayers: boolean;
  canUpdateMapLayers: boolean;
  canDeleteMapLayers: boolean;
  canCreatePersonContacts: boolean;
  canUpdatePersonContacts: boolean;
  canDeletePersonContacts: boolean;
  canCreatePersons: boolean;
  canUpdatePersons: boolean;
  canDeletePersons: boolean;
  canCreatePersonToPersons: boolean;
  canUpdatePersonToPersons: boolean;
  canDeletePersonToPersons: boolean;
  canCreateProcessConfigurations: boolean;
  canUpdateProcessConfigurations: boolean;
  canDeleteProcessConfigurations: boolean;
  canCreateProcessConfigurationActionStates: boolean;
  canUpdateProcessConfigurationActionStates: boolean;
  canDeleteProcessConfigurationActionStates: boolean;
  canCreateProcessConfigurationAuthorizationTypes: boolean;
  canUpdateProcessConfigurationAuthorizationTypes: boolean;
  canDeleteProcessConfigurationAuthorizationTypes: boolean;
  canCreateProcessConfigurationCategories: boolean;
  canUpdateProcessConfigurationCategories: boolean;
  canDeleteProcessConfigurationCategories: boolean;
  canCreateProcessConfigurationDevices: boolean;
  canUpdateProcessConfigurationDevices: boolean;
  canDeleteProcessConfigurationDevices: boolean;
  canCreateProcessConfigurationDeviceExports: boolean;
  canUpdateProcessConfigurationDeviceExports: boolean;
  canDeleteProcessConfigurationDeviceExports: boolean;
  canCreateProcessConfigurationFollowUpAppointments: boolean;
  canUpdateProcessConfigurationFollowUpAppointments: boolean;
  canDeleteProcessConfigurationFollowUpAppointments: boolean;
  canCreateProcessConfigurationForms: boolean;
  canUpdateProcessConfigurationForms: boolean;
  canDeleteProcessConfigurationForms: boolean;
  canCreateProcessConfigurationFormSendTargets: boolean;
  canUpdateProcessConfigurationFormSendTargets: boolean;
  canDeleteProcessConfigurationFormSendTargets: boolean;
  canCreateProcessConfigurationMeasurePointTypes: boolean;
  canUpdateProcessConfigurationMeasurePointTypes: boolean;
  canDeleteProcessConfigurationMeasurePointTypes: boolean;
  canCreateProcessConfigurationSteps: boolean;
  canUpdateProcessConfigurationSteps: boolean;
  canDeleteProcessConfigurationSteps: boolean;
  canCreateProcessConfigurationStepAutoAppointments: boolean;
  canUpdateProcessConfigurationStepAutoAppointments: boolean;
  canDeleteProcessConfigurationStepAutoAppointments: boolean;
  canCreateProcessConfigurationStepAutoForms: boolean;
  canUpdateProcessConfigurationStepAutoForms: boolean;
  canDeleteProcessConfigurationStepAutoForms: boolean;
  canCreateProcessConfigurationStepPositions: boolean;
  canUpdateProcessConfigurationStepPositions: boolean;
  canDeleteProcessConfigurationStepPositions: boolean;
  canCreateProcessTaskInvoices: boolean;
  canUpdateProcessTaskInvoices: boolean;
  canDeleteProcessTaskInvoices: boolean;
  canCreateProcessTasks: boolean;
  canUpdateProcessTasks: boolean;
  canDeleteProcessTasks: boolean;
  canCreateProcessTaskAppointments: boolean;
  canUpdateProcessTaskAppointments: boolean;
  canDeleteProcessTaskAppointments: boolean;
  canCreateProcessTaskAppointmentContacts: boolean;
  canUpdateProcessTaskAppointmentContacts: boolean;
  canDeleteProcessTaskAppointmentContacts: boolean;
  canCreateProcessTaskAppointmentToUsers: boolean;
  canUpdateProcessTaskAppointmentToUsers: boolean;
  canDeleteProcessTaskAppointmentToUsers: boolean;
  canCreateProcessTaskChecklistEntries: boolean;
  canUpdateProcessTaskChecklistEntries: boolean;
  canDeleteProcessTaskChecklistEntries: boolean;
  canCreateProcessTaskComments: boolean;
  canUpdateProcessTaskComments: boolean;
  canDeleteProcessTaskComments: boolean;
  canCreateProcessTaskDevices: boolean;
  canUpdateProcessTaskDevices: boolean;
  canDeleteProcessTaskDevices: boolean;
  canCreateProcessTaskGroups: boolean;
  canUpdateProcessTaskGroups: boolean;
  canDeleteProcessTaskGroups: boolean;
  canCreateProcessTaskGroupAuthorizations: boolean;
  canUpdateProcessTaskGroupAuthorizations: boolean;
  canDeleteProcessTaskGroupAuthorizations: boolean;
  canCreateProcessTaskRecurringAppointments: boolean;
  canUpdateProcessTaskRecurringAppointments: boolean;
  canDeleteProcessTaskRecurringAppointments: boolean;
  canCreateProjects: boolean;
  canUpdateProjects: boolean;
  canDeleteProjects: boolean;
  canCreateReports: boolean;
  canUpdateReports: boolean;
  canDeleteReports: boolean;
  canCreateSharepointListColumnToThingMappingItems: boolean;
  canUpdateSharepointListColumnToThingMappingItems: boolean;
  canDeleteSharepointListColumnToThingMappingItems: boolean;
  canCreateTextBricks: boolean;
  canUpdateTextBricks: boolean;
  canDeleteTextBricks: boolean;
  canCreateTextBrickTemplates: boolean;
  canUpdateTextBrickTemplates: boolean;
  canDeleteTextBrickTemplates: boolean;
  canCreateThings: boolean;
  canUpdateThings: boolean;
  canDeleteThings: boolean;
  canCreateThingAuthorizations: boolean;
  canUpdateThingAuthorizations: boolean;
  canDeleteThingAuthorizations: boolean;
  canCreateThingGroups: boolean;
  canUpdateThingGroups: boolean;
  canDeleteThingGroups: boolean;
  canCreateThingToPersons: boolean;
  canUpdateThingToPersons: boolean;
  canDeleteThingToPersons: boolean;
  canCreateUserRoles: boolean;
  canUpdateUserRoles: boolean;
  canDeleteUserRoles: boolean;
  canCreateUserRoleToUsers: boolean;
  canUpdateUserRoleToUsers: boolean;
  canDeleteUserRoleToUsers: boolean;
}>;

export type UserRoleGeneralPermissionFields = ValidatePermissionFields<{
  canSeeEntitiesWithoutProcessTaskGroupAuthorization: boolean;
  canSeeEntitiesWithoutThingAuthorization: boolean;
  canSeePersonsWithoutExplicitAuthorization: boolean;
  canCreateProcessTaskAppointmentsInFieldCalendar: boolean;
  canCreateReportSignatures: boolean;
}>;

export const userRolePermissionFieldNames = createTupleOfUnionValidator<
  | keyof UserRoleEntitySpecificPermissionFields
  | keyof UserRoleGeneralPermissionFields
>()({
  tuple: [
    'canCreateDefectComments',
    'canUpdateDefectComments',
    'canDeleteDefectComments',
    'canCreateDefects',
    'canUpdateDefects',
    'canDeleteDefects',
    'canCreateEntries',
    'canUpdateEntries',
    'canDeleteEntries',
    'canCreateMapLayers',
    'canUpdateMapLayers',
    'canDeleteMapLayers',
    'canCreatePersonContacts',
    'canUpdatePersonContacts',
    'canDeletePersonContacts',
    'canCreatePersons',
    'canUpdatePersons',
    'canDeletePersons',
    'canCreatePersonToPersons',
    'canUpdatePersonToPersons',
    'canDeletePersonToPersons',
    'canCreateProcessConfigurations',
    'canUpdateProcessConfigurations',
    'canDeleteProcessConfigurations',
    'canCreateProcessConfigurationActionStates',
    'canUpdateProcessConfigurationActionStates',
    'canDeleteProcessConfigurationActionStates',
    'canCreateProcessConfigurationAuthorizationTypes',
    'canUpdateProcessConfigurationAuthorizationTypes',
    'canDeleteProcessConfigurationAuthorizationTypes',
    'canCreateProcessConfigurationCategories',
    'canUpdateProcessConfigurationCategories',
    'canDeleteProcessConfigurationCategories',
    'canCreateProcessConfigurationDevices',
    'canUpdateProcessConfigurationDevices',
    'canDeleteProcessConfigurationDevices',
    'canCreateProcessConfigurationDeviceExports',
    'canUpdateProcessConfigurationDeviceExports',
    'canDeleteProcessConfigurationDeviceExports',
    'canCreateProcessConfigurationFollowUpAppointments',
    'canUpdateProcessConfigurationFollowUpAppointments',
    'canDeleteProcessConfigurationFollowUpAppointments',
    'canCreateProcessConfigurationForms',
    'canUpdateProcessConfigurationForms',
    'canDeleteProcessConfigurationForms',
    'canCreateProcessConfigurationFormSendTargets',
    'canUpdateProcessConfigurationFormSendTargets',
    'canDeleteProcessConfigurationFormSendTargets',
    'canCreateProcessConfigurationMeasurePointTypes',
    'canUpdateProcessConfigurationMeasurePointTypes',
    'canDeleteProcessConfigurationMeasurePointTypes',
    'canCreateProcessConfigurationSteps',
    'canUpdateProcessConfigurationSteps',
    'canDeleteProcessConfigurationSteps',
    'canCreateProcessConfigurationStepAutoAppointments',
    'canUpdateProcessConfigurationStepAutoAppointments',
    'canDeleteProcessConfigurationStepAutoAppointments',
    'canCreateProcessConfigurationStepAutoForms',
    'canUpdateProcessConfigurationStepAutoForms',
    'canDeleteProcessConfigurationStepAutoForms',
    'canCreateProcessConfigurationStepPositions',
    'canUpdateProcessConfigurationStepPositions',
    'canDeleteProcessConfigurationStepPositions',
    'canCreateProcessTaskInvoices',
    'canUpdateProcessTaskInvoices',
    'canDeleteProcessTaskInvoices',
    'canCreateProcessTasks',
    'canUpdateProcessTasks',
    'canDeleteProcessTasks',
    'canCreateProcessTaskAppointments',
    'canUpdateProcessTaskAppointments',
    'canDeleteProcessTaskAppointments',
    'canCreateProcessTaskAppointmentContacts',
    'canUpdateProcessTaskAppointmentContacts',
    'canDeleteProcessTaskAppointmentContacts',
    'canCreateProcessTaskAppointmentToUsers',
    'canUpdateProcessTaskAppointmentToUsers',
    'canDeleteProcessTaskAppointmentToUsers',
    'canCreateProcessTaskChecklistEntries',
    'canUpdateProcessTaskChecklistEntries',
    'canDeleteProcessTaskChecklistEntries',
    'canCreateProcessTaskComments',
    'canUpdateProcessTaskComments',
    'canDeleteProcessTaskComments',
    'canCreateProcessTaskDevices',
    'canUpdateProcessTaskDevices',
    'canDeleteProcessTaskDevices',
    'canCreateProcessTaskGroups',
    'canUpdateProcessTaskGroups',
    'canDeleteProcessTaskGroups',
    'canCreateProcessTaskGroupAuthorizations',
    'canUpdateProcessTaskGroupAuthorizations',
    'canDeleteProcessTaskGroupAuthorizations',
    'canCreateProcessTaskRecurringAppointments',
    'canUpdateProcessTaskRecurringAppointments',
    'canDeleteProcessTaskRecurringAppointments',
    'canCreateProjects',
    'canUpdateProjects',
    'canDeleteProjects',
    'canCreateReports',
    'canUpdateReports',
    'canDeleteReports',
    'canCreateSharepointListColumnToThingMappingItems',
    'canUpdateSharepointListColumnToThingMappingItems',
    'canDeleteSharepointListColumnToThingMappingItems',
    'canCreateTextBricks',
    'canUpdateTextBricks',
    'canDeleteTextBricks',
    'canCreateTextBrickTemplates',
    'canUpdateTextBrickTemplates',
    'canDeleteTextBrickTemplates',
    'canCreateThings',
    'canUpdateThings',
    'canDeleteThings',
    'canCreateThingAuthorizations',
    'canUpdateThingAuthorizations',
    'canDeleteThingAuthorizations',
    'canCreateThingGroups',
    'canUpdateThingGroups',
    'canDeleteThingGroups',
    'canCreateThingToPersons',
    'canUpdateThingToPersons',
    'canDeleteThingToPersons',
    'canCreateUserRoles',
    'canUpdateUserRoles',
    'canDeleteUserRoles',
    'canCreateUserRoleToUsers',
    'canUpdateUserRoleToUsers',
    'canDeleteUserRoleToUsers',
    'canSeeEntitiesWithoutProcessTaskGroupAuthorization',
    'canSeeEntitiesWithoutThingAuthorization',
    'canSeePersonsWithoutExplicitAuthorization',
    'canCreateProcessTaskAppointmentsInFieldCalendar',
    'canCreateReportSignatures'
  ]
});

export type UserRoleDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullUserRoleDto<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullUserRoleDto<TId, TDate> = never
> = Omit<
  OptionalProperties<FullUserRoleDto<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

type ValidatePermissionFields<T> =
  T extends Record<string, boolean>
    ? T
    : 'PermissionFields can only contain booleans or this will break other code';
