import { autoinject } from 'aurelia-framework';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { FilterHelper } from '../../classes/FilterHelper';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { EditUserDialog } from '../../dialogs/edit-user-dialog/edit-user-dialog';
import { CreateUserDialog } from '../../dialogs/create-user-dialog/create-user-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { computed } from '../../hooks/computed';
import { currentUser, expression, model } from '../../hooks/dependencies';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { FileDownloadService } from '../../services/FileDownloadService';
import { UserUtils } from '../../classes/EntityManager/entities/User/UserUtils';
import { Logger } from '../../classes/Logger/Logger';
import { WebSocketHandlerError } from 'common/WebSocketEndpoints/WebSocketHandlerError/WebSocketHandlerError';

@autoinject()
export class edit_users {
  protected userSortOptions = UserUtils.sortOptions;
  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editUsers.exportToCsv',
      name: 'export-users-to-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'isOffline'
    }
  ];

  protected currentSortOption = this.userSortOptions.name;
  private userFilterString = '';

  protected sortedUsers: Array<User> = [];
  protected currentPageUsers: Array<User> = [];

  private pagination: Pagination<User> | null = null;
  private currentUserEditing: User | null = null;

  private isAttached = false;
  protected isMobile = false;
  protected isOnline = false;
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly currentUserService: CurrentUserService,
    private readonly socketService: SocketService,
    private readonly fileDownloadService: FileDownloadService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(currentUser())
  private get currentUser(): User | null {
    return this.currentUserService.getCurrentUser();
  }

  @computed(model(EntityName.User))
  protected get availableUsers(): Array<User> {
    return this.entityManager.userRepository.getAll();
  }

  @computed(expression('availableUsers'), expression('userFilterString'))
  protected get filteredUsers(): Array<User> {
    return FilterHelper.filterItems(
      this.availableUsers,
      (item) => item.username + ' ' + item.email,
      this.userFilterString
    );
  }

  @computed(expression('isOnline'))
  protected get isOffline(): boolean {
    return !this.isOnline;
  }

  protected handleCreateUserClick(): void {
    void CreateUserDialog.open({
      onDialogClosed: (user) => {
        if (user) this.goToUser(user);
      }
    });
  }

  protected handleEditUserClick(user: User): void {
    this.currentUserEditing = user;
    assertNotNullOrUndefined(
      this.currentUser,
      'cannot handleEditUserClick without currentUser'
    );

    void EditUserDialog.open({
      user: this.currentUserEditing,
      currentUser: this.currentUser,
      onDialogClosed: () => {
        assertNotNullOrUndefined(
          this.currentUserEditing,
          'cannot handleEditUserClick without currentUserEditing'
        );
        this.goToUser(this.currentUserEditing);
      }
    });
  }

  // ///////// View Helper

  protected async handleExportUsersToCsvFileClicked(): Promise<void> {
    Dialogs.waitDialogTk('generalPages.editUsers.csvExportStarted');

    try {
      const response =
        await this.socketService.exportCsvModuleEndpoints.exportUsers({
          filterString: this.userFilterString
        });
      Dialogs.closeAllDialogs();
      void this.fileDownloadService.downloadFile(
        response.filePath.replace(/\\/g, '/')
      );
    } catch (e) {
      if (e instanceof WebSocketHandlerError) {
        void Dialogs.errorDialogTk(
          'generalPages.editUsers.csvExportFailed',
          `WebsocketErrors.${e.type}`
        );
      } else {
        void Dialogs.errorDialogTk(
          'generalPages.editUsers.csvExportFailed',
          'WebsocketErrors.ERROR_UNKNOWN'
        );
        Logger.logError({ error: e });
      }
    }
  }

  protected goToUser(user: User): void {
    if (this.filteredUsers.indexOf(user) === -1) {
      this.userFilterString = '';
    }

    void ScrollHelper.autoScrollToListItem(
      `#edit-users--user-${user.id}`,
      this.pagination,
      user,
      () => this.isAttached
    );
  }
}
