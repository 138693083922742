import { autoinject } from 'aurelia-framework';
import { EntityInfoPathHandler } from '@record-it-npm/synchro-common';

import {
  ProcessTaskGroupHelper as CommonProcessTaskGroupHelper,
  AutomaticSynchronizationFromTo
} from 'common/Operations/ProcessTaskGroupHelper';

import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Utils } from '../../classes/Utils/Utils';
import {
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import {
  ProcessTaskAppointmentUtils,
  TimeStampRange
} from '../../classes/EntityManager/entities/ProcessTaskAppointment/ProcessTaskAppointmentUtils';
import { ProcessTaskRecurringAppointmentHelper } from 'common/EntityHelper/ProcessTaskRecurringAppointmentHelper';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { AppEntityRepository } from '../../classes/EntityManager/base/AppEntityRepository';

/**
 * manages if ProcessTaskGroups (+ their subentities) should be kept locally
 * also helps with the transition of temporary entities to a permanent state (e.g. user is assigning itself an appointment in the next 30 days)
 *
 * inject this in the app root and call the initSubscriptions function
 */
@autoinject()
export class ProcessTaskGroupSynchronizationManagerService {
  private readonly subscriptionManager: SubscriptionManager;

  /**
   * all entities which have a ProcessTaskGroupModel as an owner
   */
  private readonly processTaskGroupSubEntityInfos: Array<EntityInfo>;

  private currentUser: User | null = null;

  private dateInfoMap: ProcessTaskAppointmentDateInfoMap | null = null;

  /**
   * this map tracks all processTaskGroupIds which have been upgraded to a permanent state, so we can downgrade them back to being a temporary entity when necessary
   */
  private processTaskGroupIdToTemporaryGroupNameMap: Record<string, string> =
    {};

  private lastActualizationProcessTaskGroupIds: Array<string> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.entityManager = entityManager;

    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskGroupSubEntityInfos =
      this.createProcessTaskGroupSubEntityInfos();

    this.currentUser = null;
  }

  public initSubscriptions(): void {
    const updateDateInfoMapRateLimited = Utils.rateLimitFunction(
      this.updateDateInfoMap.bind(this),
      250
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToUser,
      updateDateInfoMapRateLimited,
      0
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateDateInfoMapRateLimited,
      0
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateDateInfoMapRateLimited();
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: ({ success }) => {
          if (success) {
            this.handleAfterActualization();
          }
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.ProcessTaskGroup,
        this.handleProcessTaskGroupIdUpgraded.bind(this)
      )
    );
  }

  public disposeSubscriptions(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  /**
   * mark the processTaskGroupIds to be temporarily available
   *
   * This is needed for cases where the ProcessTaskGroup is synchronized permanently, but shown on a page where it is needed at least as an temporary entity.
   * When the ProcessTaskGroup will not be available permanently anymore, it shouldn't be deleted, but downgraded to a temporary entity, so the currently visible page doesn't break (since it needs the entities)
   */
  public mapTemporaryGroupName(
    processTaskGroupIds: Array<string>,
    temporaryGroupName: string
  ): void {
    for (const id of processTaskGroupIds) {
      if (this.processTaskGroupIdToTemporaryGroupNameMap[id] == null) {
        this.processTaskGroupIdToTemporaryGroupNameMap[id] = temporaryGroupName;
      }
    }
  }

  public clearTemporaryGroupName(temporaryGroupName: string): void {
    const processTaskGroupIds = Object.keys(
      this.processTaskGroupSubEntityInfos
    );

    for (const id of processTaskGroupIds) {
      if (
        this.processTaskGroupIdToTemporaryGroupNameMap[id] ===
        temporaryGroupName
      ) {
        delete this.processTaskGroupIdToTemporaryGroupNameMap[id];
      }
    }
  }

  private updateDateInfoMap(): void {
    if (this.currentUser) {
      const allRelations =
        this.entityManager.processTaskAppointmentToUserRepository.getByUserId(
          this.currentUser.id
        );
      const relationsByAppointmentId = Utils.groupBy(
        allRelations,
        (r) => r.processTaskAppointmentId
      );

      const map: ProcessTaskAppointmentDateInfoMap = new Map();

      for (const [
        appointmentId,
        relations
      ] of relationsByAppointmentId.entries()) {
        map.set(
          appointmentId,
          ProcessTaskAppointmentDateInfoMapComputer.createDateInfo(relations)
        );
      }

      this.dateInfoMap = map;
    } else {
      this.dateInfoMap = null;
    }

    this.manageProcessTaskGroups();
  }

  private manageProcessTaskGroups(): void {
    if (!this.currentUser || !this.dateInfoMap) {
      return;
    }

    const fromTo =
      CommonProcessTaskGroupHelper.getAutomaticSynchronizationFromToDate();
    const map = this.generateProcessTaskGroupIdToHasAutomaticAppointmentMap(
      this.currentUser.id,
      fromTo
    );

    this.entityManager.processTaskGroupRepository
      .getAll()
      .forEach((processTaskGroup) => {
        this.manageProcessTaskGroup(processTaskGroup, map);
      });
  }

  private generateProcessTaskGroupIdToHasAutomaticAppointmentMap(
    userId: string,
    fromTo: AutomaticSynchronizationFromTo
  ): hasAutomaticAppointmentByProcessTaskGroupId {
    const map: hasAutomaticAppointmentByProcessTaskGroupId = {};

    const timestampRange: TimeStampRange = {
      from: fromTo.from.getTime(),
      to: fromTo.to.getTime()
    };

    this.entityManager.processTaskAppointmentRepository
      .getAll()
      .forEach((a) => {
        // since we only have dateInfos of the currentUserId, a check for the assignee is not needed here
        if (this.appointmentIsInDateRange(a, timestampRange)) {
          map[a.ownerProcessTaskGroupId] = true;
        }
      });

    this.entityManager.processTaskRecurringAppointmentRepository
      .getAll()
      .forEach((a) => {
        if (
          ProcessTaskRecurringAppointmentHelper.anyEntryIsInDateRange(
            a,
            timestampRange.from,
            timestampRange.to
          )
        ) {
          map[a.ownerProcessTaskGroupId] = true;
        }
      });

    return map;
  }

  private appointmentIsInDateRange(
    appointment: ProcessTaskAppointment,
    timestampRange: TimeStampRange
  ): boolean {
    const dateInfo = this.dateInfoMap?.get(appointment.id) ?? null;
    return ProcessTaskAppointmentUtils.overlapsWithTimestampRange(
      dateInfo,
      timestampRange
    );
  }

  private manageProcessTaskGroup(
    processTaskGroup: ProcessTaskGroup,
    map: hasAutomaticAppointmentByProcessTaskGroupId
  ): void {
    if (processTaskGroup.temporaryGroupName) {
      if (map[processTaskGroup.id]) {
        this.upgradeProcessTaskGroupToPermanent(processTaskGroup);
      }
    } else if (
      !map[processTaskGroup.id] &&
      this.lastActualizationProcessTaskGroupIds.includes(processTaskGroup.id)
    ) {
      const temporaryGroupName =
        this.processTaskGroupIdToTemporaryGroupNameMap[processTaskGroup.id];
      if (temporaryGroupName != null) {
        this.downgradeProcessTaskGroupToTemporary(
          processTaskGroup,
          temporaryGroupName
        );
      } else {
        this.entityManager.processTaskGroupRepository.removeLocally(
          processTaskGroup
        );
      }
    }
  }

  private upgradeProcessTaskGroupToPermanent(
    processTaskGroup: ProcessTaskGroup
  ): void {
    processTaskGroup.temporaryGroupName = null;
    this.entityManager.processTaskGroupRepository.updateLocally(
      processTaskGroup
    );

    this.processTaskGroupSubEntityInfos.forEach((info) => {
      this.upgradeTemporaryEntitiesToPermanent(info, processTaskGroup.id);
    });
  }

  private upgradeTemporaryEntitiesToPermanent(
    info: EntityInfo,
    processTaskGroupId: string
  ): void {
    const entities = info.repository.getAll();

    for (const entity of entities) {
      if (
        info.pathHandler.firstValue({ data: entity }) === processTaskGroupId
      ) {
        entity.temporaryGroupName = null;
        info.repository.updateLocally(entity);
      }
    }
  }

  private downgradeProcessTaskGroupToTemporary(
    processTaskGroup: ProcessTaskGroup,
    temporaryGroupName: string
  ): void {
    processTaskGroup.temporaryGroupName = temporaryGroupName;
    this.entityManager.processTaskGroupRepository.updateLocally(
      processTaskGroup
    );
    this.processTaskGroupSubEntityInfos.forEach((info) => {
      this.downgradeTemporaryEntitiesToTemporary(
        info,
        processTaskGroup.id,
        temporaryGroupName
      );
    });
  }

  private downgradeTemporaryEntitiesToTemporary(
    info: EntityInfo,
    processTaskGroupId: string,
    temporaryGroupName: string
  ): void {
    const entities = info.repository.getAll();

    for (const entity of entities) {
      if (
        info.pathHandler.firstValue({ data: entity }) === processTaskGroupId
      ) {
        entity.temporaryGroupName = temporaryGroupName;
        info.repository.updateLocally(entity);
      }
    }
  }

  private createProcessTaskGroupSubEntityInfos(): Array<EntityInfo> {
    const infos: Array<EntityInfo> = [];

    infos.push({
      pathHandler: new EntityInfoPathHandler({
        path: ['id'],
        entityInfo:
          this.entityManager.processTaskGroupRepository.getEntityInfo()
      }),
      repository: this.entityManager.processTaskGroupRepository
    });

    const referenceInfos =
      this.entityManager.entityRepositoryContainer.getEntityNameReferenceInfos(
        EntityName.ProcessTaskGroup
      );

    for (const referenceInfo of referenceInfos) {
      if (referenceInfo.fieldInfo.relativeSynchronization) {
        infos.push({
          pathHandler: new EntityInfoPathHandler<any, any>({
            path: referenceInfo.fieldInfo.path,
            entityInfo: referenceInfo.repository.getEntityInfo()
          }),
          repository: referenceInfo.repository
        });
      }
    }

    return infos;
  }

  private handleAfterActualization(): void {
    const lastActualizationInfo =
      this.entityManager.entityActualization.getLastSuccessfulActualizationInfo();
    if (lastActualizationInfo) {
      const processTaskGroupIds =
        lastActualizationInfo.relativeSynchronizationEntityIdsByEntityName.get(
          EntityName.ProcessTaskGroup
        );
      this.lastActualizationProcessTaskGroupIds = processTaskGroupIds
        ? Array.from(processTaskGroupIds)
        : [];
    } else {
      this.lastActualizationProcessTaskGroupIds = [];
    }
  }

  private handleProcessTaskGroupIdUpgraded(): void {
    this.lastActualizationProcessTaskGroupIds =
      this.lastActualizationProcessTaskGroupIds.map((id) => {
        const processTaskGroup =
          this.entityManager.processTaskGroupRepository.getByOriginalId(id);
        return processTaskGroup?.id ?? id;
      });
  }
}

type EntityInfo = {
  pathHandler: EntityInfoPathHandler<any, any>;
  repository: AppEntityRepository<any>;
};

type hasAutomaticAppointmentByProcessTaskGroupId = Record<string, boolean>;
