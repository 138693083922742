import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { thingEntityInfo } from '../../../../classes/EntityManager/entities/Thing/thingEntityInfo';
import { Thing } from '../../../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ThingAdapter implements EntityAdapter<Thing> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;
  private readonly currentUserService: CurrentUserService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private editableUserGroupIds: Set<string> | null = null;
  private thingSectionsSetting: boolean | null = null;
  private currentUser: User | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ThingAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ thingIdsWhereUserIsAuthorized }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingSections',
        (thingSections) => {
          this.thingSectionsSetting = thingSections ?? false;
          updateBindings();
        }
      )
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.editableUserGroupIds != null &&
      this.thingIdsWhereUserIsAuthorized != null &&
      this.thingSectionsSetting != null &&
      this.currentUser != null
    );
  }

  public canDeleteEntity(thing: Thing): boolean {
    if (thing.synchronizedExternally) {
      return false;
    }

    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) => permissions.getCanDeleteThings()
    });
  }

  public canEditField(thing: Thing): boolean {
    if (thing.synchronizedExternally) {
      return false;
    }

    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) => permissions.getCanUpdateThings()
    });
  }

  public canEditProperties(thing: Thing): boolean {
    if (thing.synchronizedExternally) {
      return false;
    }

    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) => permissions.getCanUpdateThings()
    });
  }

  public canEditPictures(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) => permissions.getCanUpdateThings()
    });
  }

  public canEditNfcTokens(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) => permissions.getCanUpdateThings()
    });
  }

  public canViewThingSections(_thing: Thing): boolean {
    return this.thingSectionsSetting ?? false;
  }

  public canCreateProjects(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) => permissions.getCanCreateProjects()
    });
  }

  public canCreateThingSections(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () => {
        if (this.thingSectionsSetting == null) {
          return false;
        }

        return (
          this.entityIsEditableUserGroup(thing) && this.thingSectionsSetting
        );
      }
    });
  }

  public canManageThingSectionProperties(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () => {
        if (this.thingSectionsSetting == null) {
          return false;
        }

        return (
          this.entityIsEditableUserGroup(thing) && this.thingSectionsSetting
        );
      }
    });
  }

  public canAdministerUserDefinedEntities(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () =>
        this.currentUser
          ? PermissionHelper.userHasPermission(
              this.currentUser,
              'canAdministerUserDefinedEntities'
            ) && this.entityIsEditableUserGroup(thing)
          : false
    });
  }

  public canAdministerValueCalculations(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () =>
        this.currentUser
          ? PermissionHelper.userHasPermission(
              this.currentUser,
              'canAdministerValueCalculations'
            ) && this.entityIsEditableUserGroup(thing)
          : false
    });
  }

  public canViewValueCalculationResults(): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canViewValueCalculationResults'
        )
      : false;
  }

  public canCreateThingToPersons(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateThingToPersons() &&
        this.userCanUsePersonExtension()
    });
  }

  public canContactPersons(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () => this.entityIsEditableUserGroup(thing)
    });
  }

  public canEditPersons(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () =>
        this.entityIsEditableUserGroup(thing) &&
        this.userCanUsePersonExtension()
    });
  }

  public canCreateThingAuthorizations(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateThingAuthorizations()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Thing,
    Thing
  > {
    return thingEntityInfo;
  }

  public canCreateDefects(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateDefects() &&
        PermissionHelper.userHasPermission(
          this.currentUser,
          'canUseDefectManagement'
        )
    });
  }

  public canCreateReports(thing: Thing): boolean {
    return this.checkPermission({
      thing,
      checkRolePermission: () => this.entityIsEditableUserGroup(thing)
    });
  }

  private entityIsEditableUserGroup(entity: Thing): boolean {
    if (!this.editableUserGroupIds) {
      return false;
    }

    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }

  private userCanUsePersonExtension(): boolean {
    return PermissionHelper.userHasPermission(
      this.currentUser,
      'canUsePersonExtension'
    );
  }

  private checkPermission({
    thing,
    checkRolePermission
  }: {
    thing: Thing;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkPermissionBasedOnThingId({
      thingId: thing.id,
      ownerUserGroupId: thing.ownerUserGroupId,
      thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ThingAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
  currentUserService: CurrentUserService;
};
