import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { PersonToPersonDto } from './PersonToPersonDto';

export function createPersonToPersonEntityInfo<
  TEntity extends PersonToPersonDto<string, string>
>(
  options: CreateEntityInfoOptions<PersonToPersonDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.PersonToPerson,
  TEntity
> {
  return {
    entityName: EntityName.PersonToPerson,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PersonToPersonDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        person1Id: {
          name: 'person1Id',
          ref: EntityName.Person,
          cascadeDeleteLocally: true
        },
        person2Id: {
          name: 'person2Id',
          ref: EntityName.Person,
          cascadeDeleteLocally: true
        },
        name: {
          name: 'name',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
