import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { DateUtils } from 'common/DateUtils';
import { DialogIconType } from 'common/Enums/DialogIconType';
import { GalleryThingHelper } from 'common/GalleryThing/GalleryThingHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { PictureIconConfig } from 'common/GalleryThing/GalleryThingIconHelper';

import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { GalleryThingPictureCreatorService } from '../../services/GalleryThingPictureCreatorService';
import { Utils } from '../../classes/Utils/Utils';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { UploadChoiceSelectedEvent } from '../../picture/picture-upload-area/picture-upload-area';
import { UploadChoiceSelectedRegionEvent } from '../gallery-thing-region-pictures-group/gallery-thing-region-pictures-group';
import { NewDateButtonClickedEvent } from '../gallery-thing-overview-action-bar/gallery-thing-picture-overview-action-bar/gallery-thing-picture-overview-action-bar';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GalleryThingPictureCaptureService } from '../../services/PictureSketchingService/GalleryThingPictureCaptureService';
import { ExportStartedEvent } from '../gallery-thing-filter/gallery-thing-picture-filter/gallery-thing-picture-filter';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { GalleryThingPictureFilterService } from '../../services/GalleryThingPictureFilterService/GalleryThingPictureFilterService';
import { GalleryThingPictureDataSource } from './GalleryThingPictureDataSource/GalleryThingPictureDataSource';
import { SocketService } from '../../services/SocketService';
import { GetGalleryThingPicturesOptions } from './GalleryThingPictureDataSource/strategies/GalleryThingPictureDataSourceStrategy';
import {
  GalleryThingPictureOverviewEntry,
  GalleryThingPictureOverviewEntryGroup
} from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { SingleSocketRequestService } from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { PictureFileUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileUploadService';
import {
  CurrentPageIndexChangedEvent,
  Pagination
} from '../../aureliaComponents/pagination/pagination';
import { ExpandableContainer } from '../../aureliaComponents/expandable-container/expandable-container';
import {
  ButtonType,
  GlobalCustomDialog,
  GlobalCustomDialogReturnObject
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ExifDataHelper } from '../../classes/Picture/ExifDataHelper';
import { GalleryThingPictureClickHandler } from '../../classes/GalleryThing/GalleryThingPictureClickHandler';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';

/**
 * @event export-started - triggered whenever the "export" button in the filter is clicked.
 */
@autoinject()
export class GalleryThingPictureOverview {
  @bindable public thing: Thing | null = null;
  @bindable public editable = false;
  @bindable public pictureFilter: GalleryThingPictureFilter | null = null;
  @bindable public pictureSelectionEnabled = false;

  /**
   * this array will not get cleared when the pictureSelection gets disabled,
   * but there will be no indicator if the picture is selected or not then
   */
  @bindable
  public selectedPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  protected isFilterVisible = true;
  protected expandableContainers: Array<ExpandableContainer> = [];

  protected maxPageIndex = 1;
  @observable protected currentPageSize = 10;
  @observable protected currentPageIndex = 1;
  protected pagination: Pagination<null> | null = null;

  private isAttached = false;

  private selectedLastDaysRange: number | null = null;
  private thingDateRangeText: string | null = null;

  @observable
  private pictureIconsConfiguration: Array<PictureIconConfig> | null = null;

  private basemapMap: BasemapMap | null = null;
  private mapIsInitialized = false;

  private canUseViaWildbach = false;
  private canUseDefectManagement = false;

  private useUltraRapidFireWidget = false;
  private groupByRegion = false;

  private availableProjects: Array<Project> = [];

  private pictureGroups: Array<GalleryThingPictureOverviewEntryGroup> = [];

  private projectJoinedChanged = 0;

  private subscriptionManager: SubscriptionManager;
  private projectHandleSubscriptionManager: SubscriptionManager;
  private galleryThingPictureDataSourceSubscriptionManager: SubscriptionManager;

  private permissionBindings: PermissionBindingHandle;

  private activeUserCompanySettingService: ActiveUserCompanySettingService;

  private galleryThingPictureDataSource: GalleryThingPictureDataSource | null =
    null;

  @subscribableLifecycle()
  private galleryThingPictureClickHandler: GalleryThingPictureClickHandler;

  private element: HTMLElement;
  protected isMobile: boolean = false;

  constructor(
    element: Element,
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly galleryThingPictureCreatorService: GalleryThingPictureCreatorService,
    private readonly galleryThingPictureCaptureService: GalleryThingPictureCaptureService,
    private readonly galleryThingPictureFilterService: GalleryThingPictureFilterService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly socketService: SocketService,
    private readonly computedValueService: ComputedValueService,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileUploadService: PictureFileUploadService,
    private readonly singleSocketRequestService: SingleSocketRequestService,
    private readonly permissionsService: PermissionsService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.projectHandleSubscriptionManager = subscriptionManagerService.create();
    this.galleryThingPictureDataSourceSubscriptionManager =
      subscriptionManagerService.create();

    this.activeUserCompanySettingService = activeUserCompanySettingService;

    this.permissionBindings = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseViaWildbach: 'canUseViaWildbach',
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });
    this.galleryThingPictureClickHandler = new GalleryThingPictureClickHandler({
      entityManager: this.entityManager,
      socketService: this.socketService,
      subscriptionManagerService: this.subscriptionManagerService,
      activeUserCompanySettingService,
      onProjectJoinedChanged: () => {
        this.projectJoinedChanged++;
      }
    });
  }

  protected attached(): void {
    this.permissionBindings.subscribe();
    this.isAttached = true;

    this.setupGalleryThingPictureDataSource();

    const updateProjectsRateLimited = Utils.rateLimitFunction(
      this.updateAvailableProjects.bind(this),
      250
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      updateProjectsRateLimited,
      0
    );

    this.subscriptionManager.subscribeToProjectJoinedChanged(() => {
      this.projectJoinedChanged++;
    });

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'ultraRapidFireWidget.useUltraRapidFireWidget',
        (useUltraRapidFireWidget) => {
          this.useUltraRapidFireWidget = useUltraRapidFireWidget;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'via.groupByRegion',
        (groupByRegion) => {
          this.groupByRegion = groupByRegion;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.pictureIconsConfiguration',
        (pictureIconsConfiguration) => {
          this.pictureIconsConfiguration = pictureIconsConfiguration;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isSmallMobile', (isSmallMobile) => {
        this.isMobile = isSmallMobile;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.projectHandleSubscriptionManager.disposeSubscriptions();
    this.galleryThingPictureDataSourceSubscriptionManager.disposeSubscriptions();

    this.isAttached = false;
    this.permissionBindings.unsubscribe();
  }

  protected thingChanged(): void {
    this.galleryThingPictureClickHandler.setThing(this.thing);
    if (this.isAttached) {
      this.setupGalleryThingPictureDataSource();
    }
  }

  protected editableChanged(): void {
    this.galleryThingPictureClickHandler.setEditable(this.editable);
  }

  protected addToExpandableContainers(element: HTMLElement): void {
    const container = Utils.getViewModelOfElement(
      element
    ) as ExpandableContainer;
    this.expandableContainers.push(container);
  }

  protected removeFromExpandableContainers(element: HTMLElement): void {
    const container = Utils.getViewModelOfElement(
      element
    ) as ExpandableContainer;
    const index = this.expandableContainers.indexOf(container);
    if (index !== -1) {
      this.expandableContainers.splice(index, 1);
    }
  }

  protected handleCurrentPageIndexChanged(
    event: CurrentPageIndexChangedEvent
  ): void {
    this.currentPageIndex = event.detail.page;
  }

  private updateAvailableProjects(): void {
    if (!this.thing) {
      this.availableProjects = [];
      return;
    }

    this.availableProjects =
      this.entityManager.projectRepository.getByThingIdAndTypeReverseSortedByName(
        this.thing.id,
        ProjectType.GALLERY
      );

    this.galleryThingPictureDataSource?.updateGetGalleryThingPicturesOptions(
      this.createGalleryThingPicturesOptions()
    );
  }

  private createGalleryThingPicturesOptions(): GetGalleryThingPicturesOptions {
    assertNotNullOrUndefined(
      this.thing,
      'cannot set galleryThingPictureHandle options without thing'
    );
    return {
      projects: this.availableProjects,
      includeDefects: this.canUseDefectManagement,
      thing: this.thing
    };
  }

  protected pictureFilterChanged(): void {
    if (!this.pictureFilter) return;
    this.galleryThingPictureDataSource?.setFilter(this.pictureFilter, {
      currentIndex: this.currentPageIndex,
      currentPageSize: this.currentPageSize
    });
  }

  /**
   * Adds an empty picture group.
   * Since it may be possible that the user tries to create a picture group for a date and that date already exists,
   * we can't create a new project every time. Instead, we just simply use the creatorService to retrieve the
   * one for the current date (which will create a new project anyway if none is found).
   */
  private getOrCreateEmptyPictureGroup(date: Date): Project {
    assertNotNullOrUndefined(
      this.thing,
      'Cannot create an empty picture group without a thing!'
    );

    return this.galleryThingPictureCreatorService.getOrCreateProjectForDate(
      this.thing,
      date
    );
  }

  /**
   * @param {string} projectId
   * @param {number} _projectJoinedChanged - only to update view correctly
   * @returns {boolean}
   */
  protected projectJoined(
    projectId: string,
    _projectJoinedChanged: number
  ): boolean {
    return this.entityManager.joinedProjectsManager.projectIsJoined(projectId);
  }

  protected projectJoinedPermanently(
    projectId: string,
    _projectJoinedChanged: number
  ): boolean {
    return this.entityManager.joinedProjectsManager.projectIsJoinedPermanently(
      projectId
    );
  }

  private handlePictureClick(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    if (this.pictureSelectionEnabled) {
      this.toggleSelectedPictures(pictureOverviewEntry);
      return;
    }

    this.galleryThingPictureClickHandler.handlePictureClicked(
      pictureOverviewEntry
    );
  }

  private toggleSelectedPictures(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    const index =
      this.selectedPictureOverviewEntries.indexOf(pictureOverviewEntry);
    if (index >= 0) {
      this.selectedPictureOverviewEntries.splice(index, 1);
    } else {
      this.selectedPictureOverviewEntries.push(pictureOverviewEntry);
    }
  }

  /**
   * @param {Object} pictureOverviewEntry
   * @param {number} [_selectedPicturesLength] - this is just here so the view can update correctly
   * @returns {boolean}
   */
  private pictureIsSelected(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry,
    _selectedPicturesLength: number
  ): boolean {
    return (
      this.selectedPictureOverviewEntries.indexOf(pictureOverviewEntry) >= 0
    );
  }

  private handleMapInitialized(): void {
    this.mapIsInitialized = true;
    this.updateMapMarkers();
  }

  private updateMapMarkers(): void {
    if (!this.mapIsInitialized || !this.basemapMap || !this.thing) return;
    if (!this.canUseViaWildbach) return;
    const markers = this.pictureGroups
      .map((pg) => pg.pictureOverviewEntries)
      .flat()
      .flatMap(({ baseMapMarker }) =>
        baseMapMarker === null ? [] : baseMapMarker
      );
    this.basemapMap.setMarkers(markers);
  }

  private handleProjectDescriptionChanged(project: Project): void {
    this.entityManager.projectRepository.update(project);
  }

  private async handleUploadChoiceSelected(
    event: UploadChoiceSelectedEvent | UploadChoiceSelectedRegionEvent,
    projectId: string
  ): Promise<void> {
    const regionId = 'regionId' in event.detail ? event.detail.regionId : null;
    switch (event.detail.method) {
      case 'file':
        if (event.detail.files)
          await this.uploadFiles(event.detail.files, projectId, regionId);
        break;

      case 'camera':
        this.handleCameraMethod(projectId, regionId);
        break;

      case 'urfm':
        this.handleUltraRapidFireModeMethod(projectId, regionId);
        break;

      case 'sketch':
        await this.handleSketchMethod(projectId, regionId);
        break;

      default:
        throw new Error(`unknown method "${event.detail.method}"`);
    }
  }

  private async uploadFiles(
    files: Array<File>,
    projectId: string,
    regionId?: string | null
  ): Promise<void> {
    for (const file of files) {
      await this.uploadFile(file, projectId, regionId);
    }
  }

  private async uploadFile(
    file: File,
    projectId?: string,
    regionId?: string | null
  ): Promise<void> {
    if (!this.thing) return;

    await this.galleryThingPictureCreatorService.createPictureFromFile(
      file,
      {
        thing: this.thing,
        regionId: regionId || null,
        coords: await ExifDataHelper.getCoordinatesFromExifData(file)
      },
      projectId,
      false
    );
  }

  private handleCameraMethod(projectId: string, regionId: string | null): void {
    if (!this.thing) return;

    this.galleryThingPictureCaptureService.capturePictureWithCaptureWidget(
      {
        thing: this.thing,
        regionId: regionId
      },
      projectId
    );
  }

  private handleUltraRapidFireModeMethod(
    projectId: string,
    regionId: string | null
  ): void {
    if (!this.thing) return;

    this.galleryThingPictureCreatorService.capturePictureWithUltraRapidFireWidget(
      {
        thing: this.thing,
        regionId: regionId
      },
      projectId
    );
  }

  private async handleSketchMethod(
    projectId: string,
    regionId: string | null
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.thing,
      "can't GalleryThingPictureOverview.handleSketchMethod without a thing"
    );

    await this.galleryThingPictureCreatorService.createWhitePicture(
      {
        thing: this.thing,
        regionId: regionId
      },
      projectId
    );
  }

  protected handleActionBarNewDateClick(
    event: NewDateButtonClickedEvent
  ): void {
    this.getOrCreateEmptyPictureGroup(event.detail.date);
  }

  protected async handleDeleteProjectButtonClick(
    project: Project
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.thing,
      'cannot find same-date defects of project upon deletion request without thing'
    );
    const projectDate = GalleryThingHelper.getDateFromProjectName(
      project.name || ''
    );
    const defectsOfProjectDate =
      this.entityManager.defectRepository.getByCreationDateAndOwnerThingId(
        projectDate,
        this.thing.id
      );
    try {
      await this.deleteViaProjectDialog(
        DateUtils.formatToDateString(projectDate),
        defectsOfProjectDate
      );
    } catch (error) {
      if (error instanceof Error && error.message === 'DialogCanceled') {
        return;
      } else throw error;
    }

    this.entityManager.projectRepository.delete(project);
  }

  protected handleExportPanelExportStarted(event: ExportStartedEvent): void {
    DomEventHelper.fireEvent<ExportStartedEvent>(this.element, {
      name: 'export-started',
      detail: event.detail
    });
  }

  protected handleGalleryThingFilterReset(): void {
    this.pagination?.goToFirstPage();
  }

  protected currentPageSizeChanged(): void {
    this.pictureFilterChanged();
  }

  protected currentPageIndexChanged(): void {
    this.pictureFilterChanged();
  }

  protected updateMaxPageIndex(resultCount: number): void {
    this.maxPageIndex = Math.ceil(resultCount / this.currentPageSize) || 1;
  }

  private deleteViaProjectDialog(
    projectName: string,
    defects?: Array<Defect>
  ): Promise<GlobalCustomDialogReturnObject> {
    let text = this.i18n.tr('classes.Dialogs.deleteEntityText', {
      entityName: projectName
    });

    if (defects?.length) {
      const defectNumbers = defects
        .map((d) => 'MGL' + d.sequenceNumber)
        .join(', ');
      text += this.i18n.tr(
        'galleryThing.pictureOverview.deleteRequestForProjectWithDefects',
        { defectNumbers }
      );
    }

    return GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.deleteEntityTitle',
      text: text,
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'classes.Dialogs.deleteEntityButtonText',
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  private setupGalleryThingPictureDataSource(): void {
    this.galleryThingPictureDataSourceSubscriptionManager.disposeSubscriptions();

    if (!this.thing) {
      this.galleryThingPictureDataSource = null;
      return;
    }

    this.galleryThingPictureDataSource = new GalleryThingPictureDataSource({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      galleryThingPictureFilterService: this.galleryThingPictureFilterService,
      activeUserCompanySettingService: this.activeUserCompanySettingService,
      computedValueService: this.computedValueService,
      pictureFilePathService: this.pictureFilePathService,
      pictureFileUploadService: this.pictureFileUploadService,
      socketService: this.socketService,
      singleSocketRequestService: this.singleSocketRequestService,
      galleryThingPictureCreatorService: this.galleryThingPictureCreatorService,
      baseMapDataCallbacks: {
        onMarkersChanged: this.updateMapMarkers.bind(this),
        onMarkerClicked: this.handlePictureClick.bind(this)
      },
      updatePaginationMaxIndex: this.updateMaxPageIndex.bind(this),
      permissionsService: this.permissionsService,
      pictureFileByActivePictureRevisionService:
        this.pictureFileByActivePictureRevisionService
    });
    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      this.galleryThingPictureDataSource
    );

    this.updateAvailableProjects();
    this.pictureFilterChanged();

    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      ...this.galleryThingPictureDataSource.bindFilteredPictureGroups(
        (pictureGroups) => {
          this.pictureGroups = pictureGroups;
        }
      )
    );

    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (isConnected) {
          void this.galleryThingPictureDataSource?.useServerStrategy();
        } else {
          void this.galleryThingPictureDataSource?.useEntityManagerStrategy();
        }
      })
    );
  }
}
