import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';

import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GalleryThingPicture } from '../../classes/EntityManager/entities/Picture/types';
import { expression, model } from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';
import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { GalleryThingPictureDataSource } from '../gallery-thing-picture-overview/GalleryThingPictureDataSource/GalleryThingPictureDataSource';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFileUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileUploadService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { GalleryThingPictureCreatorService } from '../../services/GalleryThingPictureCreatorService';
import { GalleryThingPictureFilterService } from '../../services/GalleryThingPictureFilterService/GalleryThingPictureFilterService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SingleSocketRequestService } from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { GalleryThingPictureClickHandler } from '../../classes/GalleryThing/GalleryThingPictureClickHandler';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';

@autoinject()
export class GalleryThingPlanBasedOverview {
  @bindable public thing: Thing | null = null;
  @bindable public pictureFilter: GalleryThingPictureFilter | null = null;
  @bindable public editable = false;

  protected pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  private selectedPicture: GalleryThingPicture | null = null;
  private galleryThingPictureDataSource: GalleryThingPictureDataSource | null =
    null;

  private viaThingPictureSelectOptions: Array<PictureSelectOption> = [];

  private canUseDefectManagement = false;

  private readonly galleryThingPictureDataSourceSubscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  private isAttached: boolean = false;

  @subscribableLifecycle()
  private galleryThingPictureClickHandler: GalleryThingPictureClickHandler;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly galleryThingPictureCreatorService: GalleryThingPictureCreatorService,
    private readonly galleryThingPictureFilterService: GalleryThingPictureFilterService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly socketService: SocketService,
    private readonly computedValueService: ComputedValueService,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileUploadService: PictureFileUploadService,
    private readonly singleSocketRequestService: SingleSocketRequestService,
    private readonly permissionsService: PermissionsService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    permissionBindingService: PermissionBindingService
  ) {
    this.galleryThingPictureDataSourceSubscriptionManager =
      subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });
    this.galleryThingPictureClickHandler = new GalleryThingPictureClickHandler({
      entityManager: this.entityManager,
      socketService: this.socketService,
      subscriptionManagerService: this.subscriptionManagerService,
      activeUserCompanySettingService,
      onProjectJoinedChanged: () => {
        () => {};
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;
    this.permissionBindingHandle.subscribe();
    this.setupGalleryThingPictureDataSource();
  }

  protected detached(): void {
    this.isAttached = false;
    this.galleryThingPictureDataSourceSubscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  protected thingChanged(): void {
    this.galleryThingPictureClickHandler.setThing(this.thing);
    this.updateViaThingPictureSelectOptions();
    this.automaticallySetSelectedPicture();

    if (this.isAttached) this.setupGalleryThingPictureDataSource();
  }

  protected editableChanged(): void {
    this.galleryThingPictureClickHandler.setEditable(this.editable);
  }

  @watch(model(EntityName.Picture))
  protected pictureModelChanged(): void {
    this.updateViaThingPictureSelectOptions();
    if (!this.selectedPicture) this.automaticallySetSelectedPicture();
  }

  protected pictureFilterChanged(): void {
    if (!this.pictureFilter || !this.selectedPicture) return;
    this.galleryThingPictureDataSource?.setFilter(
      { ...this.pictureFilter, hasMarkingOnPictureId: this.selectedPicture.id },
      {
        currentIndex: 1,
        currentPageSize: Number.MAX_SAFE_INTEGER
      }
    );
  }

  @watch(
    model(EntityName.Project),
    expression('canUseDefectManagement'),
    expression('thing')
  )
  protected updateGetGalleryThingPicturesOptions(): void {
    if (!this.thing) return;

    const projects =
      this.entityManager.projectRepository.getByThingIdAndTypeReverseSortedByName(
        this.thing.id,
        ProjectType.GALLERY
      );

    this.galleryThingPictureDataSource?.updateGetGalleryThingPicturesOptions({
      projects,
      includeDefects: this.canUseDefectManagement,
      thing: this.thing
    });
  }

  protected handleMarkerClicked(
    pictureOverviewEntry: GalleryThingPictureOverviewEntry
  ): void {
    this.galleryThingPictureClickHandler.handlePictureClicked(
      pictureOverviewEntry
    );
  }

  private updateViaThingPictureSelectOptions(): void {
    if (!this.thing) {
      this.viaThingPictureSelectOptions = [];
      return;
    }

    this.viaThingPictureSelectOptions = this.entityManager.pictureRepository
      .getByGalleryThingId(this.thing.id)
      .filter((plan) => !!plan.location_info)
      .map((picture) => ({
        label:
          picture.description ??
          this.i18n.tr(
            'galleryThing.galleryThingPlanBasedOverview.pictureHasNoDescription'
          ),
        picture: picture
      }));
  }

  private automaticallySetSelectedPicture(): void {
    this.selectedPicture =
      this.viaThingPictureSelectOptions[0]?.picture ?? null;
  }

  private setupGalleryThingPictureDataSource(): void {
    this.galleryThingPictureDataSourceSubscriptionManager.disposeSubscriptions();

    if (!this.thing) {
      this.galleryThingPictureDataSource = null;
      return;
    }

    this.galleryThingPictureDataSource = new GalleryThingPictureDataSource({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      galleryThingPictureFilterService: this.galleryThingPictureFilterService,
      activeUserCompanySettingService: this.activeUserCompanySettingService,
      computedValueService: this.computedValueService,
      pictureFilePathService: this.pictureFilePathService,
      pictureFileUploadService: this.pictureFileUploadService,
      socketService: this.socketService,
      singleSocketRequestService: this.singleSocketRequestService,
      galleryThingPictureCreatorService: this.galleryThingPictureCreatorService,
      baseMapDataCallbacks: {
        onMarkersChanged: () => {},
        onMarkerClicked: () => {}
      },
      updatePaginationMaxIndex: () => {},
      permissionsService: this.permissionsService,
      pictureFileByActivePictureRevisionService:
        this.pictureFileByActivePictureRevisionService
    });
    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      this.galleryThingPictureDataSource
    );

    this.updateGetGalleryThingPicturesOptions();
    this.pictureFilterChanged();

    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      ...this.galleryThingPictureDataSource.bindFilteredPictureGroups(
        (pictureGroups) => {
          this.pictureOverviewEntries = pictureGroups
            .map((pg) => pg.pictureOverviewEntries)
            .flat();
        }
      )
    );

    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (isConnected) {
          void this.galleryThingPictureDataSource?.useServerStrategy();
        } else {
          void this.galleryThingPictureDataSource?.useEntityManagerStrategy();
        }
      })
    );
  }
}

type PictureSelectOption = {
  picture: GalleryThingPicture;
  label: string;
};
