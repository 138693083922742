import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { DefectComment } from '../../../../classes/EntityManager/entities/DefectComment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { defectCommentEntityInfo } from '../../../../classes/EntityManager/entities/DefectComment/defectCommentEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';

export class DefectCommentAdapter implements EntityAdapter<DefectComment> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> | null = null;
  private currentUser: User | null = null;

  constructor(options: DefectCommentAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ thingIdsWhereUserIsAuthorized }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.roleBasedPermissions != null && this.currentUser != null;
  }

  public canDeleteEntity(defectComment: DefectComment): boolean {
    return this.checkPermission({
      defectComment,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteDefectComments()
    });
  }

  public canEditField(defectComment: DefectComment): boolean {
    return this.checkPermission({
      defectComment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateDefectComments()
    });
  }

  public canEditGeneralFiles(defectComment: DefectComment): boolean {
    return this.checkPermission({
      defectComment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateDefectComments()
    });
  }

  public canEditPictures(defectComment: DefectComment): boolean {
    return this.checkPermission({
      defectComment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateDefectComments()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.DefectComment,
    DefectComment
  > {
    return defectCommentEntityInfo;
  }

  private checkPermission({
    defectComment,
    checkRolePermission
  }: {
    defectComment: DefectComment;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkDefectSubEntityPermission({
      entityManager: this.entityManager,
      entity: defectComment,
      currentUser: this.currentUser,
      roleBasedPermissions: this.roleBasedPermissions,
      thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
      checkRolePermission
    });
  }
}

export type DefectCommentAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
