import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import {
  TZoomBoxResizerHelperPicturePositionInfo,
  ZoomBoxPictureResizer
} from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { ContentClickedEvent } from '../../aureliaComponents/zoom-box/zoom-box';
import { SelectionMode } from '../picture-revision-comparison-full-screen-dialog/picture-revision-comparison-full-screen-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PictureRevisionMarkingsCalculator } from '../picture-revision-comparison-full-screen-dialog/PictureRevisionMarkingsCalculator';

@autoinject()
export class PictureRevisionComparisonPicture {
  @bindable protected picture: Picture | null = null;
  @bindable protected pictureRevision: PictureRevision | null = null;
  @bindable protected pictureMarkings: Array<IPictureMarking> = [];
  @bindable protected positionedMarkings: Array<IPictureMarking> = [];

  @bindable protected requiredSelectionMode: SelectionMode = SelectionMode.NONE;
  @bindable protected currentSelectionMode: SelectionMode = SelectionMode.NONE;

  @bindable protected referencePointData: MarkingCorrectionReferencePointData =
    {
      point1: null,
      point2: null,
      point3: null,
      allSet: false,
      anySet: false,
      transformationMatrix: null
    };

  private readonly zoomBoxElement: HTMLElement | null = null;
  private pictureElement: HTMLElement | null = null;
  private pictureResizer: ZoomBoxPictureResizer | null = null;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public resetReferencePointData(): void {
    this.referencePointData = this.createDefaultReferencePointData();
  }

  private createDefaultReferencePointData(): MarkingCorrectionReferencePointData {
    return {
      point1: null,
      point2: null,
      point3: null,
      anySet: false,
      allSet: false,
      transformationMatrix: null
    };
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.pictureElement,
      "can't PictureFullScreenOverlay.handleContentAttached without pictureElementToCompareAndEdit"
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      "can't PictureFullScreenOverlay.handleContentAttached without zoomBoxElementToCompareAndEdit"
    );
    this.pictureResizer = new ZoomBoxPictureResizer(
      this.pictureElement,
      this.zoomBoxElement
    );
    this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
      this.picturePositionInfo = picturePositionInfo;
    });
    this.pictureResizer.update();

    // temporary
    this.subscriptionManager.subscribeToExpression(
      this,
      'referencePointData.transformationMatrix',
      this.updateMarkingPositions.bind(this)
    );
    this.updateMarkingPositions();
  }

  protected detached(): void {
    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }
  }

  protected handleZoomBoxContentClicked(event: ContentClickedEvent): void {
    if (
      this.currentSelectionMode !== this.requiredSelectionMode ||
      this.referencePointData.allSet
    )
      return;

    assertNotNullOrUndefined(
      this.picturePositionInfo,
      "can't compute marking position without picturePositionInfo"
    );

    const marking =
      PictureRevisionMarkingsCalculator.computeMarkerOnImageFromPixelPositionVector(
        event.detail.position,
        this.picturePositionInfo
      );
    this.setNextReferencePoint(marking);
  }

  // TODO: when transformation is saved
  // on open, update of picture, Project, Picture, PictureRevision
  // subscriptionManager.subscribeToModelChanges(EntityName.Project/EntityName.Picture/EntityName.PictureRevision, updateMarkingPositions);
  private updateMarkingPositions(): void {
    if (!this.referencePointData.transformationMatrix) {
      this.positionedMarkings = this.pictureMarkings;
      console.log(this.positionedMarkings);
      return;
    }

    this.positionedMarkings = [];

    for (const marking of this.pictureMarkings) {
      const updatedMarking =
        PictureRevisionMarkingsCalculator.computeMarkingPositionsBasedOnTransformationMatrix(
          marking,
          this.referencePointData.transformationMatrix
        );

      this.positionedMarkings.push(updatedMarking);
    }
  }

  private setNextReferencePoint(marking: IPictureMarking): void {
    if (!this.referencePointData.point1) {
      this.referencePointData.point1 = marking;
      this.referencePointData.anySet = true;
    } else if (!this.referencePointData.point2) {
      this.referencePointData.point2 = marking;
    } else if (!this.referencePointData.point3) {
      this.referencePointData.point3 = marking;
      this.referencePointData.allSet = true;
    }
  }
}

export type MarkingCorrectionReferencePointData = {
  point1: IPictureMarking | null;
  point2: IPictureMarking | null;
  point3: IPictureMarking | null;
  allSet: boolean;
  anySet: boolean;
  transformationMatrix: Array<number> | null;
};
