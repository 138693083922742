import { User } from './types';
import { SorterSortOption } from '../../../../aureliaAttributes/sorter';

export class UserUtils {
  private constructor() {}

  public static sortOptions: {
    name: SorterSortOption<User>;
    status: SorterSortOption<User>;
    subscription: SorterSortOption<User>;
  } = {
    name: {
      name: 'Name',
      sortFunction: (a, b) => {
        const aUsername = a.username ? a.username : '';
        const bUsername = b.username ? b.username : '';
        return bUsername.localeCompare(aUsername);
      }
    },
    status: {
      name: 'Status',
      sortFunction: (a, b) => {
        const aIsAdmin = a.admin ? 1 : 0;
        const bIsAdmin = b.admin ? 1 : 0;
        return aIsAdmin - bIsAdmin;
      }
    },
    subscription: {
      name: 'Abonnement',
      sortFunction: (a, b) => {
        const aSubscription =
          a.permissions && a.permissions.canUseBasic
            ? new Date(a.permissions.canUseBasic).getTime()
            : Number.MAX_SAFE_INTEGER;
        const bSubscription =
          b.permissions && b.permissions.canUseBasic
            ? new Date(b.permissions.canUseBasic).getTime()
            : Number.MAX_SAFE_INTEGER;
        return aSubscription - bSubscription;
      }
    }
  };
}
