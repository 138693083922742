import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskAppointmentToUserEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/processTaskAppointmentToUserEntityInfo';
import { ProcessTaskAppointmentToUser } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskAppointmentToUserAdapter
  implements EntityAdapter<ProcessTaskAppointmentToUser>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ProcessTaskAppointmentToUserAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(
    processTaskAppointmentToUser: ProcessTaskAppointmentToUser
  ): boolean {
    return this.checkPermission({
      processTaskAppointmentToUser,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskAppointmentToUsers()
    });
  }

  public canEditField(
    processTaskAppointmentToUser: ProcessTaskAppointmentToUser
  ): boolean {
    return this.checkPermission({
      processTaskAppointmentToUser,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskAppointmentToUsers()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskAppointmentToUser,
    ProcessTaskAppointmentToUser
  > {
    return processTaskAppointmentToUserEntityInfo;
  }

  private checkPermission({
    processTaskAppointmentToUser,
    checkRolePermission
  }: {
    processTaskAppointmentToUser: ProcessTaskAppointmentToUser;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskSubEntityPermission({
      entity: processTaskAppointmentToUser,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskAppointmentToUserAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
