import { SvgCleaner } from './Svg/SvgCleaner';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DataUrlReader } from './Reader/DataUrlReader/DataUrlReader';

export class ImageHelper {
  /**
   * make sure both images have the same ratio because the second image will completely cover the first one
   *
   * returns a dataUrl of type image/jpeg
   */
  public static mergeImages(
    imageElement: HTMLImageElement,
    imageElement2: HTMLImageElement
  ): string {
    const canvas = document.createElement('canvas');
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, 'drawing context does not exist');

    ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
    ctx.drawImage(imageElement2, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL('image/jpeg');
  }

  /**
   * the result is the canvas drawn over the image (it will get stretched, so make sure both have the same ration)
   *
   * returns a dataUrl of type image/jpeg
   */
  public static mergeImageAndCanvas(
    imageElement: HTMLImageElement,
    canvasElement: HTMLCanvasElement
  ): string {
    const canvas = document.createElement('canvas');
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, 'drawing context does not exist');

    ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
    ctx.drawImage(canvasElement, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL('image/jpeg');
  }

  /**
   * this function returns a dataUrl of type image/jpeg of the currently show image in the videoElement
   */
  public static getCurrentVideoImage(videoElement: HTMLVideoElement): string {
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, 'drawing context does not exist');

    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image/jpeg');
  }

  /**
   * rotates the canvas context to the rotation (rotation anchor point is in the center)
   * and restores the state after the drawingFunction
   */
  public static drawInRotatedCanvas(
    canvas: HTMLCanvasElement,
    rotation: number,
    drawingFunction: (
      ctx: CanvasRenderingContext2D,
      rotatedDimensions: { width: number; height: number }
    ) => void
  ): void {
    const halfCanvasWidth = canvas.width / 2;
    const halfCanvasHeight = canvas.height / 2;
    const radianRotation = (rotation / 180) * Math.PI;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, 'drawing context does not exist');

    ctx.translate(halfCanvasWidth, halfCanvasHeight);
    ctx.rotate(radianRotation);

    const switchAspectRatio = rotation % 180 > 0;

    const canvasWidth = switchAspectRatio ? canvas.height : canvas.width;
    const canvasHeight = switchAspectRatio ? canvas.width : canvas.height;

    drawingFunction(ctx, { width: canvasWidth, height: canvasHeight });

    ctx.rotate(-radianRotation);
    ctx.translate(-halfCanvasWidth, -halfCanvasHeight);
  }

  public static svgElementToDataUrl(svgElement: SVGSVGElement): string {
    const svgWrapper = document.createElement('div');
    svgWrapper.appendChild(svgElement.cloneNode(true));
    const cleaner = new SvgCleaner();
    const svgString =
      '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n' +
      cleaner.clean(svgWrapper.innerHTML);

    // encodeURIComponent/unescape is here to prevent encoding issues and make sure that an UTF-8 string gets outputted
    // without this m², ÖÄÜ etc. wouldn't work
    return (
      'data:image/svg+xml;base64,' +
      window.btoa(unescape(encodeURIComponent(svgString)))
    );
  }

  public static imageToDataUrl(image: HTMLImageElement): string {
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, 'drawing context does not exist');

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image/png');
  }

  public static getFileExtensionForDataUrl(dataUrl: string): string | null {
    const result = /^data:image\/(?:[a-zA-Z]+\.)?([a-zA-Z]+)/.exec(dataUrl);
    let ext = null;

    if (result && result[1]) {
      ext = result[1];

      if (ext === 'jpeg') {
        ext = 'jpg';
      }
    }

    return ext;
  }

  public static loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (eventOrMessage) => {
        if (eventOrMessage instanceof Event) {
          reject(new Error(`failed to load image: ${eventOrMessage.type}`));
        } else {
          reject(new Error(eventOrMessage));
        }
      };
      img.src = src;
    });
  }

  public static async loadImageAsDataUrl(src: string): Promise<string> {
    const response = await fetch(src);
    if (response.status !== 200) {
      throw new Error('could not load image');
    }
    const blob = await response.blob();
    const dataUrlReader = new DataUrlReader();
    return dataUrlReader.readBlob(blob);
  }
}
