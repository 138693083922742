import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { propertyToPersonEntityInfo } from '../../../../classes/EntityManager/entities/PropertyToPerson/propertyToPersonEntityInfo';
import { PropertyToPerson } from '../../../../classes/EntityManager/entities/PropertyToPerson/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { PropertyAdapter } from '../PropertyAdapter/PropertyAdapter';

export class PropertyToPersonAdapter
  implements EntityAdapter<PropertyToPerson>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private propertyAdapter: PropertyAdapter | null = null;

  constructor(options: PropertyToPersonAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Property,
        onNextAdapter: ({ adapter }) => {
          this.propertyAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.propertyAdapter != null &&
      this.propertyAdapter.getDependenciesAreLoaded()
    );
  }

  public canDeleteEntity(propertyToPerson: PropertyToPerson): boolean {
    return this.canEditPropertyToPerson(propertyToPerson);
  }

  public canEditField(propertyToPerson: PropertyToPerson): boolean {
    return this.canEditPropertyToPerson(propertyToPerson);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.PropertyToPerson,
    PropertyToPerson
  > {
    return propertyToPersonEntityInfo;
  }

  private canEditPropertyToPerson(propertyToPerson: PropertyToPerson): boolean {
    if (!this.propertyAdapter) {
      return false;
    }

    const property = this.entityManager.propertyRepository.getById(
      propertyToPerson.propertyId
    );

    if (!property) {
      return false;
    }

    return this.propertyAdapter.canEditPropertyToPersons(property);
  }
}

export type PropertyToPersonAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
