import { Vector } from 'common/Geometry/Vector';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { GaussJordan } from '../../classes/Utils/GaussJordan/GaussJordan';
import { TZoomBoxResizerHelperPicturePositionInfo } from '../../aureliaComponents/zoom-box/ZoomBoxResizerHelper';

export class PictureRevisionMarkingsCalculator {
  public static computeTransformationMatrix({
    point1,
    point2,
    point3
  }: {
    point1: {
      oldPosition: IPictureMarking;
      newPosition: IPictureMarking;
    };
    point2: {
      oldPosition: IPictureMarking;
      newPosition: IPictureMarking;
    };
    point3: {
      oldPosition: IPictureMarking;
      newPosition: IPictureMarking;
    };
  }): Array<number> {
    const p1 = this.getPercentagePositionVectorFromMarking(point1.oldPosition);
    const p2 = this.getPercentagePositionVectorFromMarking(point2.oldPosition);
    const p3 = this.getPercentagePositionVectorFromMarking(point3.oldPosition);
    const p4 = this.getPercentagePositionVectorFromMarking(point1.newPosition);
    const p5 = this.getPercentagePositionVectorFromMarking(point2.newPosition);
    const p6 = this.getPercentagePositionVectorFromMarking(point3.newPosition);

    return GaussJordan.solve(
      [
        [p1.getX(), p1.getY(), 1, 0, 0, 0],
        [0, 0, 0, p1.getX(), p1.getY(), 1],
        [p2.getX(), p2.getY(), 1, 0, 0, 0],
        [0, 0, 0, p2.getX(), p2.getY(), 1],
        [p3.getX(), p3.getY(), 1, 0, 0, 0],
        [0, 0, 0, p3.getX(), p3.getY(), 1]
      ],
      [p4.getX(), p4.getY(), p5.getX(), p5.getY(), p6.getX(), p6.getY()]
    );
  }

  public static computeMarkingPositionsBasedOnTransformationMatrix(
    marking: IPictureMarking,
    transformationMatrix: Array<number>
  ): IPictureMarking {
    const vector = this.getPercentagePositionVectorFromMarking(marking);
    const scale = new Vector(
      transformationMatrix[0] || 1,
      transformationMatrix[4] || 1
    );
    const skew = new Vector(
      transformationMatrix[3] || 0,
      transformationMatrix[1] || 0
    );
    const translation = new Vector(
      transformationMatrix[2] || 0,
      transformationMatrix[5] || 0
    );

    const result = new Vector(
      vector.getX() * scale.getX() +
        vector.getY() * skew.getY() +
        translation.getX(),
      vector.getX() * skew.getX() +
        vector.getY() * scale.getY() +
        translation.getY()
    );

    return {
      top: result.getY().toString() + '%',
      left: result.getX().toString() + '%'
    };
  }

  public static getPercentagePositionVectorFromMarking(
    marking: IPictureMarking
  ): Vector {
    return new Vector(parseFloat(marking.left), parseFloat(marking.top));
  }

  public static computeMarkerOnImageFromPixelPositionVector(
    point: Vector,
    picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo
  ): IPictureMarking {
    const position = point
      .substractVector(picturePositionInfo.topLeftPosition)
      .divideVector(picturePositionInfo.size)
      .scale(100);

    return {
      top: position.getY().toString() + '%',
      left: position.getX().toString() + '%',
      boxTop: 0,
      boxLeft: 0,
      boxBottom: 100,
      boxRight: 100
    };
  }
}
