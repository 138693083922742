import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProjectReport, ThingReport } from './types';

export class ReportRepository extends AppEntityRepository<EntityName.Report> {
  public getByProjectIds(projectIds: Array<string>): Array<ProjectReport> {
    return this.getAll().filter((report): report is ProjectReport => {
      return !!report.project && projectIds.includes(report.project);
    });
  }

  public getByProjectId(projectId: string): Array<ProjectReport> {
    return this.getByProjectIds([projectId]);
  }

  public getByThingId(thingId: string): Array<ThingReport> {
    return this.getAll().filter((report): report is ThingReport => {
      return report.thing === thingId;
    });
  }
}
