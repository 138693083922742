import { TupleArray } from 'common/Types/utilities';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import {
  Disposable,
  DisposableContainer
} from '../../../../classes/Utils/DisposableContainer';
import { BindAdapter, EntityAdapter } from '../../EntityAdapter/EntityAdapter';
import {
  EntityNameToAdapter,
  SupportedEntityName
} from '../../entityNameToPermissionsConfig';

/**
 * A class which manages the subscriptions of necessary adapters.
 */
export class BoundAdaptersContainer<
  TAdapterConstraint extends EntityAdapter<any>,
  TEntityNames extends Array<
    SupportedEntityNamesForConstraint<TAdapterConstraint>
  >
> {
  /**
   * you need to explicitly set the TStaticAdapterConstraint template param, or else you will only have limited type support
   */
  public static createCreationFunction<
    TStaticAdapterConstraint extends EntityAdapter<any>
  >(): <
    TStaticEntityNames extends Array<
      SupportedEntityNamesForConstraint<TStaticAdapterConstraint>
    >
  >(
    entityNames: TupleArray<TStaticEntityNames>
  ) => BoundAdaptersContainer<TStaticAdapterConstraint, TStaticEntityNames> {
    return <
      TStaticEntityNames extends Array<
        SupportedEntityNamesForConstraint<TStaticAdapterConstraint>
      >
    >(
      entityNames: TStaticEntityNames
    ): BoundAdaptersContainer<TStaticAdapterConstraint, TStaticEntityNames> => {
      return new BoundAdaptersContainer({
        entityNames
      });
    };
  }

  private readonly entityNames: TEntityNames;

  private readonly adaptersByEntityName = new Map<
    SupportedEntityName,
    TAdapterConstraint
  >();

  private constructor({
    entityNames
  }: {
    /**
     * entityNames for which adapters will be available
     */
    entityNames: TEntityNames;
  }) {
    this.entityNames = entityNames;
  }

  public subscribe({
    bindAdapter,
    updateBindings
  }: SubscribeOptions): Disposable {
    const disposableContainer = new DisposableContainer();

    for (const entityName of this.entityNames) {
      disposableContainer.add(
        bindAdapter({
          entityName,
          onNextAdapter: ({ adapter }) => {
            this.adaptersByEntityName.set(entityName, adapter as any);
            updateBindings();
          }
        })
      );
    }

    return disposableContainer.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    for (const entityName of this.entityNames) {
      const adapter = this.adaptersByEntityName.get(entityName);
      if (!adapter || !adapter.getDependenciesAreLoaded()) {
        return false;
      }
    }

    return true;
  }

  /**
   * Just a utility function for cases where you won't have more specific typing.
   * Will throw an error if the entityName is not available in this container.
   */
  public getAdapterForAnyEntityName<TEntityName extends EntityName>(
    entityName: TEntityName
  ): TEntityName extends TEntityNames[number]
    ? EntityNameToAdapter[TEntityName] | null
    : never {
    if (!this.entityNames.includes(entityName as any)) {
      throw new Error(`the adapter is not configured for ${entityName}`);
    }

    return this.getAdapter(entityName as any);
  }

  public getAdapter<TEntityName extends TEntityNames[number]>(
    entityName: TEntityName
  ): EntityNameToAdapter[TEntityName] | null {
    return (this.adaptersByEntityName.get(entityName) ?? null) as any;
  }
}

export type SubscribeOptions = {
  bindAdapter: BindAdapter;
  updateBindings: () => void;
};

type SupportedEntityNamesForConstraint<
  TAdapterConstraint extends EntityAdapter<any>
> = {
  [key in SupportedEntityName]: EntityNameToAdapter[key] extends TAdapterConstraint
    ? key
    : never;
}[SupportedEntityName];
