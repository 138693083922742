import { EntityInfo } from '@record-it-npm/synchro-common';
import { userDefinedEntityEntityInfo } from '../../../../classes/EntityManager/entities/UserDefinedEntity/userDefinedEntityEntityInfo';
import { UserDefinedEntity } from '../../../../classes/EntityManager/entities/UserDefinedEntity/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';

export class UserDefinedEntityAdapter
  implements EntityAdapter<UserDefinedEntity>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private editableUserGroupIds: Set<string> | null = null;
  private currentUser: User | null = null;

  constructor(options: UserDefinedEntityAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.editableUserGroupIds != null && this.currentUser != null;
  }

  public canDeleteEntity(userDefinedEntity: UserDefinedEntity): boolean {
    return this.canAdministerUserDefinedEntitiesInUserGroup(userDefinedEntity);
  }

  public canEditField(userDefinedEntity: UserDefinedEntity): boolean {
    return this.canAdministerUserDefinedEntitiesInUserGroup(userDefinedEntity);
  }

  public canEditProperties(userDefinedEntity: UserDefinedEntity): boolean {
    return this.canAdministerUserDefinedEntitiesInUserGroup(userDefinedEntity);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.UserDefinedEntity,
    UserDefinedEntity
  > {
    return userDefinedEntityEntityInfo;
  }

  private entityIsEditableUserGroup(
    userDefinedEntity: UserDefinedEntity
  ): boolean {
    if (!this.editableUserGroupIds) {
      return false;
    }

    return this.editableUserGroupIds.has(userDefinedEntity.ownerUserGroupId);
  }

  private userCanAdministerUserDefinedEntities(): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canAdministerUserDefinedEntities'
        )
      : false;
  }

  private canAdministerUserDefinedEntitiesInUserGroup(
    userDefinedEntity: UserDefinedEntity
  ): boolean {
    return (
      this.entityIsEditableUserGroup(userDefinedEntity) &&
      this.userCanAdministerUserDefinedEntities()
    );
  }
}

export type UserDefinedEntityAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
