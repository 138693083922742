import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { nfcTokenToThingEntityInfo } from '../../../../classes/EntityManager/entities/NfcTokenToThing/nfcTokenToThingEntityInfo';
import { NfcTokenToThing } from '../../../../classes/EntityManager/entities/NfcTokenToThing/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ThingAdapter } from '../ThingAdapter/ThingAdapter';

export class NfcTokenToThingAdapter implements EntityAdapter<NfcTokenToThing> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private thingAdapter: ThingAdapter | null = null;

  constructor(options: NfcTokenToThingAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Thing,
        onNextAdapter: ({ adapter }) => {
          this.thingAdapter = adapter as ThingAdapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return this.thingAdapter !== null;
  }

  public canEditField(nfcTokenToThing: NfcTokenToThing): boolean {
    return this.getCanUpdateEntity(nfcTokenToThing);
  }

  public canDeleteEntity(nfcTokenToThing: NfcTokenToThing): boolean {
    return this.getCanUpdateEntity(nfcTokenToThing);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.NfcTokenToThing,
    NfcTokenToThing
  > {
    return nfcTokenToThingEntityInfo;
  }

  private getCanUpdateEntity(nfcTokenToThing: NfcTokenToThing): boolean {
    if (!this.thingAdapter) {
      return false;
    }

    const thing = this.entityManager.thingRepository.getById(
      nfcTokenToThing.thingId
    );

    if (!thing) {
      return false;
    }

    return this.thingAdapter.canEditNfcTokens(thing);
  }
}

export type NfcTokenToThingAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
