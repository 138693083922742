import { autoinject } from 'aurelia-framework';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { AppEntityManagerEntityTypesByEntityName } from '../../classes/EntityManager/entities/AppEntityManagerEntityTypesByEntityName';
import { RxjsService } from '../RxjsService/RxjsService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { EntityAdapterContainerService } from './EntityAdapterContainerService/EntityAdapterContainerService';
import { EntitiesPermissionChecker } from './EntitiesPermissionChecker/EntitiesPermissionChecker';
import { EntitiesWithPermissionHandle } from './EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { EntityOfEntityAdapterContainer } from './EntityAdapterContainer/EntityAdapterContainer';
import {
  EntityNameToAdapterContainer,
  EntityNameToAdapter,
  entityNameToPermissionsConfig,
  EntityNameToPermissionsHandle,
  SupportedEntityName
} from './entityNameToPermissionsConfig';

/**
 * A service which creates handles to retrieve permissions for entities so they can be used in the template.
 *
 * Example:
 *   component.ts:
 *     @autoinject()
 *     class SomeComponent {
 *       @bindable()
 *       public positon: ProcessTaskPosition|null = null;
 *
 *       @subscribableLifecycle()
 *       protected readonly handle: PermissionsHandleForEntityName<EntityName.ProcessTaskPosition>;
 *
 *       constructor(permissionsService: PermissionsService) {
 *         this.permissionHandle = permissionsService.getPermissionsHandleForExpressionValue({
 *           entityName: EntityName.ProcessTaskPosition,
 *           context: this,
 *           expression: 'processTaskPosition'
 *         });
 *       }
 *     }
 *
 *   component.html:
 *     <template>
 *       <clickable-text-input enabled.to-view="permissionHandle.canEditField.name"></clickable-text-input>
 *     </template>
 *
 *
 * How to add support for a new entity:
 *  * create an adapter for the entity in the `./EntityAdapter` folder
 *  * add the EntityName to the `supportedEntityNames` in the `./entityNameToPermissionsConfig` file
 *  * add the config for the entity to the `entityNameToAdapterFactory` and `entityNameToPermissionsHandleFactory` in the `./entityNameToPermissionsConfig` file
 */
@autoinject()
export class PermissionsService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly rxjsService: RxjsService,
    private readonly entityAdapterContainerService: EntityAdapterContainerService
  ) {}

  /**
   * Creates a permissions handle which takes the entity from an propertyName
   *
   * Use this if you want the get the permissions handle of an entity which is only available as an @bindable().
   * Also this only works on public field.
   *
   * Since this has some (for me) unexplainable typing issues when you use `this` as the context, you have to cast `this` into the class of itself explicitly and then it works
   * e.g.
   * class Test {
   *   public entity;
   *
   *   constructor() {
   *     const handle = permissionsService.getPermissionsHandleForProperty({ entityName: ..., context: this as Test, propertyName: 'entity' });
   *   }
   * }
   */
  public getPermissionsHandleForProperty<
    TEntityName extends SupportedEntityName,
    TContext extends Record<string, any>
  >({
    entityName,
    context,
    propertyName
  }: {
    entityName: TEntityName;
    context: TContext;
    propertyName: KeysForTypeInContext<
      AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'],
      TContext
    >;
  }): EntityNameToPermissionsHandle[TEntityName] {
    return this.getPermissionsHandleForExpressionValue({
      entityName,
      context,
      expression: String(propertyName)
    });
  }

  /**
   * Creates a permissions handle which takes the entity from an expression.
   * If the expression is only a single public field, you should use getPermissionsHandleForProperty instead for better typing assistance
   *
   * Use this if you want the get the permissions handle of an entity which is only available as an @bindable().
   */
  public getPermissionsHandleForExpressionValue<
    TEntityName extends SupportedEntityName
  >({
    entityName,
    context,
    expression
  }: {
    entityName: TEntityName;
    context: any;
    expression: string;
  }): EntityNameToPermissionsHandle[TEntityName] {
    return this.getPermissionsHandle({
      entityName,
      entity$: this.rxjsService.fromExpression({ context, expression })
    });
  }

  /**
   * Creates a permissions handle which uses a fixed entity
   *
   * Use this if you are in a context where the entity never changes for the duration of the subscriptions, e.g. in a dialog.open function or in a class which receives the entity via the constructor
   */
  public getPermissionsHandleForEntity<
    TEntityName extends SupportedEntityName
  >({
    entityName,
    entity
  }: {
    entityName: TEntityName;
    entity: EntityOfEntityAdapterContainer<
      EntityNameToAdapterContainer[TEntityName]
    > | null;
  }): EntityNameToPermissionsHandle[TEntityName] {
    return this.getPermissionsHandle({
      entityName,
      entity$: new BehaviorSubject(entity)
    });
  }

  /**
   * Creates a permissions handle which takes the entityId from an propertyName
   *
   * Use this if you want the get the permissions handle of an entity which is only available as an @bindable().
   * Also this only works on public field.
   *
   * Since this has some (for me) unexplainable typing issues when you use `this` as the context, you have to cast `this` into the class of itself explicitly and then it works
   * e.g.
   * class Test {
   *   public entity;
   *
   *   constructor() {
   *     const handle = permissionsService.getPermissionsHandleForProperty({ entityName: ..., context: this as Test, propertyName: 'entity' });
   *   }
   * }
   */
  public getPermissionsHandleForIdProperty<
    TEntityName extends SupportedEntityName,
    TContext extends Record<string, any>
  >({
    entityName,
    context,
    propertyName
  }: {
    entityName: TEntityName;
    context: TContext;
    propertyName: KeysForTypeInContext<string, TContext>;
  }): EntityNameToPermissionsHandle[TEntityName] {
    return this.getPermissionsHandleForIdExpressionValue({
      entityName,
      context,
      expression: String(propertyName)
    });
  }

  /**
   * Creates a permissions handle which takes an entityId from an expression.
   * Use this if you want the get the permissions handle of an entity which is represented by an reference in another entity.
   * E.g. if you want the permissions handle of the userGroup
   */
  public getPermissionsHandleForEntityIdOfPropertyValue<
    TEntityName extends SupportedEntityName,
    TContext extends Record<string, any>,
    TPropertyName extends keyof TContext
  >({
    entityName,
    context,
    propertyName,
    idPropertyName
  }: {
    entityName: TEntityName;
    context: TContext;
    propertyName: TPropertyName;
    idPropertyName: KeysForTypeInContext<
      string,
      NonNullable<TContext[TPropertyName]>
    >;
  }): EntityNameToPermissionsHandle[TEntityName] {
    return this.getPermissionsHandleForIdExpressionValue({
      entityName,
      context,
      expression: `${String(propertyName)}.${String(idPropertyName)}`
    });
  }

  /**
   * Creates a permissions handle which takes an entityId from an expression.
   */
  public getPermissionsHandleForIdExpressionValue<
    TEntityName extends SupportedEntityName
  >({
    entityName,
    context,
    expression
  }: {
    entityName: TEntityName;
    context: Record<string, any>;
    expression: string;
  }): EntityNameToPermissionsHandle[TEntityName] {
    const entityId$ = this.rxjsService.fromExpression<string | null>({
      context,
      expression
    });

    const entityChanged$ = new Observable<undefined>((subscriber) => {
      const subscriptionManager = this.subscriptionManagerService.create();

      subscriptionManager.subscribeToModelChanges(entityName, () => {
        subscriber.next();
      });

      subscriber.next();

      return () => {
        subscriptionManager.disposeSubscriptions();
      };
    });

    const entity$ = combineLatest([entityId$, entityChanged$]).pipe(
      map(([entityId]) => {
        if (!entityId) {
          return null;
        }

        return this.entityManager.entityRepositoryContainer
          .getByEntityName(entityName)
          .getById(entityId) as EntityOfEntityAdapterContainer<
          EntityNameToAdapterContainer[TEntityName]
        >;
      })
    );

    return this.getPermissionsHandle({
      entityName,
      entity$
    });
  }

  public getPermissionsHandle<TEntityName extends SupportedEntityName>({
    entityName,
    entity$
  }: {
    entityName: TEntityName;
    entity$: Observable<
      | EntityOfEntityAdapterContainer<
          EntityNameToAdapterContainer[TEntityName]
        >
      | null
      | undefined
    >;
  }): EntityNameToPermissionsHandle[TEntityName] {
    const adapterContainer =
      this.entityAdapterContainerService.getCachedEntityAdapterContainer(
        entityName
      );
    return entityNameToPermissionsConfig[entityName].createPermissionsHandle({
      adapterContainer: adapterContainer as any,
      entity$: entity$ as Observable<any>
    }) as EntityNameToPermissionsHandle[TEntityName];
  }

  public getEntitiesWithPermissionHandleForPermissionName<
    TEntityName extends SupportedEntityName
  >({
    entityName,
    permissionName
  }: {
    entityName: TEntityName;
    permissionName: PermissionNamesWhereOnlyAnEntityIsPassed<TEntityName>;
  }): EntitiesWithPermissionHandle<TEntityName> {
    return new EntitiesWithPermissionHandle({
      adapterContainer:
        this.entityAdapterContainerService.getCachedEntityAdapterContainer(
          entityName
        ),
      checkPermission: ({ adapter, entity }) => {
        return (
          adapter[
            permissionName
          ] as PermissionCheckingFunctionWithOnlyEntityAsParam<TEntityName>
        )(entity);
      }
    });
  }

  public getEntitiesPermissionChecker<TEntityName extends SupportedEntityName>({
    entityName
  }: {
    entityName: TEntityName;
  }): EntitiesPermissionChecker<TEntityName> {
    return new EntitiesPermissionChecker({
      adapterContainer:
        this.entityAdapterContainerService.getCachedEntityAdapterContainer(
          entityName
        )
    });
  }

  public useAdapterOnce<TEntityName extends SupportedEntityName, T>({
    entityName,
    useAdapter
  }: {
    entityName: TEntityName;
    useAdapter: (adapter: EntityNameToAdapter[TEntityName]) => T;
  }): Promise<T> {
    return new Promise((resolve, reject) => {
      const subscription = this.entityAdapterContainerService
        .getCachedEntityAdapterContainerWithGenericTyping(entityName as any)
        .bindAdapter(({ adapter }) => {
          try {
            if (!adapter.getDependenciesAreLoaded()) {
              return;
            }

            resolve(useAdapter(adapter as any));

            subscription.dispose();
          } catch (error) {
            reject(error);
          }
        });
    });
  }
}

type KeysForTypeInContext<T, TContext extends Record<string, any>> = {
  [key in keyof TContext]: NonNullable<Required<TContext>[key]> extends T
    ? key
    : never;
}[keyof TContext];

type PermissionNamesWhereOnlyAnEntityIsPassed<
  TEntityName extends SupportedEntityName,
  TEntityAdapter = EntityNameToAdapter[TEntityName]
> = {
  [key in keyof TEntityAdapter]: TEntityAdapter[key] extends PermissionCheckingFunctionWithOnlyEntityAsParam<TEntityName>
    ? key
    : never;
}[keyof TEntityAdapter];

type PermissionCheckingFunctionWithOnlyEntityAsParam<
  TEntityName extends SupportedEntityName
> = (
  entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
) => boolean;
