import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  NfcTokenDtoUtils,
  MainEntityInfo
} from 'common/Types/Entities/NfcToken/NfcTokenDtoUtils';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { nfcTokenEntityInfo } from '../../../../classes/EntityManager/entities/NfcToken/nfcTokenEntityInfo';
import { NfcToken } from '../../../../classes/EntityManager/entities/NfcToken/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { BoundAdaptersContainer } from '../../utils/BoundAdaptersContainer/BoundAdaptersContainer';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class NfcTokenAdapter implements EntityAdapter<NfcToken> {
  private readonly entityManager: AppEntityManager;

  private readonly entityAdaptersContainer =
    BoundAdaptersContainer.createCreationFunction<EntityAdapterWithCanEditNfcTokens>()(
      [EntityName.Thing, EntityName.Person]
    );

  constructor(options: NfcTokenAdapterOptions) {
    this.entityManager = options.entityManager;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    return this.entityAdaptersContainer.subscribe({
      updateBindings,
      bindAdapter
    });
  }

  public getDependenciesAreLoaded(): boolean {
    return this.entityAdaptersContainer.getDependenciesAreLoaded();
  }

  public canEditField(nfcToken: NfcToken): boolean {
    return this.getCanUpdateEntity(nfcToken);
  }

  public canDeleteEntity(nfcToken: NfcToken): boolean {
    return this.getCanUpdateEntity(nfcToken);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.NfcToken,
    NfcToken
  > {
    return nfcTokenEntityInfo;
  }

  private getCanUpdateEntity(nfcToken: NfcToken): boolean {
    const mainEntityInfos = this.getMainEntityInfos(nfcToken);

    return mainEntityInfos.some((mainEntityInfo) => {
      return this.checkPermissionForMainEntityInfo(mainEntityInfo);
    });
  }

  private getMainEntityInfos(nfcToken: NfcToken): Array<MainEntityInfo> {
    return NfcTokenDtoUtils.getMainEntityInfos({
      nfcTokenToThingDtos:
        this.entityManager.nfcTokenToThingRepository.getByNfcTokenId(
          nfcToken.id
        ),
      nfcTokenToPersonDtos:
        this.entityManager.nfcTokenToPersonRepository.getByNfcTokenId(
          nfcToken.id
        )
    });
  }

  private checkPermissionForMainEntityInfo(
    mainEntityInfo: MainEntityInfo
  ): boolean {
    const mainEntity = this.entityManager.entityRepositoryContainer
      .getByEntityName(mainEntityInfo.mainEntityName)
      .getById(mainEntityInfo.mainEntityId);

    if (!mainEntity) {
      return false;
    }

    const adapter = this.entityAdaptersContainer.getAdapter(
      mainEntityInfo.mainEntityName
    ) as EntityAdapterWithCanEditNfcTokens;

    if (!adapter) {
      return false;
    }

    return adapter.canEditNfcTokens(mainEntity);
  }
}

export type NfcTokenAdapterOptions = {
  entityManager: AppEntityManager;
};

type EntityAdapterWithCanEditNfcTokens = EntityAdapter<any> & {
  canEditNfcTokens: (entity: any) => boolean;
};
