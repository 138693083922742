import { autoinject, bindable } from 'aurelia-framework';
import { Vector } from 'common/Geometry/Vector';
import { AngleHelper } from 'common/Geometry/AngleHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { MouseDraggingDetector } from '../../classes/DomUtilities/MouseDraggingDetector';
import { PointerEventHelper } from '../../classes/PointerEventHelper';
import { RepositionableElementResizeDraggingHandler } from './handlers/RepositionableElementResizeDraggingHandler/RepositionableElementResizeDraggingHandler';
import { RepositionableElementRotateDraggingHandler } from './handlers/RepositionableElementRotateDraggingHandler/RepositionableElementRotateDraggingHandler';
import { TouchDraggingDetector } from '../../classes/DomUtilities/TouchDraggingDetector';
import { Utils } from '../../classes/Utils/Utils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import {
  PositionAndSizeTransformation,
  RepositionableElementUtils
} from './RepositionableElementUtils';
import { RepositionableElementFeature } from './features/RepositionableElementFeature';
import { RepositionableElementMoveDraggingHandler } from './handlers/RepositionableElementMoveDraggingHandler/RepositionableElementMoveDraggingHandler';
import { RepositionableElementDraggingHandler } from './handlers/RepositionableElementDraggingHandler';

/**
 * the element can only be moved inside the parent element
 *
 * @event {PositionAndSizeChangedEvent} position-and-size-changed
 *
 * @slot default
 */
@autoinject()
export class RepositionableElement {
  @bindable()
  public resizable: boolean = true;

  @bindable()
  public rotatable: boolean = true;

  /**
   * set this no null if you want a flexible ratio
   * this setting is only used when resizable is true
   *
   * ratio is width / height
   */
  @bindable()
  public fixedRatio: number | null = null;

  /**
   * If this value get's changed internally (via drag and drop etc), the new value is available via the `position-and-size-changed` event.
   */
  @bindable()
  public positionAndSize: Readonly<RepositionableElementPositionAndSize> = {
    left: 0,
    top: 0,
    height: 10,
    width: 10,
    rotation: 0
  };

  /**
   * normally all units (for top, left, width, height) are absolute in pixels, but with relativeUnits they all are percentual to the parent container.
   * relative units will be from 0 to 100
   */
  @bindable()
  public relativeUnits: boolean = false;

  /**
   * decides if there is an extra border when the repositionable-element is active
   */
  @bindable()
  public activeBorder: boolean = true;

  @bindable()
  public features: Array<RepositionableElementFeature> = [];

  private readonly element: HTMLElement;

  private active: boolean = true;
  private mode: ManipulationMode = ManipulationMode.NONE;
  private availableModes: Array<ManipulationMode> = [
    ManipulationMode.RESIZE,
    ManipulationMode.ROTATE
  ];

  private positioningContainer: HTMLElement | null = null;
  private mouseDraggingDetector: MouseDraggingDetector | null = null;
  private touchDraggingDetector: TouchDraggingDetector | null = null;
  private currentDraggingHandler: RepositionableElementDraggingHandler | null =
    null;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  /**
   * if active is true, the user is allowed to interact with this element, else it is locked
   */
  public setActive(active: boolean): void {
    this.active = active;
    this.updateDraggingDetectorsInitialized();
  }

  protected attached(): void {
    this.positioningContainer = this.element.parentElement;
    this.resizeableChanged();
    this.rotatableChanged();

    this.createMouseDraggingDetector();
    this.createTouchDraggingDetector();
  }

  protected detached(): void {
    this.mouseDraggingDetector?.destroy();
    this.mouseDraggingDetector = null;

    this.touchDraggingDetector?.destroy();
    this.touchDraggingDetector = null;
  }

  protected resizeableChanged(): void {
    this.updateModeAvailability(this.resizable, ManipulationMode.RESIZE);
  }

  protected rotatableChanged(): void {
    this.updateModeAvailability(this.rotatable, ManipulationMode.ROTATE);
  }

  private createMouseDraggingDetector(): void {
    this.mouseDraggingDetector?.destroy(); // destroy the old instance

    this.mouseDraggingDetector = new MouseDraggingDetector(document.body, {
      elementCanStartDragging: (element) => {
        return this.elementCanStartDragging(element);
      }
    });

    this.mouseDraggingDetector.onDragStart(
      this.handleMouseDragStart.bind(this)
    );

    this.mouseDraggingDetector.onDrag(this.handleDrag.bind(this));
  }

  private handleMouseDragStart(event: MouseEvent): void {
    this.currentDraggingHandler = this.getDraggingHandler(event);
    if (!this.currentDraggingHandler) {
      this.getMouseDraggingDetector().cancelDragging();
    }
  }

  private createTouchDraggingDetector(): void {
    this.touchDraggingDetector?.destroy(); // destroy the old instance

    this.touchDraggingDetector = new TouchDraggingDetector(document.body, {
      elementCanStartDragging: (element) => {
        return this.elementCanStartDragging(element);
      }
    });

    this.touchDraggingDetector.onDragStart(
      this.handleTouchDragStart.bind(this)
    );

    this.touchDraggingDetector.onDrag(this.handleDrag.bind(this));
  }

  private handleTouchDragStart(event: TouchEvent): void {
    this.currentDraggingHandler = this.getDraggingHandler(event);
    if (!this.currentDraggingHandler) {
      this.getTouchDraggingDetector().cancelDragging();
    }
  }

  private handleDrag(event: MouseEvent | TouchEvent): void {
    assertNotNullOrUndefined(
      this.currentDraggingHandler,
      "can't handleDrag without currentDraggingHandler"
    );

    const result = this.currentDraggingHandler.handleDrag({
      pointerPosition: PointerEventHelper.getClientPositionFromEvent(event)
    });

    this.setPositionAndSize(result);
  }

  private updateDraggingDetectorsInitialized(): void {
    if (this.active) {
      this.mouseDraggingDetector?.init();
      this.touchDraggingDetector?.init();
    } else {
      this.mouseDraggingDetector?.destroy();
      this.touchDraggingDetector?.destroy();
    }
  }

  private elementCanStartDragging(element: Element): boolean {
    return this.element.contains(element);
  }

  /**
   * returns null if the element is not a valid dragStartPoint!
   */
  private getDraggingHandler(
    event: MouseEvent | TouchEvent
  ): RepositionableElementDraggingHandler | null {
    if (
      !event.target ||
      !(event.target instanceof Element) ||
      !this.element.contains(event.target)
    ) {
      return null;
    }

    let dragMode: string | null = null;

    Utils.walkThroughParentElements(event.target, (element) => {
      if (element?.hasAttribute('data-drag-mode')) {
        dragMode = element.getAttribute('data-drag-mode');
        return false;
      }

      return true;
    });

    return this.getDraggingHandlerForDragMode({
      event,
      dragMode
    });
  }

  private getDraggingHandlerForDragMode({
    event,
    dragMode
  }: {
    event: MouseEvent | TouchEvent;
    dragMode: string | null;
  }): RepositionableElementDraggingHandler {
    if (!dragMode) {
      return new RepositionableElementMoveDraggingHandler({
        element: this.element,
        initialPointerPosition:
          PointerEventHelper.getClientPositionFromEvent(event)
      });
    }

    const [manipulationMode, direction] = dragMode.split('_');
    if (!manipulationMode || !direction) {
      throw new Error(
        `dragMode is missing the manipulationMode or direction "${dragMode}"`
      );
    }

    switch (manipulationMode) {
      case ManipulationMode.RESIZE:
        return new RepositionableElementResizeDraggingHandler({
          element: this.element,
          direction,
          fixedRatio: this.fixedRatio,
          initialPointerPosition:
            PointerEventHelper.getClientPositionFromEvent(event)
        });

      case ManipulationMode.ROTATE:
        return new RepositionableElementRotateDraggingHandler({
          element: this.element,
          controlPosition: direction,
          initialPointerPosition:
            PointerEventHelper.getClientPositionFromEvent(event)
        });

      default:
        throw new Error(`unhandled dragMode "${dragMode}"`);
    }
  }

  private updateModeAvailability(
    available: boolean,
    mode: ManipulationMode
  ): void {
    const index = this.availableModes.indexOf(mode);
    if (available) {
      if (index === -1) {
        this.availableModes.push(mode);
      }

      if (this.mode === 'none') {
        this.mode = mode;
      }
    } else {
      if (index >= 0) {
        this.availableModes.splice(index);
      }

      if (this.mode === mode) {
        this.mode = this.availableModes[0] ?? ManipulationMode.NONE;
      }
    }
  }

  protected handleDomElementClick(): void {
    if (
      !this.availableModes.length ||
      this.mouseDraggingDetector?.draggedRecently()
    ) {
      return;
    }

    const modeIndex = this.availableModes.indexOf(this.mode);
    this.mode =
      this.availableModes[(modeIndex + 1) % this.availableModes.length] ??
      ManipulationMode.NONE;
  }

  protected getRepositionableElementStyle(
    top: number,
    left: number,
    width: number,
    height: number,
    rotation: number,
    relativeUnits: boolean
  ): Record<string, any> {
    const withUnit = (value: number): string => {
      return `${value}${relativeUnits ? '%' : 'px'}`;
    };

    return {
      top: withUnit(top),
      left: withUnit(left),
      width: withUnit(width),
      height: withUnit(height),
      transform: 'rotate(' + AngleHelper.flipAngle(rotation) + 'deg)'
    };
  }

  protected getTransformRotation(rotation: number): string {
    return 'rotate(' + AngleHelper.flipAngle(rotation) + 'deg)';
  }

  private setPositionAndSize({
    top,
    left,
    width,
    height,
    rotation
  }: SetPositionAndSizeOptions): void {
    assertNotNullOrUndefined(
      this.positioningContainer,
      "can't RepositionableElement.setPositionAndSize without positioningContainer"
    );
    const positioningContainerClientRect =
      this.positioningContainer.getBoundingClientRect();
    const getSizeInPxOrPercent = (
      size: number | undefined,
      relativeSize: number
    ): number | null => {
      if (size == null) {
        return null;
      }

      return this.relativeUnits ? (100 * size) / relativeSize : size;
    };

    DomEventHelper.fireEvent<PositionAndSizeChangedEvent>(this.element, {
      name: 'position-and-size-changed',
      detail: {
        positionAndSize: this.getTransformedAndProcessedPositionAndSize({
          transformation: {
            top: getSizeInPxOrPercent(
              top,
              positioningContainerClientRect.height
            ),
            left: getSizeInPxOrPercent(
              left,
              positioningContainerClientRect.width
            ),
            width: getSizeInPxOrPercent(
              width,
              positioningContainerClientRect.width
            ),
            height: getSizeInPxOrPercent(
              height,
              positioningContainerClientRect.height
            ),
            rotation
          },
          positioningContainerClientRect
        })
      }
    });
  }

  private getTransformedAndProcessedPositionAndSize({
    transformation,
    positioningContainerClientRect
  }: {
    transformation: PositionAndSizeTransformation;
    positioningContainerClientRect: DOMRect;
  }): RepositionableElementPositionAndSize {
    let positionAndSize = RepositionableElementUtils.transformPositionAndSize({
      transformation,
      positionAndSize: this.positionAndSize
    });

    let absoluteConversionVector;
    if (this.relativeUnits) {
      absoluteConversionVector = new Vector(
        positioningContainerClientRect.width / 100,
        positioningContainerClientRect.height / 100
      );
    } else {
      absoluteConversionVector = new Vector(1, 1);
    }

    for (const feature of this.features) {
      if (feature.postProcessNewPositionAndSize) {
        positionAndSize = feature.postProcessNewPositionAndSize({
          positionAndSize,
          originalTransformation: transformation,
          sizeConversion: {
            absoluteConversionVector
          }
        });
      }
    }

    return positionAndSize;
  }

  private getMouseDraggingDetector(): MouseDraggingDetector {
    assertNotNullOrUndefined(
      this.mouseDraggingDetector,
      'no mouseDraggingDetector available'
    );
    return this.mouseDraggingDetector;
  }

  private getTouchDraggingDetector(): TouchDraggingDetector {
    assertNotNullOrUndefined(
      this.touchDraggingDetector,
      'no getTouchDraggingDetector available'
    );
    return this.touchDraggingDetector;
  }
}

export type RepositionableElementPositionAndSize = {
  /**
   * top position
   * in px or percent, based on the relativeUnits setting
   */
  top: number;

  /**
   * left position
   * in px or percent, based on the relativeUnits setting
   */
  left: number;

  /**
   * rotation in deg, counter clockwise/vector representation
   */
  rotation: number;

  /**
   * in px or percent, based on the relativeUnits setting
   */
  width: number;

  /**
   * in px or percent, based on the relativeUnits setting
   */
  height: number;
};

export type PositionAndSizeChangedEvent = NamedCustomEvent<
  'position-and-size-changed',
  {
    positionAndSize: RepositionableElementPositionAndSize;
  }
>;

enum ManipulationMode {
  RESIZE = 'resize',
  ROTATE = 'rotate',
  NONE = 'none'
}

export type SetPositionAndSizeOptions = {
  /**
   * in px
   */
  top?: number;

  /**
   * in px
   */
  left?: number;

  /**
   * in px
   */
  width?: number;

  /**
   * in px
   */
  height?: number;

  /**
   * in deg
   */
  rotation?: number;
};
