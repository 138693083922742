import { autoinject, bindable, PLATFORM } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DateUtils } from 'common/DateUtils';
import { GalleryThingHelper } from 'common/GalleryThing/GalleryThingHelper';
import { PropertyFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPicturePropertyFilter';
import {
  GalleryThingPictureFilter,
  GalleryThingPictureFilterTagMatchMode
} from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';

import { Utils } from '../../classes/Utils/Utils';
import { SelectCoordinatesOnPositionedPictureOverlay } from '../../dialogs/select-coordinates-on-positioned-picture-overlay/select-coordinates-on-positioned-picture-overlay';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SelectThingPictureDialog } from '../../dialogs/select-thing-picture-dialog/select-thing-picture-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { LatLongAreaInput } from 'src/inputComponents/lat-long-area-input/lat-long-area-input';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ThingTag } from '../../classes/EntityManager/entities/Tag/types';
import { TRegionSelectEvent } from '../../regionComponents/region-select/region-select';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { LatLongArea } from 'common/Types/LatLongArea';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { TagsChangedEvent } from '../../aureliaComponents/categorized-tags-widget/categorized-tags-widget';
import {
  FilterBarConfiguration,
  ViaFilterBarConfigurationService
} from '../../services/ViaFilterBarConfigurationService/ViaFilterBarConfigurationService';
import { GalleryThingFilterHelper } from '../../classes/GalleryThing/GalleryThingFilterHelper';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { GalleryThingWidgetType } from '../../classes/GalleryThing/GalleryThingWidgetType';

/**
 * @slot header - the content will be shown at the top of the container
 * Since there is no way to check if a slot is used or not, you have to add a `<div slot="header" class="gallery-thing-picture-filter-bar--SectionSeparator"></div>` as the last element in the slot
 * if you need to add a title/heading you can use the `gallery-thing-picture-filter-bar--Title` class so it looks the same as the other title in this component
 *
 * @slot footer - the content will be shown at the bottom of the container
 * Since there is no way to check if a slot is used or not, you have to add a `<div slot="footer" class="gallery-thing-picture-filter-bar--SectionSeparator"></div>` as the first element in the slot
 * if you need to add a title/heading you can use the `gallery-thing-picture-filter-bar--Title` class so it looks the same as the other title in this component
 *
 * @slot buttons - you can add additional record-it-buttons here
 *
 * @event gallery-thing-filter-reset triggered when the filter reset button is clicked
 */
@autoinject()
export class GalleryThingPictureFilterBar {
  @bindable public thing: Thing | null = null;

  /**
   * readonly
   */
  @bindable public pictureFilter: GalleryThingPictureFilter | undefined;

  private canUseRegionExtension = false;

  private dateFrom: string | null = null;
  private dateTo: string | null = null;

  protected warningTexts: Array<string> = [];

  private latitude: number | null = null;
  private longitude: number | null = null;
  private distance: number | null = null;
  private latLongAreaInputValid = false;
  private latLongArea: LatLongArea | null = null;
  private latLongAreaInput: LatLongAreaInput | null = null;

  private projectId: string | null = null;

  private availableTags: Array<ThingTag> = [];
  private selectedTags: Array<ThingTag> = [];
  private untaggedPicturesOnly: boolean = false;
  private tagMatchMode: GalleryThingPictureFilterTagMatchMode =
    GalleryThingPictureFilterTagMatchMode.PARTIAL;

  private isAttached = false;

  private regionId: string | null = null;

  private personIds: Array<string> | null = null;

  private personSearchPattern: string | null = null;

  private pictureDescriptionSearchPattern: string | null = null;

  private projectIdTimestampMap: Record<string, number> = {};

  private properties: Array<PropertyFilter> = [];

  private static filterBarWidgetTypeToTemplateNameMap: Record<
    Exclude<GalleryThingWidgetType, GalleryThingWidgetType.Property>,
    string
  > = {
    [GalleryThingWidgetType.DateRange]: PLATFORM.moduleName(
      './widgetTemplates/dateRange.html'
    ),
    [GalleryThingWidgetType.LatLongArea]: PLATFORM.moduleName(
      './widgetTemplates/latLongArea.html'
    ),
    [GalleryThingWidgetType.Tags]: PLATFORM.moduleName(
      './widgetTemplates/tags.html'
    ),
    [GalleryThingWidgetType.Region]: PLATFORM.moduleName(
      './widgetTemplates/region.html'
    ),
    [GalleryThingWidgetType.Person]: PLATFORM.moduleName(
      './widgetTemplates/person.html'
    ),
    [GalleryThingWidgetType.PersonSelect]: PLATFORM.moduleName(
      './widgetTemplates/personSelect.html'
    ),
    [GalleryThingWidgetType.PersonMultiSelect]: PLATFORM.moduleName(
      './widgetTemplates/personMultiSelect.html'
    ),
    [GalleryThingWidgetType.PictureDescription]: PLATFORM.moduleName(
      './widgetTemplates/pictureDescription.html'
    ),
    [GalleryThingWidgetType.Project]: PLATFORM.moduleName(
      './widgetTemplates/project.html'
    )
  };

  private filterBarConfiguration: FilterBarConfiguration;

  protected pictureFilterIsSet = false;

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  private updatePictureFilterRateLimited = Utils.rateLimitFunction(
    this.updatePictureFilter.bind(this),
    10
  );

  private updatePictureFilterRateLimitedForText = Utils.rateLimitFunction(
    this.updatePictureFilter.bind(this),
    500
  );

  protected useCategorizedTags = false;

  protected GalleryThingFilterHelper = GalleryThingFilterHelper;

  protected GalleryThingPictureFilterTagMatchMode =
    GalleryThingPictureFilterTagMatchMode;

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    private readonly activeUsercompanySettingService: ActiveUserCompanySettingService,
    private readonly entityManager: AppEntityManager,
    private readonly viaFilterBarConfigurationService: ViaFilterBarConfigurationService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseRegionExtension: 'canUseRegionExtension',
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });

    this.filterBarConfiguration =
      viaFilterBarConfigurationService.getStandardFilterBarConfiguration();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToMultiplePropertyChanges(
      this,
      [
        'dateFrom',
        'dateTo',
        'latLongAreaInputValid',
        'latLongArea',
        'projectId',
        'regionId'
      ],
      this.updatePictureFilterRateLimited
    );

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'selectedTags',
      this.updatePictureFilterRateLimited
    );
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'personIds',
      this.updatePictureFilterRateLimited
    );
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'properties',
      this.updatePictureFilterRateLimited
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateAvailableTags.bind(this)
    );
    this.updateAvailableTags();
    this.permissionBindingHandle.subscribe();

    this.subscriptionManager.addDisposable(
      this.viaFilterBarConfigurationService.bindFilterBarConfiguration(
        (configuration) => {
          this.filterBarConfiguration = configuration;
          this.updateProperties();
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUsercompanySettingService.bindSettingProperty(
        'via.useCategorizedTags',
        (value) => {
          this.useCategorizedTags = value;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUsercompanySettingService.bindSettingProperty(
        'via.tagMatchMode',
        (value) => {
          this.tagMatchMode = value
            ? value
            : GalleryThingPictureFilterTagMatchMode.PARTIAL;
        }
      )
    );
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
    this.subscriptionManager.disposeSubscriptions();

    this.isAttached = false;
  }

  protected thingChanged(): void {
    if (this.isAttached) {
      this.updateAvailableTags();
      this.updatePictureFilter();
    }
  }

  private updateProperties(): void {
    const propertyFilterWidgets = this.filterBarConfiguration.widgets.filter(
      (widget) => widget.type === 'property'
    );

    this.properties = GalleryThingFilterHelper.updateProperties(
      propertyFilterWidgets,
      this.properties
    );
  }

  private updateAvailableTags(): void {
    if (!this.thing) {
      this.availableTags = [];
      return;
    }

    this.availableTags = this.thing.id
      ? this.entityManager.tagRepository.getByThingId(this.thing.id)
      : [];
  }

  private updateWarningTexts(): void {
    const warningTexts = [];

    if (this.dateFrom && this.dateTo) {
      if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
        warningTexts.push(this.translate('dateToBeforeDateFromWarning'));
      }
    }

    if (!this.latLongAreaInputValid) {
      warningTexts.push(this.translate('incompleteLatLongArea'));
    }

    this.warningTexts = warningTexts;
  }

  protected async handleSelectCoordinatesOnThingPictureClick(): Promise<void> {
    let oldPosition = null;
    if (this.latitude && this.longitude) {
      oldPosition = {
        latitude: this.latitude,
        longitude: this.longitude
      };
    }

    const thingId = this.thing?.id;
    if (!thingId) return;

    const pictures =
      this.entityManager.pictureRepository.getByGalleryThingId(thingId);
    const picture =
      pictures.length === 1
        ? pictures[0]
        : await new Promise<Picture | null>((res) => {
            void SelectThingPictureDialog.open({
              thingId: thingId,
              onDialogClosed: (pic) => res(pic)
            });
          });

    if (!picture) return;

    void SelectCoordinatesOnPositionedPictureOverlay.open({
      oldPosition: oldPosition,
      picture: picture,
      onCoordinateSelected: ({ coords }) => {
        this.latitude = coords.latitude;
        this.longitude = coords.longitude;

        setTimeout(() => {
          if (this.latLongAreaInput) this.latLongAreaInput.focusDistanceInput();
        }, 10);
      },
      pictureNotPositionedText: this.i18n.tr(
        'galleryThing.thingPictureIsNotPositioned'
      )
    });
  }

  protected handleResetButtonClick(): void {
    this.dateFrom = null;
    this.dateTo = null;
    this.latitude = null;
    this.longitude = null;
    this.distance = null;
    this.selectedTags = [];
    this.untaggedPicturesOnly = false;
    this.regionId = null;
    this.projectId = null;
    this.personIds = null;
    this.personSearchPattern = null;
    this.pictureDescriptionSearchPattern = null;

    for (const propertyFilter of this.properties) {
      GalleryThingFilterHelper.resetFilter(propertyFilter);
    }

    DomEventHelper.fireEvent<GalleryThingFilterResetEvent>(this.element, {
      name: 'gallery-thing-filter-reset',
      detail: null
    });
  }

  private updatePictureFilter(): void {
    this.updateWarningTexts();
    this.pictureFilter = this.createPictureFilter();
    this.updatePictureFilterIsSet();
  }

  private createPictureFilter(): GalleryThingPictureFilter {
    assertNotNullOrUndefined(
      this.thing,
      'cannot create filter without knowing the current thing'
    );
    let personIds = null;
    if (
      this.personIds &&
      this.personIds.length > 0 &&
      this.personIds.some((id) => !!id)
    ) {
      personIds = this.personIds;
    }
    return {
      timestampFrom: this.dateFrom ? new Date(this.dateFrom).getTime() : null,
      timestampTo: this.dateTo
        ? DateUtils.getEndDateOfDay(new Date(this.dateTo)).getTime()
        : null,
      latLongArea: this.latLongArea,
      tagIds: this.selectedTags.map((t) => t.id),
      tagMatchMode: this.tagMatchMode,
      pictureDescription: this.pictureDescriptionSearchPattern,
      untaggedPicturesOnly: this.untaggedPicturesOnly,
      regionId: this.regionId,
      personSearchPattern: this.personSearchPattern,
      projectIds: this.projectId ? [this.projectId] : null,
      personIds: personIds,
      properties: this.properties,
      thingId: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId,
      isActive: this.hasAnyActiveFilter()
    };
  }

  protected handleTagCreated(name: string): void {
    if (!this.thing?.id) return;

    const tag = this.entityManager.tagRepository
      .getByThingId(this.thing.id)
      .find((t) => t.name === name);
    if (tag) {
      this.selectedTags.push(tag);
    }
  }

  protected handleTagRemoved(tag: ThingTag): void {
    this.selectedTags = this.selectedTags.filter((t) => t.name !== tag.name);
  }

  protected handleTagsChanged(evt: TagsChangedEvent): void {
    this.selectedTags = evt.detail.tags;
  }

  protected handleRegionChanged(event: TRegionSelectEvent): void {
    this.regionId = event.detail.value;
  }

  protected handlePersonSearchPatternChanged(): void {
    this.updatePictureFilterRateLimited();
  }

  protected handlePictureDescriptionSearchPatternChanged(): void {
    this.updatePictureFilterRateLimitedForText();
  }

  protected handlePropertyWidgetValueChanged(): void {
    this.updatePictureFilterRateLimited();
  }

  protected handleUntaggedPicturesOnlyChanged(): void {
    this.selectedTags = [];
    this.updatePictureFilterRateLimited();
  }

  protected handleTagMatchModeChanged(): void {
    this.updatePictureFilterRateLimited();
  }

  private translate(key: string): string {
    return this.i18n.tr('galleryThing.pictureFilterBar.' + key);
  }

  protected getContentTemplateName(
    widgetType: GalleryThingWidgetType | null
  ): string | null {
    if (!widgetType || widgetType === GalleryThingWidgetType.Property)
      return null;
    return (
      GalleryThingPictureFilterBar.filterBarWidgetTypeToTemplateNameMap[
        widgetType
      ] ?? null
    );
  }

  protected projectNameTransformationFunction(projectName: string): string {
    const date = GalleryThingHelper.getDateFromProjectName(projectName);
    return DateUtils.formatToDateString(date);
  }

  protected projectFilterFunction(project: Project): boolean {
    return (
      project.projectType === ProjectType.GALLERY &&
      !!project.pictureCount &&
      project.pictureCount > 0
    );
  }

  private updatePictureFilterIsSet(): void {
    this.pictureFilterIsSet = this.hasAnyActiveFilter();
  }

  private propertyFilterIsSet(property: PropertyFilter): boolean {
    return !!GalleryThingFilterHelper.isActiveFilter(property);
  }

  private hasAnyActiveFilter(): boolean {
    return (
      !!this.dateFrom ||
      !!this.dateTo ||
      !!this.latLongArea ||
      this.selectedTags.length > 0 ||
      this.untaggedPicturesOnly ||
      !!this.pictureDescriptionSearchPattern ||
      !!this.regionId ||
      !!this.projectId ||
      (this.personIds && this.personIds.length > 0) ||
      !!this.personSearchPattern ||
      this.properties.some((prop) => {
        return this.propertyFilterIsSet(prop);
      })
    );
  }
}

export type GalleryThingFilterResetEvent = NamedCustomEvent<
  'gallery-thing-filter-reset',
  null
>;
