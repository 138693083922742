import { autoinject } from 'aurelia-framework';
import { RouteConfig } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';

import { SocketService } from '../../services/SocketService';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ExportType } from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ExportProjectService } from '../../classes/EntityManager/entities/Project/ExportProjectService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Report } from '../../classes/EntityManager/entities/Report/types';
import { ReportUtils } from '../../classes/EntityManager/entities/Report/ReportUtils';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { PictureFileAutoUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoUploadService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { NavigationService } from '../../services/NavigationService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class export_project {
  @subscribableLifecycle()
  protected readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private project: Project | null = null;
  protected reports: Array<Report> = [];
  protected currentReportType: ReportType | null = null;
  protected currentSortOption: SorterSortOption<Report>;
  protected isConnected = false;
  protected isMobile = false;
  private reportIdToAutoScrollTo: string | null = null;
  protected pictureFilesToUpload: Array<PictureFile> = [];

  private reportSortOptions = ReportUtils.sortOptions;

  private chooseReportExportTypeDropdownViewModel: TooltipContent | null = null;
  private chooseReportExportTypeDropdownViewModel2: TooltipContent | null =
    null;

  private pagination: Pagination<Report> | null = null;
  private subscriptionManager: SubscriptionManager;
  private socketService: SocketService;
  private activeEntitiesService: ActiveEntitiesService;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly exportProjectService: ExportProjectService,
    private readonly pictureFileAutoUploadService: PictureFileAutoUploadService,
    private readonly navigationService: NavigationService,
    subscriptionManagerService: SubscriptionManagerService,
    socketService: SocketService,
    activeEntitiesService: ActiveEntitiesService,
    permissionsService: PermissionsService,
    private readonly i18n: I18N
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.socketService = socketService;
    this.activeEntitiesService = activeEntitiesService;
    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        context: this,
        entityName: EntityName.Project,
        expression: 'project'
      });

    this.currentSortOption = this.reportSortOptions.exported;
  }

  protected activate(
    params: { project_id: string },
    routeConfig: RouteConfig
  ): void {
    this.project =
      this.entityManager.projectRepository.getById(params.project_id) || null;
    assertNotNullOrUndefined(
      this.project,
      `project not found: ${params.project_id}`
    );

    void this.entityManager.joinedProjectsManager.joinProject(this.project.id);
    this.activeEntitiesService.setActiveProject(this.project);

    if (routeConfig.navModel && this.project?.name)
      routeConfig.navModel.title =
        this.i18n.tr(routeConfig.navModel.title) + ' - ' + this.project.name;
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    assertNotNullOrUndefined(this.project, 'project is not available');
    this.subscriptionManager.subscribeToPropertyChange(
      this.project,
      'report_type',
      this.updateReportType.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Report,
      this.updateReports.bind(this)
    );
    this.updateReportType();
    this.updateReports();

    this.subscriptionManager.addDisposable(
      this.pictureFileAutoUploadService.bindPictureFilesToUpload(
        (pictureFilesToUpload) => {
          this.pictureFilesToUpload = pictureFilesToUpload;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveProject(null);
  }

  private updateReportType(): void {
    if (this.project && this.project.report_type) {
      this.currentReportType =
        this.entityManager.reportTypeRepository.getById(
          this.project.report_type
        ) || null;
    } else {
      this.currentReportType = null;
    }
  }

  private updateReports(): void {
    if (this.project) {
      this.reports = this.entityManager.reportRepository.getByProjectId(
        this.project.id
      );
    } else {
      this.reports = [];
    }

    if (this.reportIdToAutoScrollTo) {
      const report = this.entityManager.reportRepository.getById(
        this.reportIdToAutoScrollTo
      );
      if (report) {
        this.goToReport(report);
        this.reportIdToAutoScrollTo = null;
      }
    }
  }

  protected async handleExportPdfClick(): Promise<void> {
    this.closeDropdowns();

    assertNotNullOrUndefined(this.project, 'project is not available');

    const response = await this.exportProjectService.exportProject(
      this.project,
      ExportType.PDF
    );
    if (response.success && response.reportId) {
      this.exportStarted(response.reportId);
    }
  }

  protected async handleExportDocxClick(): Promise<void> {
    this.closeDropdowns();

    assertNotNullOrUndefined(this.project, 'project is not available');

    const response = await this.exportProjectService.exportProject(
      this.project,
      ExportType.DOCX
    );
    if (response.success && response.reportId) {
      this.exportStarted(response.reportId);
    }
  }

  private closeDropdowns(): void {
    if (this.chooseReportExportTypeDropdownViewModel) {
      this.chooseReportExportTypeDropdownViewModel.close();
    }

    if (this.chooseReportExportTypeDropdownViewModel2) {
      this.chooseReportExportTypeDropdownViewModel2.close();
    }
  }

  protected handleExportPicturesClick(): void {
    if (this.project) {
      this.exportProjectService.exportProjectPictures(this.project);
    }
  }

  private exportStarted(reportId: string): void {
    this.reportIdToAutoScrollTo = reportId;
    this.updateReports();
  }

  private goToReport(report: Report): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getReportListItemId(report.id),
      this.pagination,
      report
    );
  }

  private getReportListItemId(reportId: string): string {
    return 'export-project--report-' + reportId;
  }
}
