import { autoinject } from 'aurelia-framework';
import {
  LockName,
  LogoutLockService
} from '../../../services/LogoutLockService';
import { AppEntityManagerApiAdapter } from './AppEntityManagerApiAdapter';
import { JoinedDefectsManager } from './Defect/JoinedDefectsManager';
import { EntityIdService } from './generalServices/EntityIdService/EntityIdService';
import { JoinedProcessTaskGroupManager } from './ProcessTaskGroup/JoinedProcessTaskGroupManager';
import { JoinedProjectsManager } from './Project/JoinedProjectsManager';
import { ProjectMetadataManager } from './Project/ProjectMetadataManager';
import { EntityName } from './types';
import { DisposableContainer } from '../../Utils/DisposableContainer';
import { createRepositoriesByEntityNameFactory } from './createRepositoriesByEntityNameFactory';
import { AppEntityManagerEntityRepositories } from './AppEntityManagerEntityRepositories';
import { AppEntityFilter } from '../filter/AppEntityFilter';
import { SessionService } from '../../../services/SessionService/SessionService';

/**
 * Notable behaviors:
 * * you have to call entityActualization.actualize once before the entitySynchronization starts synchronizing. This is a safety feature to prevent data loss.
 */
@autoinject()
export class AppEntityManager extends AppEntityManagerEntityRepositories {
  private readonly disposableContainer: DisposableContainer;
  private isAuthenticated: boolean = false;
  private readonly internalJoinedProcessTaskGroupManager: JoinedProcessTaskGroupManager;
  private readonly internalJoinedProjectsManager: JoinedProjectsManager;
  private readonly internalJoinedDefectsManager: JoinedDefectsManager;
  private readonly internalProjectMetadataManager: ProjectMetadataManager =
    new ProjectMetadataManager(this);

  private entityFilter: AppEntityFilter | null = null;

  constructor(
    private readonly apiAdapter: AppEntityManagerApiAdapter,
    private readonly logoutLockService: LogoutLockService,
    sessionService: SessionService,
    entityIdService: EntityIdService
  ) {
    super({
      getActualizationsFunction: (infos) => {
        return apiAdapter.getActualizations(infos);
      },
      uploadFunction: (item) => {
        console.log('[AppEntityManager] uploading item', item);
        return apiAdapter.uploadItem(item);
      },
      createRepositoriesByEntityName: createRepositoriesByEntityNameFactory({
        sessionService
      }),
      createEntityFilter: () => {
        if (this.entityFilter) {
          throw new Error('only one entityFilter is allowed to be initialized');
        }

        this.entityFilter = new AppEntityFilter({
          entityManager: this,
          sessionService
        });

        return this.entityFilter;
      },
      entityIdAdapter: entityIdService.getClientEntityIdAdapter()
    });

    this.disposableContainer = new DisposableContainer();
    this.internalJoinedProjectsManager =
      this.createJoinedProjectsManager(apiAdapter);
    this.internalJoinedProcessTaskGroupManager =
      this.createJoinedProcessTaskGroupManager(apiAdapter);
    this.internalJoinedDefectsManager =
      this.createJoinedDefectsManager(apiAdapter);
  }

  public get joinedProcessTaskGroupManager(): JoinedProcessTaskGroupManager {
    return this.internalJoinedProcessTaskGroupManager;
  }

  public get joinedProjectsManager(): JoinedProjectsManager {
    return this.internalJoinedProjectsManager;
  }

  public get joinedDefectsManager(): JoinedDefectsManager {
    return this.internalJoinedDefectsManager;
  }

  public get projectMetadataManager(): ProjectMetadataManager {
    return this.internalProjectMetadataManager;
  }

  public waitForFilterUpdateFinished(): Promise<void> {
    if (!this.entityFilter) {
      return Promise.resolve();
    }

    return this.entityFilter.waitForSubscriptionsToBeFinished();
  }

  public async init(): Promise<void> {
    await super.init();

    this.disposableContainer.add(
      this.joinedProjectsManager.registerHooks({
        onProjectJoined: (projectId) =>
          this.projectMetadataManager.setJoinedProjectOn(projectId),
        onProjectLeft: (projectId) =>
          this.projectMetadataManager.setLeftProjectOn(projectId)
      })
    );

    await this.projectMetadataManager.init();
    await this.joinedProjectsManager.init();

    this.disposableContainer.add(
      this.entityActualization.registerHooks({
        afterActualization: ({ success }) => {
          if (!success) {
            return;
          }

          this.entitySynchronization.unlock(this);

          const lastActualizationInfo =
            this.entityActualization.getLastSuccessfulActualizationInfo();
          const projectIds =
            lastActualizationInfo?.relativeSynchronizationEntityIdsByEntityName.get(
              EntityName.Project
            ) ?? new Set();
          for (const projectId of projectIds) {
            this.projectMetadataManager.setLastActualizedAt(projectId);
          }
        }
      })
    );

    this.disposableContainer.add(
      this.entitySynchronization.bindItemsToSynchronizeInfos(
        (itemsToSynchronizeInfos) => {
          this.logoutLockService.setLockStatus(
            LockName.SYNCHRONIZATION,
            itemsToSynchronizeInfos.filter((i) => !i.failed).length
          );
          this.logoutLockService.setLockStatus(
            LockName.FAILED_SYNCHRONIZATION,
            itemsToSynchronizeInfos.filter((i) => i.failed).length
          );
        }
      )
    );

    this.disposableContainer.add(
      this.apiAdapter.bindIsAuthenticated((isAuthenticated) => {
        if (isAuthenticated !== this.isAuthenticated) {
          this.isAuthenticated = isAuthenticated;
          this.handleIsAuthenticatedChanged();
        }
      })
    );
  }

  public async flush(): Promise<void> {
    await this.joinedProjectsManager.flush();

    await super.flush();
  }

  public destroy(): void {
    this.disposableContainer.disposeAll();
    this.joinedProjectsManager.destroy();
    this.joinedProcessTaskGroupManager.stop();
    this.joinedDefectsManager.stop();
    this.projectMetadataManager.destroy();

    super.destroy();
  }

  private handleIsAuthenticatedChanged(): void {
    if (this.isAuthenticated) {
      this.joinedProcessTaskGroupManager.start();
      this.joinedProjectsManager.start();
      this.entitySynchronization.lock(this);
      this.entitySynchronization.start();
      this.joinedDefectsManager.start();
    } else {
      this.joinedProcessTaskGroupManager.stop();
      this.joinedDefectsManager.stop();
      this.entitySynchronization.stop();
      this.joinedProjectsManager.stop();
    }
  }

  private createJoinedProjectsManager(
    apiAdapter: AppEntityManagerApiAdapter
  ): JoinedProjectsManager {
    return new JoinedProjectsManager(this, {
      joinProject: apiAdapter.joinProject.bind(apiAdapter),
      leaveProject: apiAdapter.leaveProject.bind(apiAdapter)
    });
  }

  private createJoinedProcessTaskGroupManager(
    apiAdapter: AppEntityManagerApiAdapter
  ): JoinedProcessTaskGroupManager {
    return new JoinedProcessTaskGroupManager(this, {
      joinProcessTaskGroup: apiAdapter.joinProcessTaskGroup.bind(apiAdapter),
      leaveProcessTaskGroup: apiAdapter.leaveProcessTaskGroup.bind(apiAdapter)
    });
  }

  public createJoinedDefectsManager(
    apiAdapter: AppEntityManagerApiAdapter
  ): JoinedDefectsManager {
    return new JoinedDefectsManager(this, {
      joinDefect: apiAdapter.joinDefect.bind(apiAdapter),
      leaveDefect: apiAdapter.leaveDefect.bind(apiAdapter)
    });
  }
}
