import { autoinject, computedFrom } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PictureFileAutoDownloadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoDownloadService';
import { PictureFileAutoUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoUploadService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class SynchronizationIcon {
  private readonly subscriptionManager: SubscriptionManager;

  private isActualizing: boolean = false;
  private isConnected: boolean = false;
  private actualizationSuccessful: boolean | null = null;
  private itemsToSynchronizeCount: number = 0;
  private failedItemsToSynchronizeCount: number = 0;

  protected pictureFilesToUpload: Array<PictureFile> = [];
  protected pictureFilesToDownload: Array<PictureFile> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly pictureFileAutoUploadService: PictureFileAutoUploadService,
    private readonly pictureFileAutoDownloadService: PictureFileAutoDownloadService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          this.isActualizing = isActualizing;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: ({ success }) => {
          this.actualizationSuccessful = success;
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.bindItemsToSynchronizeInfos(
        (itemsToSynchronizeInfos) => {
          this.itemsToSynchronizeCount = itemsToSynchronizeInfos.length;
          this.failedItemsToSynchronizeCount = itemsToSynchronizeInfos.filter(
            (i) => i.failed
          ).length;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.pictureFileAutoUploadService.bindPictureFilesToUpload(
        (pictureFilesToUpload) => {
          this.pictureFilesToUpload = pictureFilesToUpload;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.pictureFileAutoDownloadService.bindPictureFilesToDownload(
        (pictureFilesToDownload) => {
          this.pictureFilesToDownload = pictureFilesToDownload;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computedFrom(
    'numberOfSynchronizationItems',
    'isActualizing',
    'synchronizationStatusOk'
  )
  protected get synchronizationStatusClass(): string {
    if (
      this.isActualizing ||
      (this.numberOfSynchronizationItems > 0 &&
        this.synchronizationStatusOk &&
        this.actualizationSuccessful !== false)
    ) {
      return ' fa-spin';
    }
    return '';
  }

  @computedFrom(
    'isConnected',
    'itemsToSynchronizeCount',
    'failedItemsToSynchronizeCount'
  )
  private get synchronizationStatusOk(): boolean {
    return !(
      (!this.isConnected && this.itemsToSynchronizeCount > 0) ||
      this.failedItemsToSynchronizeCount > 0
    );
  }

  @computedFrom('itemsToSynchronizeCount', 'pictureFilesToUpload.length')
  private get numberOfSynchronizationItems(): number {
    return this.itemsToSynchronizeCount + this.pictureFilesToUpload.length;
  }
}
