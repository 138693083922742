import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  EntityNameToSearchResultMap,
  ReasonType
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';

@autoinject()
export class StructureTemplateSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.StructureTemplate]
    | null = null;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager
  ) {}

  protected get itemProperties(): Array<ItemProperty> {
    if (!this.searchResult) return [];

    return [
      {
        labelTk: 'modelProperties.description',
        value: this.searchResult.description
      },
      {
        labelTk: 'modelsDetail.StructureTemplateModel.type',
        value: this.searchResult.type
      },
      {
        labelTk: 'modelsDetail.StructureTemplateModel.status',
        value: this.searchResult.status
      }
    ];
  }

  protected handleNavigateToStructureTemplateClick(): void {
    const route = this.getRouteForStructureTemplateSearchResult();
    this.router.navigate(route);
  }

  private getRouteForStructureTemplateSearchResult(): string {
    assertNotNullOrUndefined(
      this.searchResult,
      'cannot navigate to structure template'
    );

    const structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        this.searchResult.id
      );

    assertNotNullOrUndefined(
      structureTemplate,
      'cannot navigate to structure template'
    );

    switch (this.searchResult.reason.type) {
      case ReasonType.EntityKey:
        return this.router.generate('edit_structure_template', {
          structure_template_id: structureTemplate.id
        });

      default:
        throw new Error(
          `cannot navigate to structure template result with unknown reason ${this.searchResult.reason.type}`
        );
    }
  }
}
