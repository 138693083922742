import { EntityInfo } from '@record-it-npm/synchro-common';

import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';

import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { ProjectQuestion } from '../../../../classes/EntityManager/entities/ProjectQuestion/types';
import { projectQuestionEntityInfo } from '../../../../classes/EntityManager/entities/ProjectQuestion/projectQuestionEntityInfo';

export class ProjectQuestionAdapter implements EntityAdapter<ProjectQuestion> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ProjectQuestionAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      () => updateBindings()
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.roleBasedPermissions != null;
  }

  public canDeleteEntity(projectQuestion: ProjectQuestion): boolean {
    return this.checkProjectQuestionPermission({
      projectQuestion,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanDeleteProjects()
    });
  }

  public canEditField(projectQuestion: ProjectQuestion): boolean {
    return this.checkProjectQuestionPermission({
      projectQuestion,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanUpdateProjects()
    });
  }

  public canEditPictures(projectQuestion: ProjectQuestion): boolean {
    return this.checkProjectQuestionPermission({
      projectQuestion,
      canEditBasedOnRolePermission: (userGroupSpecificPermissions) =>
        userGroupSpecificPermissions.getCanUpdateProjects()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProjectQuestion,
    ProjectQuestion
  > {
    return projectQuestionEntityInfo;
  }

  private checkProjectQuestionPermission({
    projectQuestion,
    canEditBasedOnRolePermission
  }: {
    projectQuestion: ProjectQuestion;
    canEditBasedOnRolePermission: (
      userGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return canEditBasedOnRolePermission(
      this.roleBasedPermissions.inUserGroupId(projectQuestion.ownerUserGroupId)
    );
  }
}

export type ProjectQuestionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
