import { CommonGalleryThingPictureOverviewEntry } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureOverviewEntry';
import { GalleryThingPictureOverviewEntryGroup as CommonGalleryThingPictureOverviewEntryGroup } from 'common/GalleryThing/GalleryThingPictureGroupHelper';

import { Picture } from '../EntityManager/entities/Picture/types';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { Property } from '../EntityManager/entities/Property/types';
import { GalleryThingIconGenerator } from './GalleryThingIconGenerator';
import { PictureFilePathService } from '../EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFile } from '../EntityManager/entities/PictureFile/types';
import { MapMarker } from '../../map/basemap-map/basemap-map';
import { PropertyCache } from '../../computedValues/computers/PropertyCacheComputer/PropertyCacheComputer';
import { GalleryThingBaseMapMarkerGenerator } from './GalleryThingBaseMapMarkerGenerator';
import { Project } from '../EntityManager/entities/Project/types';
import { ProjectPermissionsHandle } from '../../services/PermissionsService/EntitySpecificPermissionsHandle/ProjectPermissionsHandle';

export class GalleryThingPictureOverviewEntryHelper {
  public static async reducePictureToPictureOverviewEntry(
    picture: Picture,
    entityManager: AppEntityManager,
    pictureFile: PictureFile | null,
    iconGenerator: GalleryThingIconGenerator | null,
    pictureFilePathService: PictureFilePathService,
    propertyCache: PropertyCache | null,
    onMarkerClicked: OnBaseMapMarkerClicked
  ): Promise<GalleryThingPictureOverviewEntry> {
    const filePaths = await pictureFilePathService.getPicturePreviews(
      pictureFile,
      false
    );

    const overviewEntry: GalleryThingPictureOverviewEntry = {
      ownerUserGroupId: picture.ownerUserGroupId,
      thumbnailPath: filePaths[0] || null,
      pictureId: picture.id,
      projectId: picture.project ?? null,
      ownerDefectId: picture.ownerDefectId ?? null,
      entryId: picture.entry ?? null,
      regionId: picture.entry
        ? (entityManager.entryRepository.getById(picture.entry)?.regionId ??
          null)
        : null,
      createdAt: picture.createdAt ?? new Date().toISOString(),
      icons: null,
      coords: picture.coords,
      baseMapMarker: null,
      coordsFromPositionedPictureInfo: picture.coordsFromPositionedPictureInfo,
      additional_markings: picture.additional_markings ?? []
    };
    overviewEntry.baseMapMarker =
      GalleryThingBaseMapMarkerGenerator.generateMapMarker(
        overviewEntry,
        propertyCache,
        onMarkerClicked
      );
    overviewEntry.icons =
      iconGenerator?.generatePictureIcons(overviewEntry) ?? null;
    return overviewEntry;
  }

  public static getPropertyByNameFromPictureOverviewEntry(
    entityManager: AppEntityManager,
    overviewEntry: GalleryThingPictureOverviewEntry,
    name: string
  ): Property | null {
    if (!overviewEntry.entryId) return null;
    const entry = entityManager.entryRepository.getById(overviewEntry.entryId);
    if (!entry) return null;
    return (
      entityManager.propertyRepository
        .getByEntryId(entry.id)
        .find((property) => property.name === name) ?? null
    );
  }
}

export type GalleryThingPictureOverviewEntry =
  CommonGalleryThingPictureOverviewEntry<MapMarker>;
export type GalleryThingPictureOverviewEntryGroup =
  CommonGalleryThingPictureOverviewEntryGroup<MapMarker, Project> & {
    projectPermissionsHandle: ProjectPermissionsHandle;
  };

export type OnBaseMapMarkerClicked = (
  entry: GalleryThingPictureOverviewEntry
) => void;
