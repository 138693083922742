import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { reportEntityInfo } from '../../../../classes/EntityManager/entities/Report/reportEntityInfo';
import { Report } from '../../../../classes/EntityManager/entities/Report/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { LockedBySignedReportReportIdsComputer } from '../../../../computedValues/computers/LockedBySignedReportReportIdsComputer/LockedBySignedReportReportIdsComputer';

export class ReportAdapter implements EntityAdapter<Report> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private lockedSigningBySignedReportReportIds: Set<string> | null = null;
  private projectIdsWhereUserIsAuthorized: Set<string> | null = null;

  constructor(options: ReportAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: LockedBySignedReportReportIdsComputer,
        computeData: {},
        callback: ({ lockedSigningReportIds }) => {
          this.lockedSigningBySignedReportReportIds = lockedSigningReportIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ projectIdsWhereUserIsAuthorized }) => {
          this.projectIdsWhereUserIsAuthorized =
            projectIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.subscribeToModelChanges(EntityName.Report, () => {
      updateBindings();
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.lockedSigningBySignedReportReportIds != null &&
      this.projectIdsWhereUserIsAuthorized != null
    );
  }

  public canDeleteEntity(report: Report): boolean {
    return this.checkPermission({
      report,
      checkRolePermission: (permissions) =>
        report.signatureTime == null && permissions.getCanDeleteReports()
    });
  }

  public canEditField(report: Report): boolean {
    return this.checkPermission({
      report,
      checkRolePermission: (permissions) => permissions.getCanUpdateReports()
    });
  }

  public canSignReport(report: Report): boolean {
    return this.checkPermission({
      report,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateReportSignatures()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.Report,
    Report
  > {
    return reportEntityInfo;
  }

  private checkPermission({
    report,
    checkRolePermission
  }: {
    report: Report;
    checkRolePermission: (
      roleBasedUserGroupSpecificPermissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !this.roleBasedPermissions ||
      !this.lockedSigningBySignedReportReportIds
    ) {
      return false;
    }

    if (this.lockedSigningBySignedReportReportIds.has(report.id)) {
      return false;
    }

    if (report.ownerProjectId) {
      return EntityAdapterUtils.checkProjectSubEntityPermission({
        entity: report,
        projectIdsWhereUserIsAuthorized: this.projectIdsWhereUserIsAuthorized,
        roleBasedPermissions: this.roleBasedPermissions,
        checkRolePermission
      });
    }

    return checkRolePermission(
      this.roleBasedPermissions.inUserGroupId(report.ownerUserGroupId)
    );
  }
}

export type ReportAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
