import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Dialogs } from '../../classes/Dialogs';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskChecklistEntryDialogService } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/ProcessTaskChecklistEntryDialogService';
import { SocketService } from '../../services/SocketService';
import { FileDownloadService } from '../../services/FileDownloadService';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event edit-button-clicked
 */
@autoinject()
export class ProcessTaskInvoiceListItem {
  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskInvoicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskInvoice];

  private panelOpened: boolean = false;
  private isAttached: boolean = false;
  private relatedProcessTasks: Array<ProcessTask> = [];

  protected listItemElement: HTMLElement | null = null;

  protected readonly SequenceNumberUtils = SequenceNumberUtils;
  protected readonly SequenceNumberType = SequenceNumberType;

  constructor(
    private readonly element: Element,
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly processTaskChecklistEntryDialogService: ProcessTaskChecklistEntryDialogService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskInvoicePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskInvoice,
        context: this as ProcessTaskInvoiceListItem,
        propertyName: 'processTaskInvoice'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateRelatedProcessTasks.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      this.updateRelatedProcessTasks.bind(this)
    );
    this.updateRelatedProcessTasks();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskInvoiceChanged(): void {
    if (this.isAttached) {
      this.updateRelatedProcessTasks();
    }
  }

  private updateRelatedProcessTasks(): void {
    if (this.processTaskInvoice) {
      const relations =
        this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
          this.processTaskInvoice.id
        );
      const ids = relations.map((r) => r.ownerProcessTaskId);
      this.relatedProcessTasks =
        this.entityManager.processTaskRepository.getByIds(ids);
    } else {
      this.relatedProcessTasks = [];
    }
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleEditButtonClick(): void {
    DomEventHelper.fireEvent<EditButtonClickedEvent>(this.element, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  protected handleDeleteButtonClick(): void {
    const processTaskInvoice = this.processTaskInvoice;
    assertNotNullOrUndefined(
      processTaskInvoice,
      "can't ProcessTaskInvoiceListItem.handleDeleteButtonClick without processTaskInvoice"
    );

    void Dialogs.deleteEntityDialog(processTaskInvoice).then(() => {
      this.entityManager.processTaskInvoiceRepository.delete(
        processTaskInvoice
      );
    });
  }

  protected async handleDownloadInvoiceClick(): Promise<void> {
    const invoice = this.processTaskInvoice;
    if (!invoice) return;

    await this.processTaskChecklistEntryDialogService.confirmProcessTasksChecklistEntries(
      this.relatedProcessTasks
    );

    Dialogs.waitDialog();
    this.socketService.downloadProcessTaskInvoice(invoice.id, (response) => {
      if (response.success) {
        Dialogs.closeAllDialogs();
        void this.fileDownloadService.downloadFileByToken(response.token);
      } else {
        const errorMessage = this.i18n.tr(
          `serverResponses.${
            response.status ? response.status : 'unspecifiedError'
          }`
        );

        void Dialogs.errorDialog('Fehler beim Download!', errorMessage);
      }
    });
  }
}

export type EditButtonClickedEvent = NamedCustomEvent<
  'edit-button-clicked',
  null
>;
