import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { nfcTokenToPersonEntityInfo } from '../../../../classes/EntityManager/entities/NfcTokenToPerson/nfcTokenToPersonEntityInfo';
import { NfcTokenToPerson } from '../../../../classes/EntityManager/entities/NfcTokenToPerson/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { PersonAdapter } from '../PersonAdapter/PersonAdapter';

export class NfcTokenToPersonAdapter
  implements EntityAdapter<NfcTokenToPerson>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private personAdapter: PersonAdapter | null = null;

  constructor(options: NfcTokenToPersonAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Person,
        onNextAdapter: ({ adapter }) => {
          this.personAdapter = adapter as PersonAdapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return this.personAdapter !== null;
  }

  public canEditField(nfcTokenToPerson: NfcTokenToPerson): boolean {
    return this.getCanUpdateEntity(nfcTokenToPerson);
  }

  public canDeleteEntity(nfcTokenToPerson: NfcTokenToPerson): boolean {
    return this.getCanUpdateEntity(nfcTokenToPerson);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.NfcTokenToPerson,
    NfcTokenToPerson
  > {
    return nfcTokenToPersonEntityInfo;
  }

  private getCanUpdateEntity(nfcTokenToPerson: NfcTokenToPerson): boolean {
    if (!this.personAdapter) {
      return false;
    }

    const person = this.entityManager.personRepository.getById(
      nfcTokenToPerson.personId
    );

    if (!person) {
      return false;
    }

    return this.personAdapter.canEditNfcTokens(person);
  }
}

export type NfcTokenToPersonAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
