import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  EntityNameToSearchResultMap,
  ReasonType
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class DefectSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.Defect]
    | null = null;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager
  ) {}

  @computed(
    expression('searchResult.description'),
    expression('searchResult.sequenceNumber'),
    expression('searchResult.status'),
    expression('searchResult.dueAt'),
    expression('searchResult.assigneeId')
  )
  protected get itemProperties(): Array<ItemProperty> {
    if (!this.searchResult) return [];

    return [
      {
        labelTk: 'modelProperties.description',
        value: this.searchResult.description
      },
      {
        labelTk: 'modelProperties.sequenceNumber',
        value: this.searchResult.sequenceNumber?.toString() ?? ''
      },
      {
        labelTk: 'modelProperties.status',
        value: this.searchResult.status
      },
      {
        labelTk: 'modelProperties.dueAt',
        value: DateUtils.formatToDateString(this.searchResult.dueAt)
      },
      {
        labelTk: 'modelProperties.assigneeUserId',
        value: this.searchResult.assigneeId
      }
    ];
  }

  protected handleNavigateToDefectClick(): void {
    const route = this.getRouteForDefectSearchResult();
    this.router.navigate(route);
  }

  private getRouteForDefectSearchResult(): string {
    assertNotNullOrUndefined(this.searchResult, 'cannot navigate to defect');

    const defect = this.entityManager.defectRepository.getById(
      this.searchResult.id
    );

    assertNotNullOrUndefined(defect, 'cannot navigate to defect');

    switch (this.searchResult.reason.type) {
      case ReasonType.EntityKey:
        return this.router.generate('edit_gallery_object', {
          thing_id: defect.ownerThingId,
          defect_id: defect.id
        });

      default:
        throw new Error(
          `cannot navigate to defect result with unknown reason ${this.searchResult.reason.type}`
        );
    }
  }
}
