import { bindable, autoinject } from 'aurelia-framework';

import { AngleHelper } from 'common/Geometry/AngleHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { NumberUtils } from 'common/Utils/NumberUtils';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { ImageHelper } from '../../classes/ImageHelper';
import { SvgLoader } from '../../classes/Svg/SvgLoader';
import { SvgRotator } from '../../classes/Svg/SvgRotator';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';
import {
  PositionAndSizeChangedEvent,
  RepositionableElementPositionAndSize
} from '../repositionable-element/repositionable-element';

/**
 * @event editing-aborted will fire when the editing is aborted
 * @event editing-finished will fire when the editing is finished
 * @event stopped-editing will fire when the editing gets stopped (aborted or is finished), doesn't bubble
 */
@autoinject()
export class PictureEditor {
  @bindable()
  public picture: Picture | null = null;

  private domElement: HTMLElement;
  private rotation: number = 0;
  private originalImage: HTMLImageElement | null = null;
  protected errorTextTk: string | null = null;
  private isAttached: boolean = false;

  private drawingCanvas: HTMLCanvasElement | null = null;

  protected cutoutPositionInfo = this.getDefaultCutoutPositionInfo();

  constructor(
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    element: Element
  ) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;

    if (this.picture) {
      void this.updateOriginalImage();
    }
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected pictureChanged(): void {
    if (this.isAttached) {
      void this.updateOriginalImage();
    }
  }

  private async updateOriginalImage(): Promise<void> {
    // since we can't abort pending requests, we have to check if the loaded image is really for our picture
    const usedPicture = this.picture;

    const pictureFile = usedPicture
      ? this.pictureFileByActivePictureRevisionService.getOriginalPictureFileByPictureId(
          usedPicture.id
        )
      : null;

    const src = pictureFile
      ? await this.pictureFilePathService.getPictureFileSource(pictureFile)
      : null;

    try {
      this.clearCanvas();
      this.errorTextTk = null;

      if (!src) {
        throw new Error('no src found for original image');
      }

      const image = await ImageHelper.loadImage(src);
      if (this.picture === usedPicture) {
        this.originalImage = image;
        this.updateCanvas(this.originalImage);
      }
    } catch (e) {
      console.error(e);
      if (this.picture === usedPicture) {
        this.errorTextTk = 'aureliaComponents.pictureEditor.loadError';
      }
    }
  }

  /**
   * @param direction - pass a 1 to turn clockwise, or -1 to turn counterclockwise
   */
  protected handleRotateClick(direction: -1 | 1): void {
    assertNotNullOrUndefined(
      this.originalImage,
      "can't handleRotateClick without an originalImage"
    );

    this.resetCutout();

    const rotation = (this.rotation + (direction >= 0 ? 90 : -90)) % 360;
    this.rotation = AngleHelper.simplifyDegAngle(rotation);

    this.updateCanvas(this.originalImage);
  }

  protected handleAbortClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'editing-aborted',
      detail: null
    });
    this.stopEditing();
  }

  protected async handleFinishClick(): Promise<void> {
    try {
      await this.saveImages();
      DomEventHelper.fireEvent(this.domElement, {
        name: 'editing-finished',
        detail: null
      });
      this.stopEditing();
    } catch (e) {
      console.error(e);
      this.errorTextTk = 'aureliaComponents.pictureEditor.saveError';
    }
  }

  protected handleResetCanvasPositionClick(): void {
    this.resetCutout();
    this.resetRotation();
  }

  protected getBackdropStyle(
    top: number,
    left: number,
    width: number,
    height: number
  ): Record<string, any> {
    const bottom = top + height;
    const right = left + width;

    return {
      'clip-path': `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, 0% 0%, ${left}% ${top}%, ${right}% ${top}%, ${right}% ${bottom}%, ${left}% ${bottom}%, ${left}% ${top}%)`
    };
  }

  protected handlePositionAndSizeChanged(
    event: PositionAndSizeChangedEvent
  ): void {
    this.setCutoutPositionAndSizeClamped({
      positionAndSize: event.detail.positionAndSize
    });
  }

  private setCutoutPositionAndSizeClamped({
    positionAndSize
  }: {
    positionAndSize: Omit<RepositionableElementPositionAndSize, 'rotation'>;
  }): void {
    const clampedPositionAndSize = this.clampPositionAndSize({
      positionAndSize
    });

    this.cutoutPositionInfo.left = clampedPositionAndSize.left;
    this.cutoutPositionInfo.top = clampedPositionAndSize.top;
    this.cutoutPositionInfo.width = clampedPositionAndSize.width;
    this.cutoutPositionInfo.height = clampedPositionAndSize.height;
  }

  private clampPositionAndSize({
    positionAndSize
  }: {
    positionAndSize: Omit<RepositionableElementPositionAndSize, 'rotation'>;
  }): Omit<RepositionableElementPositionAndSize, 'rotation'> {
    const clampedWidth = NumberUtils.clamp({
      value: positionAndSize.width,
      min: 0,
      max: 100
    });

    const clampedHeight = NumberUtils.clamp({
      value: positionAndSize.height,
      min: 0,
      max: 100
    });

    const clampedTop = NumberUtils.clamp({
      value: positionAndSize.top,
      min: 0,
      max: 100 - clampedHeight
    });

    const clampedLeft = NumberUtils.clamp({
      value: positionAndSize.left,
      min: 0,
      max: 100 - clampedWidth
    });

    return {
      top: clampedTop,
      left: clampedLeft,
      width: clampedWidth,
      height: clampedHeight
    };
  }

  private stopEditing(): void {
    this.resetCutout();
    this.resetRotation();
    this.originalImage = null;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'stopped-editing',
      detail: null
    });
  }

  private async saveImages(): Promise<void> {
    // cache the variables because we have a lot of async stuff and it could be null in the meantime
    const picture = this.picture;
    const originalImage = this.originalImage;
    assertNotNullOrUndefined(
      picture,
      "can't handleFinishClick without a picture"
    );
    assertNotNullOrUndefined(
      originalImage,
      "can't handleFinishClick without an originalImage"
    );

    const loadSketchResult = await this.loadSketchImage(picture);

    if (this.rotation) {
      this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
        picture,
        this.rotateImage(originalImage, 'image/jpeg')
      );
    }

    const { destImgWidth, destImgHeight } = this.getDestinationImageSize();

    let sketchResultImage = null;

    if (loadSketchResult) {
      sketchResultImage = await this.saveRotatedAndScaledSketch({
        sketchPictureFile: loadSketchResult.pictureFile,
        picture,
        sketchImage: loadSketchResult.image,
        destImgWidth,
        destImgHeight
      });
    }

    const res = this.renderCroppedImage({
      overlayImage: sketchResultImage,
      destImgWidth,
      destImgHeight
    });

    this.savePictureFileDataUrlService.saveEditedPictureDataUrl(
      picture,
      res.editedDataUrl
    );
    this.savePictureFileDataUrlService.saveCroppedPictureDataUrl(
      picture,
      res.croppedDataUrl
    );
  }

  private async loadSketchImage(
    picture: Picture
  ): Promise<{ image: HTMLImageElement; pictureFile: PictureFile } | null> {
    const pictureFile =
      this.pictureFileByActivePictureRevisionService.getSketchPictureFileByPictureId(
        picture.id
      );

    if (!pictureFile) return null;

    const src =
      await this.pictureFilePathService.getPictureFileSource(pictureFile);

    if (src) {
      return {
        image: await ImageHelper.loadImage(src),
        pictureFile: pictureFile
      };
    } else {
      throw new Error('no sketch src found');
    }
  }

  private async saveRotatedAndScaledSketch({
    sketchPictureFile,
    picture,
    sketchImage,
    destImgWidth,
    destImgHeight
  }: {
    sketchPictureFile: PictureFile;
    picture: Picture;
    sketchImage: HTMLImageElement;
    destImgWidth: number;
    destImgHeight: number;
  }): Promise<HTMLImageElement> {
    let dataUrl;

    if (sketchPictureFile.file_extension === 'svg') {
      dataUrl = await this.generateRotatedAndScaledSketch({
        src: sketchImage.src,
        destImgWidth,
        destImgHeight
      });

      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        dataUrl
      );
    } else {
      dataUrl = this.rotateImage(sketchImage, 'image/png');

      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        dataUrl
      );
    }

    return ImageHelper.loadImage(dataUrl);
  }

  private async generateRotatedAndScaledSketch({
    src,
    destImgWidth,
    destImgHeight
  }: {
    src: string;
    destImgWidth: number;
    destImgHeight: number;
  }): Promise<string> {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      'cannot generate rotated and scaled sketch without a drawing canvas'
    );

    const loader = new SvgLoader();
    const svg = await loader.load(src);

    const rotator = new SvgRotator(svg, this.rotation);
    rotator.rotate();

    const svgSrcWidth = svg.viewBox.baseVal.width;
    const svgSrcHeight = svg.viewBox.baseVal.height;

    const shouldScaleHeight = svgSrcWidth === 1920 || svgSrcHeight !== 1920;

    const destAspectRatio = destImgWidth / destImgHeight;

    const svgDestWidth = shouldScaleHeight
      ? svgSrcWidth
      : svgSrcHeight * destAspectRatio;

    const svgDestHeight = shouldScaleHeight
      ? svgSrcWidth / destAspectRatio
      : svgSrcHeight;

    svg.viewBox.baseVal.width = svgDestWidth;
    svg.viewBox.baseVal.height = svgDestHeight;

    return ImageHelper.svgElementToDataUrl(svg);
  }

  /**
   * canvas has to already have the size of the _img
   *
   * also rotates the canvas if needed
   *
   * if an overlayImage is given, the merged image is available in editedDataUrl
   * else editedDataUrl is the same as the croppedDataUrl
   */
  private renderCroppedImage({
    overlayImage,
    destImgWidth,
    destImgHeight
  }: {
    overlayImage: HTMLImageElement | null;
    destImgWidth: number;
    destImgHeight: number;
  }): {
    destImgHeight: number;
    destImgWidth: number;
    croppedDataUrl: string;
    editedDataUrl: string;
  } {
    const originalImage = this.originalImage;

    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't renderCroppedImage without a drawingCanvas"
    );

    assertNotNullOrUndefined(
      originalImage,
      "can't renderCroppedImage without an originalImage"
    );

    const cutoutLeft =
      (this.cutoutPositionInfo.left / 100) * this.drawingCanvas.width;

    const cutoutTop =
      (this.cutoutPositionInfo.top / 100) * this.drawingCanvas.height;

    const cutoutWidth =
      (this.cutoutPositionInfo.width / 100) * this.drawingCanvas.width;

    const cutoutHeight =
      (this.cutoutPositionInfo.height / 100) * this.drawingCanvas.height;

    const canvas = document.createElement('canvas');

    canvas.width = destImgWidth;
    canvas.height = destImgHeight;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, "canvas didn't generate a drawingContext");

    ctx.drawImage(
      this.drawingCanvas,

      cutoutLeft, // src image rect x start
      cutoutTop, // src image rect y start
      cutoutWidth, // src image rect width
      cutoutHeight, // src image rect height

      0, // destination x start
      0, // destination y start
      destImgWidth, // destination width
      destImgHeight // destination height
    );

    const dataUrl = canvas.toDataURL('image/jpeg');

    const returnValue = {
      destImgWidth,
      destImgHeight,
      croppedDataUrl: dataUrl,
      editedDataUrl: dataUrl
    };

    if (overlayImage) {
      this.renderOverlayImage(overlayImage, canvas);
      returnValue.editedDataUrl = canvas.toDataURL('image/jpeg');
    }

    return returnValue;
  }

  private getDestinationImageSize(): {
    destImgHeight: number;
    destImgWidth: number;
  } {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't renderCroppedImage without a drawingCanvas"
    );

    const cutoutWidth =
      (this.cutoutPositionInfo.width / 100) * this.drawingCanvas.width;

    const cutoutHeight =
      (this.cutoutPositionInfo.height / 100) * this.drawingCanvas.height;

    const cutoutAspectRatio = cutoutWidth / cutoutHeight;

    const drawingCanvasAspectRatio =
      this.drawingCanvas.width / this.drawingCanvas.height;

    let destImgWidth;
    let destImgHeight;

    if (cutoutAspectRatio > drawingCanvasAspectRatio) {
      destImgWidth = this.drawingCanvas.width;

      destImgHeight =
        this.drawingCanvas.height *
        (drawingCanvasAspectRatio / cutoutAspectRatio);
    } else {
      destImgWidth =
        this.drawingCanvas.width *
        (cutoutAspectRatio / drawingCanvasAspectRatio);

      destImgHeight = this.drawingCanvas.height;
    }

    return {
      destImgWidth,
      destImgHeight
    };
  }

  private renderOverlayImage(
    overlayImage: HTMLImageElement,
    canvas: HTMLCanvasElement
  ): void {
    const context = canvas.getContext('2d');
    assertNotNullOrUndefined(context, 'drawing context does not exist');

    context.drawImage(
      overlayImage,
      0, // destination x start
      0, // destination y start
      canvas.width, // destination width
      canvas.height // destination height
    );
  }

  /**
   * @param img
   * @param type
   * @returns - a png base64 of the rotated image
   * @private
   */
  private rotateImage(
    img: HTMLImageElement,
    type: 'image/jpeg' | 'image/png'
  ): string {
    const canvas = document.createElement('canvas');
    if (this.rotation === 0 || this.rotation === 180) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
    } else {
      canvas.width = img.naturalHeight;
      canvas.height = img.naturalWidth;
    }

    ImageHelper.drawInRotatedCanvas(canvas, this.rotation, (ctx) => {
      ctx.drawImage(
        img,
        -(img.naturalWidth / 2), // destination x start
        -(img.naturalHeight / 2) // destination y start
      );
    });

    return canvas.toDataURL(type);
  }

  protected calculateImageContainerPaddingBottom(
    width: number,
    height: number,
    rotation: number
  ): string {
    if (rotation === 0 || rotation === 180) {
      return (height / width) * 100 + '%';
    } else {
      return (width / height) * 100 + '%';
    }
  }

  private clearCanvas(): void {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't updateCanvas without a drawingCanvas"
    );
    const ctx = this.drawingCanvas.getContext('2d');
    assertNotNullOrUndefined(
      ctx,
      "canvas didn't generate a context in clearCanvas"
    );
    ctx.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
    this.drawingCanvas.height = 0;
    this.drawingCanvas.width = 0;
  }

  private updateCanvas(img: HTMLImageElement): void {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't updateCanvas without a drawingCanvas"
    );

    if (this.rotation === 0 || this.rotation === 180) {
      this.drawingCanvas.height = img.naturalHeight;
      this.drawingCanvas.width = img.naturalWidth;
    } else {
      this.drawingCanvas.height = img.naturalWidth;
      this.drawingCanvas.width = img.naturalHeight;
    }

    ImageHelper.drawInRotatedCanvas(
      this.drawingCanvas,
      this.rotation,
      (ctx) => {
        ctx.drawImage(
          img,
          -(img.naturalWidth / 2),
          -(img.naturalHeight / 2),
          img.naturalWidth,
          img.naturalHeight
        );
      }
    );
  }

  private resetCutout(): void {
    this.cutoutPositionInfo = this.getDefaultCutoutPositionInfo();
  }

  private resetRotation(): void {
    this.rotation = 0;
    if (this.originalImage) {
      this.updateCanvas(this.originalImage);
    }
  }

  private getDefaultCutoutPositionInfo(): RepositionableElementPositionAndSize {
    return {
      top: 0,
      left: 0,
      width: 100,
      height: 100,
      rotation: 0
    };
  }
}
