import { IUtilsRateLimitedFunction } from './Utils/Utils';
import { UiUpdater } from './UiUpdater';
import { Disposable, DisposableContainer } from './Utils/DisposableContainer';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingEngine, ICollectionObserverSplice } from 'aurelia-binding';
import { SocketService } from '../services/SocketService';
import { ComputedValueService } from '../computedValues/ComputedValueService';
import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { EntityName } from './EntityManager/entities/types';
import { Subscription } from 'rxjs';
import { SubscriptionUtils } from './Utils/SubscriptionUtils/SubscriptionUtils';

export class SubscriptionManager {
  private readonly disposableContainer = new DisposableContainer();
  private subSubscriptionManagers: Array<SubscriptionManager> = [];
  private rateLimitedFunctions: Array<IUtilsRateLimitedFunction> = [];

  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly bindingEngine: BindingEngine,
    private readonly socketService: SocketService,
    private readonly computedValueService: ComputedValueService,
    private readonly getEntityManager: () => AppEntityManager
  ) {}

  /**
   * @param eventName
   * @param callback
   * @param rateInterval - the maximum rate the callback is allowed to be called
   */
  public subscribeToEvent(
    eventName: string,
    callback: (...args: Array<any>) => void,
    rateInterval?: number
  ): void {
    const rateLimitedCallback = this.createRateLimitedCallback(
      callback,
      rateInterval
    );

    this.disposableContainer.add(
      this.eventAggregator.subscribe(eventName, rateLimitedCallback)
    );
  }

  public subscribeToMultipleEvents(
    eventNames: Array<string>,
    callback: (...args: Array<any>) => void,
    rateInterval?: number
  ): void {
    for (const eventName of eventNames) {
      this.subscribeToEvent(eventName, callback, rateInterval);
    }
  }

  /**
   * will call the callback anytime when one entity of `entityName` got changed
   * be careful: you will miss some events because there is a rateInterval
   * if you need to receive all events you have to unset the rate interval
   */
  public subscribeToModelChanges(
    entityName: EntityName,
    callback: () => void,
    rateInterval: number = 250
  ): void {
    this.subscribeToMultipleModelChanges([entityName], callback, rateInterval);
  }

  /**
   * will call the callback anytime when one entity of `entityName` got changed
   * be careful: you will miss some events because there is a rateInterval
   * if you need to receive all events you have to unset the rate interval
   */
  public subscribeToMultipleModelChanges(
    entityNames: Array<EntityName>,
    callback: () => void,
    rateInterval: number = 250
  ): void {
    const result = SubscriptionUtils.subscribeToMultipleModelChanges({
      entityManager: this.getEntityManager(),
      entityNames,
      callback,
      listenToVisibilityChanges: true,
      rateInterval
    });

    this.disposableContainer.add(result);
    this.rateLimitedFunctions.push(result.rateLimitedCallback);
  }

  public subscribeToProjectJoinedChanged(
    callback: (projectId: string) => void
  ): void {
    this.disposableContainer.add(
      this.getEntityManager().joinedProjectsManager.registerHooks({
        onJoinedStatusChanged: callback
      })
    );
  }

  /**
   * @deprecated - you should use subscribeToExpression instead
   */
  public subscribeToPropertyChange(
    context: Record<string, any>,
    propertyName: string,
    callback: PropertyObserverCallback
  ): void {
    this.disposableContainer.add(
      this.bindingEngine
        .propertyObserver(context, propertyName)
        .subscribe(callback)
    );
  }

  /**
   * @deprecated - you should use subscribeToExpression instead
   */
  public subscribeToMultiplePropertyChanges(
    context: Record<string, any>,
    propertyNames: Array<string>,
    callback: PropertyObserverCallback
  ): void {
    for (const propertyName of propertyNames) {
      this.subscribeToPropertyChange(context, propertyName, callback);
    }
  }

  public subscribeToArrayChanges(
    context: Array<any>,
    callback: CollectionObserverCallback
  ): void {
    this.disposableContainer.add(
      this.bindingEngine.collectionObserver(context).subscribe(callback)
    );
  }

  /**
   * subscribes to changes on a certain property of the context
   * the callback will be called if an new array gets assigned, or if the array itself has internal changes (e.g. an item has been pushed into it)
   */
  public subscribeToArrayPropertyChanges(
    context: Record<string, any>,
    propertyName: string,
    callback: CollectionObserverCallback
  ): void {
    const manager = this.createAndRegisterSubSubscriptionManager();

    const instanceChanged = (): void => {
      manager.disposeSubscriptions();
      if (context[propertyName]) {
        manager.subscribeToArrayChanges(context[propertyName], callback);
      }
    };

    this.subscribeToExpression(context, propertyName, () => {
      instanceChanged();
      callback([]);
    });

    instanceChanged();
  }

  public subscribeToInterval(callback: () => void, timeout: number): void {
    const id = setInterval(callback, timeout);
    this.disposableContainer.add({
      dispose: () => {
        clearInterval(id);
      }
    });
  }

  public subscribeToTimeout(callback: () => void, timeout: number): void {
    const id = setTimeout(callback, timeout);
    this.disposableContainer.add({
      dispose: () => {
        clearTimeout(id);
      }
    });
  }

  public subscribeToDomEvent<K extends keyof DocumentEventMap>(
    element: Document,
    eventName: K,
    callback: (event: DocumentEventMap[K]) => void
  ): void;

  public subscribeToDomEvent<K extends keyof HTMLElementEventMap>(
    element: HTMLElement,
    eventName: K,
    callback: (event: HTMLElementEventMap[K]) => void
  ): void;

  public subscribeToDomEvent(
    element: EventTarget,
    eventName: string,
    callback: (event: Event) => void
  ): void;

  public subscribeToDomEvent(
    element: EventTarget,
    eventName: string,
    callback: (event: Event) => void
  ): void {
    element.addEventListener(eventName, callback);

    this.disposableContainer.add({
      dispose: () => {
        element.removeEventListener(eventName, callback);
      }
    });
  }

  public subscribeToExpression(
    context: Record<string, any>,
    expression: string,
    callback: (newValue: any, oldValue: any) => void
  ): void {
    const observer = this.bindingEngine.expressionObserver(context, expression);
    this.disposableContainer.add(observer.subscribe(callback));
  }

  public listenToSocket(
    eventName: string,
    callback: (...args: Array<any>) => void
  ): void {
    this.socketService.addListener(eventName, callback);

    this.disposableContainer.add({
      dispose: () => {
        this.socketService.removeListener(eventName, callback);
      }
    });
  }

  public subscribeToResize(callback: () => void): void {
    UiUpdater.registerResizeUpdateFunction(callback);

    this.disposableContainer.add({
      dispose: () => {
        UiUpdater.unregisterResizeUpdateFunction(callback);
      }
    });
  }

  public createRateLimitedCallback(
    callback: () => void,
    rateInterval: number | undefined
  ): () => void {
    const rateLimitedFunction = SubscriptionUtils.createRateLimitedCallback({
      callback,
      rateInterval
    });

    this.rateLimitedFunctions.push(rateLimitedFunction);

    return rateLimitedFunction;
  }

  public addDisposable(
    ...disposables: [Disposable, ...Array<Disposable>]
  ): void {
    for (const disposable of disposables) {
      this.disposableContainer.add(disposable);
    }
  }

  public addRxjsSubscription(subscription: Subscription): void {
    this.disposableContainer.add({
      dispose: () => {
        subscription.unsubscribe();
      }
    });
  }

  public flush(): void {
    for (const rateLimitedFunction of this.rateLimitedFunctions) {
      rateLimitedFunction.callImmediatelyIfPending();
    }

    for (const subSubscriptionManager of this.subSubscriptionManagers) {
      subSubscriptionManager.flush();
    }
  }

  public cancelPendingSubscriptions(): void {
    for (const rateLimitedFunction of this.rateLimitedFunctions) {
      rateLimitedFunction.cancel();
    }
  }

  public toDisposable(): Disposable {
    return {
      dispose: () => {
        this.disposeSubscriptions();
      }
    };
  }

  public disposeSubscriptions(): void {
    this.cancelPendingSubscriptions();
    this.disposableContainer.disposeAll();

    this.subSubscriptionManagers.forEach((m) => m.disposeSubscriptions());
    this.subSubscriptionManagers = [];
    this.rateLimitedFunctions = [];
  }

  private createAndRegisterSubSubscriptionManager(): SubscriptionManager {
    const manager = new SubscriptionManager(
      this.eventAggregator,
      this.bindingEngine,
      this.socketService,
      this.computedValueService,
      this.getEntityManager
    );
    this.subSubscriptionManagers.push(manager);
    return manager;
  }
}

export type PropertyObserverCallback = (newValue: any, oldValue: any) => void;

export type CollectionObserverCallback = (
  changeRecords: Array<ICollectionObserverSplice>
) => void;
