import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { PersonToPerson } from '../../../classes/EntityManager/entities/PersonToPerson/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class EditPersonRelationsWidgetListItem {
  @bindable()
  public person: Person | null = null;

  @bindable()
  public personToPerson: PersonToPerson | null = null;

  @subscribableLifecycle()
  protected readonly personToPersonPermissionsHandle: EntityNameToPermissionsHandle[EntityName.PersonToPerson];

  constructor(
    private readonly router: Router,
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.personToPersonPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.PersonToPerson,
        context: this as EditPersonRelationsWidgetListItem,
        propertyName: 'personToPerson'
      });
  }

  protected handleDeleteButtonClicked(): void {
    const personToPerson = this.personToPerson;
    assertNotNullOrUndefined(
      personToPerson,
      "can't EditPersonRelationsWidgetListItem.handleDeleteButtonClicked without personToPerson"
    );

    void Dialogs.deleteEntityDialog(personToPerson).then(() => {
      this.entityManager.personToPersonRepository.delete(personToPerson);
    });
  }

  protected handlePersonToPersonChanged(): void {
    assertNotNullOrUndefined(
      this.personToPerson,
      "can't EditPersonRelationsWidgetListItem.handleDeleteButtonClicked without personToPerson"
    );

    this.entityManager.personToPersonRepository.update(this.personToPerson);
  }

  @computed(expression('otherPersonId'))
  protected get otherPersonHref(): string | null {
    const otherPersonId = this.otherPersonId;

    if (!otherPersonId) {
      return null;
    }

    return PersonUtils.getEditPersonPageUrl(this.router, otherPersonId);
  }

  @computed(expression('otherPersonId'), model(EntityName.Person))
  protected get otherPersonName(): string {
    const person = this.otherPersonId
      ? this.entityManager.personRepository.getById(this.otherPersonId)
      : null;

    if (person) {
      return PersonUtils.getPersonDisplayNameForPerson(person);
    }

    return this.i18n.tr('modelsDetail.PersonModel.personNotFound');
  }

  @computed(
    expression('person.id'),
    expression('personToPerson.person1Id'),
    expression('personToPerson.person2Id')
  )
  protected get otherPersonId(): string | null {
    if (!this.personToPerson || !this.person) {
      return null;
    }

    return this.personToPerson.person1Id !== this.person.id
      ? this.personToPerson.person1Id
      : this.personToPerson.person2Id;
  }
}
