import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { computed } from '../../../../hooks/computed';
import { watch } from '../../../../hooks/watch';
import { expression, model } from '../../../../hooks/dependencies';
import { Thing } from '../../../../classes/EntityManager/entities/Thing/types';
import { GalleryThingPictureDataSource } from '../../../../galleryThing/gallery-thing-picture-overview/GalleryThingPictureDataSource/GalleryThingPictureDataSource';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../../../services/PermissionBindingService';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { GalleryThingPictureCreatorService } from '../../../../services/GalleryThingPictureCreatorService';
import { GalleryThingPictureFilterService } from '../../../../services/GalleryThingPictureFilterService/GalleryThingPictureFilterService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SocketService } from '../../../../services/SocketService';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { PictureFilePathService } from '../../../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFileUploadService } from '../../../../classes/EntityManager/entities/PictureFile/PictureFileUploadService';
import { SingleSocketRequestService } from '../../../../services/SingleSocketRequestService/SingleSocketRequestService';
import { PictureFileByActivePictureRevisionService } from '../../../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';
import { GetGalleryThingPicturesOptions } from '../../../../galleryThing/gallery-thing-picture-overview/GalleryThingPictureDataSource/strategies/GalleryThingPictureDataSourceStrategy';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { GalleryThingPictureOverviewEntry } from '../../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';

@autoinject()
export class PictureMapPositioningGpsModePictureFilter {
  @bindable public thing: Thing | null = null;

  /**
   * read only
   */
  @bindable
  public picturesForSelection: Array<GalleryThingPictureOverviewEntry> = [];

  protected latitude: number | null = null;
  protected longitude: number | null = null;
  protected distance: number | null = null;

  private galleryThingPictureDataSource: GalleryThingPictureDataSource | null =
    null;

  private pictureFilter: GalleryThingPictureFilter | null = null;
  private activeUserCompanySettingService: ActiveUserCompanySettingService;
  private galleryThingPictureDataSourceSubscriptionManager: SubscriptionManager;
  private permissionBindings: PermissionBindingHandle;
  private canUseDefectManagement = false;
  private currentPageSize = 10;
  private currentPageIndex = 1;
  private currentPictureGroupSize = 0;
  private maxPictureGroupSize = 0;

  private availableProjects: Array<Project> = [];

  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService,
    private readonly galleryThingPictureCreatorService: GalleryThingPictureCreatorService,
    private readonly galleryThingPictureFilterService: GalleryThingPictureFilterService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    private readonly socketService: SocketService,
    private readonly computedValueService: ComputedValueService,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileUploadService: PictureFileUploadService,
    private readonly singleSocketRequestService: SingleSocketRequestService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.activeUserCompanySettingService = activeUserCompanySettingService;
    this.galleryThingPictureDataSourceSubscriptionManager =
      subscriptionManagerService.create();

    this.permissionBindings = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });
  }

  protected attached(): void {
    this.isAttached = true;
    this.setupGalleryThingPictureDataSource();
  }

  protected detached(): void {
    this.isAttached = false;
    this.galleryThingPictureDataSourceSubscriptionManager.disposeSubscriptions();
  }

  protected thingChanged(): void {
    if (this.isAttached) {
      this.setupGalleryThingPictureDataSource();
    }
  }

  protected handleFilterUpdate(
    filterUpdate: Partial<GalleryThingPictureFilter>
  ): void {
    if (!this.thing) return;
    if (!this.pictureFilter) this.pictureFilter = this.createPictureFilter();

    this.pictureFilter = {
      ...this.pictureFilter,
      ...filterUpdate,
      isActive: this.filterIsSet
    };

    this.setFilter();
  }

  protected handleResetFilterClick(): void {
    this.resetFilter();
  }

  protected parseDateFromDateTimePickerValue(value: string): Date | null {
    return DateUtils.parseDateFromIsoString(value);
  }

  protected updateMaxPageIndex(resultCount: number): void {
    this.maxPictureGroupSize = resultCount;
  }

  @computed(
    expression('maxPictureGroupSize'),
    expression('currentPictureGroupSize')
  )
  protected get showNotAllResultsDisplayedHint(): boolean {
    return this.maxPictureGroupSize > this.currentPictureGroupSize;
  }

  private setupGalleryThingPictureDataSource(): void {
    this.galleryThingPictureDataSourceSubscriptionManager.disposeSubscriptions();

    if (!this.thing) {
      this.galleryThingPictureDataSource = null;
      return;
    }

    this.galleryThingPictureDataSource = new GalleryThingPictureDataSource({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      galleryThingPictureFilterService: this.galleryThingPictureFilterService,
      activeUserCompanySettingService: this.activeUserCompanySettingService,
      computedValueService: this.computedValueService,
      pictureFilePathService: this.pictureFilePathService,
      pictureFileUploadService: this.pictureFileUploadService,
      socketService: this.socketService,
      singleSocketRequestService: this.singleSocketRequestService,
      galleryThingPictureCreatorService: this.galleryThingPictureCreatorService,
      baseMapDataCallbacks: {
        onMarkersChanged: () => {},
        onMarkerClicked: () => {}
      },
      updatePaginationMaxIndex: this.updateMaxPageIndex.bind(this),
      permissionsService: this.permissionsService,
      pictureFileByActivePictureRevisionService:
        this.pictureFileByActivePictureRevisionService
    });
    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      this.galleryThingPictureDataSource
    );

    this.updateAvailableProjects();

    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      ...this.galleryThingPictureDataSource.bindFilteredPictureGroups(
        (pictureGroups) => {
          this.picturesForSelection = pictureGroups
            .map((pg) => pg.pictureOverviewEntries)
            .flat()
            .filter((e) => e.coords);
          this.currentPictureGroupSize = pictureGroups.length;
        }
      )
    );

    this.galleryThingPictureDataSourceSubscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (isConnected) {
          void this.galleryThingPictureDataSource?.useServerStrategy();
        } else {
          void this.galleryThingPictureDataSource?.useEntityManagerStrategy();
        }
      })
    );

    this.resetFilter();
  }

  private resetFilter(): void {
    this.pictureFilter = this.createPictureFilter();
    this.latitude = null;
    this.longitude = null;
    this.distance = null;
    this.setFilter();
  }

  private createPictureFilter(): GalleryThingPictureFilter {
    assertNotNullOrUndefined(
      this.thing,
      'cannot create filter without knowing the current thing'
    );

    return {
      timestampFrom: null,
      timestampTo: null,
      latLongArea: null,
      thingId: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId,
      isActive: false
    };
  }

  @computed(
    expression('pictureFilter.timestampFrom'),
    expression('pictureFilter.timestampTo'),
    expression('pictureFilter.latLongArea')
  )
  private get filterIsSet(): boolean {
    return (
      !!this.pictureFilter?.timestampFrom ||
      !!this.pictureFilter?.timestampTo ||
      !!this.pictureFilter?.latLongArea
    );
  }

  private setFilter(): void {
    if (!this.pictureFilter) return;
    this.galleryThingPictureDataSource?.setFilter(this.pictureFilter, {
      currentIndex: this.currentPageIndex,
      currentPageSize: this.currentPageSize
    });
  }

  @watch(model(EntityName.Project))
  private updateAvailableProjects(): void {
    if (!this.thing) {
      this.availableProjects = [];
      return;
    }

    this.availableProjects =
      this.entityManager.projectRepository.getByThingIdAndTypeReverseSortedByName(
        this.thing.id,
        ProjectType.GALLERY
      );

    this.galleryThingPictureDataSource?.updateGetGalleryThingPicturesOptions(
      this.createGalleryThingPicturesOptions()
    );
  }

  private createGalleryThingPicturesOptions(): GetGalleryThingPicturesOptions {
    assertNotNullOrUndefined(
      this.thing,
      'cannot set galleryThingPictureHandle options without thing'
    );
    return {
      projects: this.availableProjects,
      includeDefects: this.canUseDefectManagement,
      thing: this.thing
    };
  }
}
