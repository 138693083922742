import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { thingTypeEntityInfo } from '../../../../classes/EntityManager/entities/ThingType/thingTypeEntityInfo';
import { ThingType } from '../../../../classes/EntityManager/entities/ThingType/types';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ThingTypeAdapter implements EntityAdapter<ThingType> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private editableUserGroupIds: Set<string> | null = null;
  private currentUser: User | null = null;

  constructor(options: ThingTypeAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.editableUserGroupIds != null && this.currentUser != null;
  }

  public canDeleteEntity(thingType: ThingType): boolean {
    return this.entityIsEditableUserGroup(thingType);
  }

  public canEditField(thingType: ThingType): boolean {
    return this.entityIsEditableUserGroup(thingType);
  }

  public canEditProperties(thingType: ThingType): boolean {
    return this.entityIsEditableUserGroup(thingType);
  }

  public canAdministerValueCalculations(thingType: ThingType): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canAdministerValueCalculations'
        ) && this.entityIsEditableUserGroup(thingType)
      : false;
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ThingType,
    ThingType
  > {
    return thingTypeEntityInfo;
  }

  private entityIsEditableUserGroup(entity: ThingType): boolean {
    if (!this.editableUserGroupIds) {
      return false;
    }

    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type ThingTypeAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
