import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskAppointmentEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/processTaskAppointmentEntityInfo';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskAppointmentAdapter
  implements EntityAdapter<ProcessTaskAppointment>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskIdsWhereUserIsAuthorized: Set<string> | null = null;
  private userGroupIdsWithSmsCredentials: Set<string> | null = null;

  constructor(options: ProcessTaskAppointmentAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskIdsWhereUserIsAuthorized }) => {
          this.processTaskIdsWhereUserIsAuthorized =
            processTaskIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ userGroupIdsWithSmsCredentials }) => {
          this.userGroupIdsWithSmsCredentials = userGroupIdsWithSmsCredentials;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.processTaskIdsWhereUserIsAuthorized != null &&
      this.userGroupIdsWithSmsCredentials != null
    );
  }

  public canDeleteEntity(
    processTaskAppointment: ProcessTaskAppointment
  ): boolean {
    return this.checkPermission({
      processTaskAppointment,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskAppointments()
    });
  }

  public canEditField(processTaskAppointment: ProcessTaskAppointment): boolean {
    return this.checkPermission({
      processTaskAppointment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskAppointments()
    });
  }

  public canEditProperties(
    processTaskAppointment: ProcessTaskAppointment
  ): boolean {
    return this.checkPermission({
      processTaskAppointment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskAppointments()
    });
  }

  public canEditGeneralFiles(
    processTaskAppointment: ProcessTaskAppointment
  ): boolean {
    return this.checkPermission({
      processTaskAppointment,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskAppointments()
    });
  }

  public canSeeSmsNotificationSetting(
    processTaskAppointment: ProcessTaskAppointment
  ): boolean {
    if (!this.userGroupIdsWithSmsCredentials) {
      return false;
    }

    return this.userGroupIdsWithSmsCredentials.has(
      processTaskAppointment.ownerUserGroupId
    );
  }

  public canCreateProcessTaskAppointmentContacts(
    processTaskAppointment: ProcessTaskAppointment
  ): boolean {
    return this.checkPermission({
      processTaskAppointment,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskAppointmentContacts()
    });
  }

  public canCreateProcessTaskAppointmentToUsers(
    processTaskAppointment: ProcessTaskAppointment
  ): boolean {
    return this.checkPermission({
      processTaskAppointment,
      checkRolePermission: (permissions) =>
        permissions.getCanCreateProcessTaskAppointmentToUsers()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskAppointment,
    ProcessTaskAppointment
  > {
    return processTaskAppointmentEntityInfo;
  }

  private checkPermission({
    processTaskAppointment,
    checkRolePermission
  }: {
    processTaskAppointment: ProcessTaskAppointment;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return EntityAdapterUtils.checkProcessTaskSubEntityPermission({
      entity: processTaskAppointment,
      processTaskIdsWhereUserIsAuthorized:
        this.processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskAppointmentAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
