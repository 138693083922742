/* global Msvg */

import { TransformHelper } from '../../../../../common/src/Geometry/TransformHelper';
import { Utils } from '../../../classes/Utils/Utils';

/**
 * this class converts points into svg coordinates
 */
export class SvgPointerEventNormalizer {
  /**
   * @param {MouseEvent} event
   * @param {Msvg.Msvg} msvg
   *
   * @returns {Msvg.Point}
   */
  static normalizeMouseEvent(event, msvg) {
    const element = msvg.getElement();
    const boundingClientRect = element.getBoundingClientRect();

    const unscaledOffsetX = event.clientX - boundingClientRect.left;
    const unscaledOffsetY = event.clientY - boundingClientRect.top;

    const elementScale = this._getScaleOfElement(element);

    const scaledOffsetX = unscaledOffsetX / elementScale.scaleX;
    const scaledOffsetY = unscaledOffsetY / elementScale.scaleY;

    return this.normalizeOffsetCoordinates(
      new Msvg.Point(scaledOffsetX, scaledOffsetY),
      msvg
    );
  }

  /**
   * @param {Event} event
   * @param {Msvg.Msvg} msvg
   *
   * @returns {Msvg.Point}
   */
  static normalizeTouchEvent(event, msvg) {
    // noinspection JSUnresolvedVariable
    const touch = event.targetTouches[0];

    const element = msvg.getElement();
    const boundingClientRect = element.getBoundingClientRect();

    const x = touch ? touch.clientX - boundingClientRect.left : 0;
    const y = touch ? touch.clientY - boundingClientRect.top : 0;

    const elementScale = this._getScaleOfElement(element);

    const scaledX = x / elementScale.scaleX;
    const scaledY = y / elementScale.scaleY;

    return this.normalizeOffsetCoordinates(
      new Msvg.Point(scaledX, scaledY),
      msvg
    );
  }

  /**
   * this is basically the reverse function for normalizeOffsetCoordinates
   * result is relative to the top left border of the svg element
   *
   * @param {Msvg.Point} position
   * @param {Msvg.Msvg} msvg
   * @returns {Msvg.Point}
   */
  static getOffsetCoordinatesForSvgPosition(position, msvg) {
    const computed = window.getComputedStyle(msvg.getElement());
    const viewBox = msvg.getViewBox();

    const widthRatio = parseFloat(computed.width) / viewBox.width;
    const heightRatio = parseFloat(computed.height) / viewBox.height;

    return new Msvg.Point(
      (position.x - viewBox.x) * widthRatio,
      (position.y - viewBox.y) * heightRatio
    );
  }

  /**
   * coord must be relative to the top left of the msvg itself!
   *
   * @param {Msvg.Point} coord
   * @param {Msvg.Msvg} msvg
   * @returns {Msvg.Point}
   */
  static normalizeOffsetCoordinates(coord, msvg) {
    const computed = window.getComputedStyle(msvg.getElement());
    const viewBox = msvg.getViewBox();

    const widthRatio = viewBox.width / parseFloat(computed.width);
    const heightRatio = viewBox.height / parseFloat(computed.height);

    return new Msvg.Point(
      coord.x * widthRatio + viewBox.x,
      coord.y * heightRatio + viewBox.y
    );
  }

  /**
   * @param {Element} element
   * @returns {import('../../../../../common/src/Geometry/TransformHelper').Scale}
   */
  static _getScaleOfElement(element) {
    let scaleX = 1;
    let scaleY = 1;

    Utils.walkThroughParentElements(element.parentElement, (elem) => {
      const computedStyle = window.getComputedStyle(elem);
      const elementScale = TransformHelper.getScaleFromTransformMatrix(
        computedStyle.transform
      );

      if (elementScale) {
        scaleX *= elementScale.scaleX;
        scaleY *= elementScale.scaleY;
      }

      return true;
    });

    return {
      scaleX: scaleX,
      scaleY: scaleY
    };
  }
}
