import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { regionEntityInfo } from '../../../../classes/EntityManager/entities/Region/regionEntityInfo';
import { Region } from '../../../../classes/EntityManager/entities/Region/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class RegionAdapter implements EntityAdapter<Region> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private editableUserGroupIds: Set<string> | null = null;

  constructor(options: RegionAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return this.editableUserGroupIds != null;
  }

  public canEditField(entity: Region): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public canDeleteEntity(entity: Region): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public canEditProperties(entity: Region): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName,
    Region
  > {
    return regionEntityInfo;
  }

  private entityIsEditableUserGroup(entity: Region): boolean {
    if (!this.editableUserGroupIds) {
      return false;
    }

    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type RegionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
