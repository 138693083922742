import { DefectStatus } from '../Enums/DefectStatus';
import { EntityName } from '../Types/Entities/Base/ClientEntityName';
import { ProjectType } from '../Types/Entities/Project/ProjectDto';
import {
  StructureTemplateStatus,
  StructureTemplateType
} from '../Types/Entities/StructureTemplate/StructureTemplateDto';

export type SearchGloballyRequest = {
  searchTerm: string;
  resultsPerPage?: number;
  page?: number;
};

export type SearchGloballyResponse =
  | SearchGloballySuccessResponse
  | SearchGloballyErrorResponse;

export type SearchGloballySuccessResponse = {
  success: true;
  searchResults: GlobalSearchResults;
  totalResultCount: number;
};

export type GlobalSearchResults = Partial<{
  [key in AvailableSearchResultEntities]: SearchResults<key>;
}>;

export type SearchResults<T extends AvailableSearchResultEntities> = {
  entities: Array<EntityNameToSearchResultMap[T]>;
  totalResultCountPerEntity: number;
};

export type AvailableSearchResultEntities =
  | EntityName.Defect
  | EntityName.Entry
  | EntityName.Person
  | EntityName.Project
  | EntityName.ReportType
  | EntityName.StructureTemplate
  | EntityName.Thing
  | EntityName.ThingType;

export type SearchResult = {
  id: string;
  name: string;
  pictureUrl?: string;
};

export type EntityNameToSearchResultMap = ValidateEntityNameToSearchResultMap<{
  [EntityName.Defect]: SearchResult & {
    sequenceNumber: number | null;
    status: DefectStatus | null;
    description: string | null;
    dueAt: Date | null;
    assigneeId: string | null;
    reason: EntityKeyReason | PictureReason | TagReason;
  };
  [EntityName.Entry]: SearchResult & {
    created: number;
    note: string | null;
    projectId: string;
    pageDepthParent: string | null;
    reason: EntityKeyReason | PropertyReason | PictureReason | TagReason;
  };
  [EntityName.Person]: SearchResult & {
    companyName: string | null;
    address: string | null;
    iban: string | null;
    bankAccountHolder: string | null;
    note: string | null;
    vatIdentificationNumber: string | null;
    reason: EntityKeyReason;
  };
  [EntityName.Project]: SearchResult & {
    projectType: ProjectType;
    description: string | null;
    created: number;
    longitude: number | null;
    latitude: number | null;
    reason: EntityKeyReason | PropertyReason | PictureReason | TagReason;
  };
  [EntityName.ReportType]: SearchResult & {
    fileName: string | null;
    created: number;
    reason: EntityKeyReason;
  };
  [EntityName.StructureTemplate]: SearchResult & {
    description: string | null;
    type: StructureTemplateType;
    status: StructureTemplateStatus;
    reason: EntityKeyReason;
  };
  [EntityName.Thing]: SearchResult & {
    description: string | null;
    created: number;
    longitude: number | null;
    latitude: number | null;
    archived: boolean;
    status: string | null;
    reason:
      | EntityKeyReason
      | PropertyReason
      | PictureReason
      | TagReason
      | NfcTagReason;
  };
  [EntityName.ThingType]: SearchResult & {
    created: number;
    reason: EntityKeyReason | PropertyReason;
  };
}>;

type ValidateEntityNameToSearchResultMap<
  T extends Record<
    AvailableSearchResultEntities,
    { reason: Record<string, unknown> }
  >
> = T;

export enum ReasonType {
  EntityKey = 'entityKey',
  NfcTag = 'nfcTag',
  Picture = 'picture',
  Property = 'property',
  Tag = 'tag'
}

type BaseReason<
  T extends ReasonType,
  TData extends Record<string, unknown> | null
> = {
  type: T;
  data: TData;
  pictureUrl?: string;
};

type EntityKeyReason = BaseReason<ReasonType.EntityKey, null>;
export type PictureReason = BaseReason<
  ReasonType.Picture,
  { pictureId: string; description: string }
>;
export type PropertyReason = BaseReason<
  ReasonType.Property,
  { propertyId: string; name: string; value: string }
>;
export type TagReason = BaseReason<
  ReasonType.Tag,
  { tagId: string; name: string }
>;
type NfcTagReason = BaseReason<ReasonType.NfcTag, { nfcTagId: string }>;

export type SearchResultReason =
  | EntityKeyReason
  | PictureReason
  | PropertyReason
  | TagReason
  | NfcTagReason;

type SearchGloballyErrorResponse = {
  success: false;
  status: string;
};

export type SearchByNfcTokenIdRequest = {
  nfcTokenId: string;
};

export type SearchByNfcTokenIdResponse =
  | SearchGloballySuccessResponse
  | SearchGloballyErrorResponse;
