import { AngleHelper } from './AngleHelper';

export class TransformHelper {
  public static getRotationFromTransformMatrix(matrix: string): number | null {
    const values = this.getMatrixElements(matrix);
    if (!values) {
      return null;
    }

    return AngleHelper.radToDeg(Math.atan2(values.b, values.a));
  }

  public static getScaleFromTransformMatrix(matrix: string): Scale | null {
    const values = this.getMatrixElements(matrix);
    if (!values) return null;

    const transformParameters = this.decomposeMatrix(values);

    return {
      scaleX: transformParameters.scaleX,
      scaleY: transformParameters.scaleY
    };
  }

  private static getMatrixElements(matrix: string): MatrixElements | null {
    const values = this.parseMatrixString(matrix);
    if (!values) return null;

    return {
      a: parseFloat(values[1]),
      b: parseFloat(values[2]),
      c: parseFloat(values[3]),
      d: parseFloat(values[4]),
      tx: parseFloat(values[5]),
      ty: parseFloat(values[6])
    };
  }

  private static parseMatrixString(
    matrix: string
  ):
    | (RegExpExecArray &
        [string, string, string, string, string, string, string])
    | null {
    const parsedMatrix =
      /matrix\(([^,]*), ([^,]*), ([^,]*), ([^,]*), ([^,]*), ([^,]*)\)/.exec(
        matrix
      );

    return Array.isArray(parsedMatrix) && parsedMatrix.length === 7
      ? (parsedMatrix as RegExpExecArray &
          [string, string, string, string, string, string, string])
      : null;
  }

  /**
   * @see https://gist.github.com/2052247
   */
  private static decomposeMatrix(matrix: MatrixElements): TransformParameters {
    // calculate delta transform point
    const px = this.deltaTransformPoint(matrix, { x: 0, y: 1 });
    const py = this.deltaTransformPoint(matrix, { x: 1, y: 0 });

    // calculate skew
    const skewX = (180 / Math.PI) * Math.atan2(px.y, px.x) - 90;
    const skewY = (180 / Math.PI) * Math.atan2(py.y, py.x);

    return {
      translateX: matrix.tx,
      translateY: matrix.ty,
      scaleX: Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b),
      scaleY: Math.sqrt(matrix.c * matrix.c + matrix.d * matrix.d),
      skewX: skewX,
      skewY: skewY
    };
  }

  private static deltaTransformPoint(
    matrix: MatrixElements,
    point: Point
  ): Point {
    const dx = point.x * matrix.a + point.y * matrix.c + 0;
    const dy = point.x * matrix.b + point.y * matrix.d + 0;
    return { x: dx, y: dy };
  }
}

export type MatrixElements = {
  a: number;
  b: number;
  c: number;
  d: number;
  tx: number;
  ty: number;
};

export type Scale = {
  scaleX: number;
  scaleY: number;
};

export type Skew = {
  skewX: number;
  skewY: number;
};

export type Translate = {
  translateX: number;
  translateY: number;
};

export type Point = {
  x: number;
  y: number;
};

export type TransformParameters = Scale & Skew & Translate;
