import { autoinject, bindable } from 'aurelia-framework';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Report } from '../../classes/EntityManager/entities/Report/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class ChecklistProjectReportList {
  @bindable public thing: Thing | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  @computed(expression('thing.id'), model(EntityName.Project))
  protected get projects(): Array<Project> {
    if (!this.thing) return [];
    return this.entityManager.projectRepository.getByThingId(this.thing.id);
  }

  @computed(expression('projects'), model(EntityName.Report))
  protected get reports(): Array<Report> {
    return this.entityManager.reportRepository.getByProjectIds(
      this.projects.map((p) => p.id)
    );
  }
}
