import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { ProjectSubEntityDto } from 'common/Types/BaseEntities/ProjectSubEntityUtils';
import { DefectSubEntity } from 'common/Types/Entities/Base/DefectSubEntity';
import { ProcessTaskGroupSubEntity } from 'common/Types/Entities/Base/ProcessTaskGroupSubEntity';
import { ProcessTaskSubEntity } from 'common/Types/Entities/Base/ProcessTaskSubEntity';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../../../classes/EntityManager/entities/Person/types';
import { User } from '../../../../classes/EntityManager/entities/User/types';

export class EntityAdapterUtils {
  private constructor() {}

  public static checkProcessTaskSubEntityPermission({
    entity,
    roleBasedPermissions,
    processTaskIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entity: ProcessTaskSubEntity<string>;
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return this.checkPermissionBasedOnProcessTaskId({
      processTaskId: entity.ownerProcessTaskId,
      ownerUserGroupId: entity.ownerUserGroupId,
      processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions,
      checkRolePermission
    });
  }

  public static checkPermissionBasedOnProcessTaskId({
    processTaskId,
    ownerUserGroupId,
    roleBasedPermissions,
    processTaskIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    processTaskId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !processTaskIdsWhereUserIsAuthorized ||
      !processTaskIdsWhereUserIsAuthorized.has(processTaskId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkProcessTaskGroupSubEntityPermission({
    entity,
    roleBasedPermissions,
    processTaskGroupIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entity: ProcessTaskGroupSubEntity<string> & { ownerProcessTaskId?: never };
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskGroupIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return this.checkPermissionBasedOnProcessTaskGroupId({
      processTaskGroupId: entity.ownerProcessTaskGroupId,
      ownerUserGroupId: entity.ownerUserGroupId,
      processTaskGroupIdsWhereUserIsAuthorized,
      roleBasedPermissions,
      checkRolePermission
    });
  }

  public static checkPermissionBasedOnProcessTaskGroupId({
    processTaskGroupId,
    ownerUserGroupId,
    roleBasedPermissions,
    processTaskGroupIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    processTaskGroupId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskGroupIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !processTaskGroupIdsWhereUserIsAuthorized ||
      !processTaskGroupIdsWhereUserIsAuthorized.has(processTaskGroupId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnThingId({
    thingId,
    ownerUserGroupId,
    roleBasedPermissions,
    thingIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    thingId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    thingIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !thingIdsWhereUserIsAuthorized ||
      !thingIdsWhereUserIsAuthorized.has(thingId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnThingGroupId({
    thingGroupId,
    ownerUserGroupId,
    roleBasedPermissions,
    thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing,
    checkRolePermission
  }: {
    thingGroupId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing ||
      !thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing.has(thingGroupId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkProjectSubEntityPermission({
    entity,
    roleBasedPermissions,
    projectIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entity: ProjectSubEntityDto;
    roleBasedPermissions: RoleBasedPermissions | null;
    projectIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !projectIdsWhereUserIsAuthorized ||
      !projectIdsWhereUserIsAuthorized.has(entity.ownerProjectId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(entity.ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnProjectId({
    projectId,
    ownerUserGroupId,
    roleBasedPermissions,
    projectIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    projectId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    projectIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !projectIdsWhereUserIsAuthorized ||
      !projectIdsWhereUserIsAuthorized.has(projectId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnPerson({
    person,
    roleBasedPermissions,
    groupAdminOnlyPersonCategoryNames,
    groupAdminUserGroupIds,
    personIdToThingIds,
    thingIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    person: Person;
    roleBasedPermissions: RoleBasedPermissions | null;
    groupAdminOnlyPersonCategoryNames: Set<string> | null;
    groupAdminUserGroupIds: Set<string> | null;
    personIdToThingIds: Map<string, Array<string>> | null;
    thingIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !groupAdminOnlyPersonCategoryNames ||
      !groupAdminUserGroupIds ||
      !personIdToThingIds ||
      !thingIdsWhereUserIsAuthorized
    ) {
      return false;
    }

    if (
      person.categoryName &&
      groupAdminOnlyPersonCategoryNames.has(person.categoryName) &&
      !groupAdminUserGroupIds.has(person.ownerUserGroupId)
    ) {
      return false;
    }

    const groupPermissions = roleBasedPermissions.inUserGroupId(
      person.ownerUserGroupId
    );

    if (
      groupPermissions.getControlEntityVisibilityWithAuthorizations() &&
      !groupPermissions.getCanSeePersonsWithoutExplicitAuthorization()
    ) {
      const thingIds = personIdToThingIds.get(person.id) ?? [];
      const isConnectedToThingWhereUserIsAuthorized = thingIds.some(
        (thingId) => {
          return thingIdsWhereUserIsAuthorized.has(thingId);
        }
      );

      if (!isConnectedToThingWhereUserIsAuthorized) {
        return false;
      }
    }

    return checkRolePermission(groupPermissions);
  }

  public static checkDefectSubEntityPermission({
    entityManager,
    entity,
    currentUser,
    roleBasedPermissions,
    thingIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entityManager: AppEntityManager;
    entity: DefectSubEntity<string>;
    currentUser: User | null;
    roleBasedPermissions: RoleBasedPermissions | null;
    thingIdsWhereUserIsAuthorized: Set<string> | null;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!roleBasedPermissions || !currentUser) {
      return false;
    }

    const defect = entityManager.defectRepository.getById(entity.ownerDefectId);
    if (!defect) {
      return false;
    }

    if (defect.assigneeId === currentUser.id) {
      return true;
    }

    return this.checkPermissionBasedOnThingId({
      thingId: defect.ownerThingId,
      ownerUserGroupId: entity.ownerUserGroupId,
      roleBasedPermissions,
      thingIdsWhereUserIsAuthorized,
      checkRolePermission
    });
  }
}
