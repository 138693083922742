import L from 'leaflet';

import { autoinject } from 'aurelia-framework';
import { DialogIconType } from 'common/Enums/DialogIconType';
import { Vector } from 'common/Geometry/Vector';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import {
  PositionAndSizeChangedEvent,
  RepositionableElement
} from '../../aureliaComponents/repositionable-element/repositionable-element';
import { Picture } from '../../picture/picture/picture';
import { CirclePreloader } from '../../aureliaComponents/circle-preloader/circle-preloader';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import {
  IPictureLocationInfo,
  IPictureLocationInfoPosition
} from 'common/Types/Entities/Picture/PictureDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture as PictureEntity } from '../../classes/EntityManager/entities/Picture/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { configureHooks } from '../../hooks/configureHooks';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { BaseMapBoundsChangedEvent } from './picture-map-positioning-gps-mode/picture-map-positioning-gps-mode';
/**
 * this dialog is meant to be a single global instance!
 *
 * a dialog which provides the functionality to precisely position a picture on the map.
 * it doesn't need
 * this is useful if you e.g. want to position a plan correctly on the map
 */
@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class PictureMapPositioningDialog {
  @subscribableLifecycle()
  protected readonly picturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  protected thing: Thing | null = null;

  private dialog: RecordItDialog | null = null;
  protected isOpen = false;

  protected boundPictureLocationInfoPositionToVector =
    this.pictureLocationInfoPositionToVector.bind(this);

  private readonly navigationModeChoices: [
    PictureMapPositioningDialogNavigationMode,
    PictureMapPositioningDialogNavigationMode,
    PictureMapPositioningDialogNavigationMode
  ] = [
    {
      labelTk: 'dialogs.pictureMapPositioningDialog.labelMap',
      name: 'map'
    },
    {
      labelTk: 'dialogs.pictureMapPositioningDialog.labelPicture',
      name: 'picture'
    },
    {
      labelTk: 'dialogs.pictureMapPositioningDialog.labelGps',
      name: 'gps'
    }
  ];

  private repositionableElementInfo: RepositionableElementInfo = {
    height: 100,
    width: 100,
    ratio: 0,
    top: 0,
    left: 0,
    rotation: 0
  };

  private selectedNavigationMode: PictureMapPositioningDialogNavigationMode =
    this.navigationModeChoices[0];

  private basemapMap: BasemapMap | null = null;

  private repositionablePicture: RepositionableElement | null = null;
  private pictureViewModel: Picture | null = null;
  private mapCirclePreloader: CirclePreloader | null = null;

  private picture: PictureEntity | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.picturePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'picture'
      });
  }

  public static async open(
    thing: Thing,
    picture: PictureEntity
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(thing, picture);
  }

  public open(thing: Thing, picture: PictureEntity): void {
    this.mapCirclePreloader?.start();
    this.resetRepositionableElementInfo();

    this.thing = thing;
    this.picture = picture;

    if (picture.location_info && picture.location_info.topLeftPosition) {
      this.selectedNavigationMode = this.navigationModeChoices[1];
    } else {
      this.selectedNavigationMode = this.navigationModeChoices[0];
    }

    this.handleSelectedNavigationModeChanged();
    this.resetPicturePositionOnMap();

    this.dialog?.open();
  }

  public close(): void {
    this.dialog?.close();
  }

  protected handleDialogOpened(): void {
    this.isOpen = true;
  }

  protected handleDialogClosed(): void {
    this.isOpen = false;
    this.mapCirclePreloader?.reset();
    this.picture = null;
    this.thing = null;
  }

  protected handleCancelClick(): void {
    this.close();
  }

  protected async handleAcceptClick(): Promise<void> {
    if (
      this.picture?.location_info &&
      this.picture.location_info.topLeftPosition
    ) {
      const baseKey =
        'dialogs.pictureMapPositioningDialog.picturePositionedWarningDialog.';
      await GlobalCustomDialog.open({
        titleTk: baseKey + 'title',
        textTk: baseKey + 'text',
        buttons: [
          {
            closeDialog: true,
            textTk: baseKey + 'okButton'
          },
          {
            closeDialog: true,
            type: ButtonType.CANCEL,
            textTk: 'general.cancel',
            className: 'record-it-button-orange'
          }
        ],
        icon: DialogIconType.WARNING
      });
    }

    this.acceptCoordinates();
  }

  protected handleMapInitialized(): void {
    this.handleSelectedNavigationModeChanged();
    this.resetPicturePositionOnMap();
  }

  protected handleBaseMapBoundsChanged(
    $event: BaseMapBoundsChangedEvent
  ): void {
    this.basemapMap?.getMapInstance()?.fitBounds($event.detail.bounds, {
      animate: false,
      duration: 0
    });
  }

  protected handlePositionAndSizeChanged(
    $event: PositionAndSizeChangedEvent
  ): void {
    this.repositionableElementInfo.left = $event.detail.positionAndSize.left;
    this.repositionableElementInfo.top = $event.detail.positionAndSize.top;
    this.repositionableElementInfo.width = $event.detail.positionAndSize.width;
    this.repositionableElementInfo.height =
      $event.detail.positionAndSize.height;
    this.repositionableElementInfo.rotation =
      $event.detail.positionAndSize.rotation;
  }

  protected getTabClassNameForMode(
    navigationMode: PictureMapPositioningDialogNavigationMode
  ): string {
    return navigationMode
      ? `picture-map-positioning-dialog--${StringUtils.upperCaseFirstLetter(
          navigationMode.name
        )}Mode`
      : '';
  }

  protected handlePictureLoaded(): void {
    this.mapCirclePreloader?.stop();

    if (
      this.picture?.location_info &&
      this.picture.location_info.topLeftPosition
    ) {
      return; // no need to set any values, since the picture is already at the right position
    }

    const dimensions = this.pictureViewModel?.getNaturalDimensions();
    assertNotNullOrUndefined(dimensions, 'no dimensions available');

    const ratio = dimensions.width / dimensions.height;
    const baseWidth = 200;
    const height = baseWidth / ratio;

    this.repositionableElementInfo.height = height;
    this.repositionableElementInfo.width = baseWidth;
    this.repositionableElementInfo.ratio = ratio;
    this.repositionableElementInfo.rotation = 0;
    this.repositionableElementInfo.top = 0;
    this.repositionableElementInfo.left = 0;
  }

  private acceptCoordinates(): void {
    if (!this.picture) return;

    this.mapCirclePreloader?.start();

    const locationInfo = this.calculatePictureLocationInfo();
    this.picture.location_info = locationInfo;

    this.entityManager.pictureRepository.update(this.picture);

    setTimeout(() => {
      this.mapCirclePreloader?.stop();
      this.close();
    }, 1000);
  }

  private handleSelectedNavigationModeChanged(): void {
    if (!this.basemapMap || !this.basemapMap.getMapInstance()) {
      return; // not initialized yet
    }

    switch (this.selectedNavigationMode.name) {
      case 'picture':
        this.basemapMap.disableUserInteraction();
        this.repositionablePicture?.setActive(true);
        break;

      case 'map':
        this.basemapMap.enableUserInteraction();
        this.repositionablePicture?.setActive(false);
        break;

      case 'gps':
        this.basemapMap.disableUserInteraction();
        this.repositionablePicture?.setActive(false);
        break;

      default:
        throw new Error('never gonna happen');
    }
  }

  private calculatePictureLocationInfo(): IPictureLocationInfo {
    const info = this.repositionableElementInfo;

    const widthVector = Vector.createHtmlVector(info.width, 0).rotate(
      info.rotation
    );
    const heightVector = Vector.createHtmlVector(0, info.height).rotate(
      info.rotation
    );

    const topLeftPoint = Vector.createHtmlVector(info.left, info.top);
    const topRightPoint = topLeftPoint.clone().addVector(widthVector);
    const bottomRightPoint = topRightPoint.clone().addVector(heightVector);
    const bottomLeftPoint = topLeftPoint.clone().addVector(heightVector);

    return {
      topLeftPosition: this.vectorToPictureLocationInfoPosition(topLeftPoint),
      topRightPosition: this.vectorToPictureLocationInfoPosition(topRightPoint),
      bottomRightPosition:
        this.vectorToPictureLocationInfoPosition(bottomRightPoint),
      bottomLeftPosition:
        this.vectorToPictureLocationInfoPosition(bottomLeftPoint)
    };
  }

  private vectorToPictureLocationInfoPosition(
    vector: Vector
  ): IPictureLocationInfoPosition {
    const map = this.basemapMap?.getMapInstance();
    assertNotNullOrUndefined(map, 'basemap map should exist');

    const latLng = map.containerPointToLatLng(
      new L.Point(vector.getX(), vector.getY())
    );
    return {
      latitude: latLng.lat,
      longitude: latLng.lng
    };
  }

  /**
   * does nothing if the basemapMap is not initialized yet!
   */
  private resetPicturePositionOnMap(): void {
    const map = this.basemapMap?.getMapInstance();
    const info = this.picture?.location_info;

    if (map && info && info.topLeftPosition) {
      this.setMapToPictureLocationInfo(info);
      this.setRepositionableElementToPictureLocationInfo(info);
    }
  }

  private setMapToPictureLocationInfo(info: IPictureLocationInfo): void {
    const topLeftLatLng = L.latLng(
      info.topLeftPosition.latitude,
      info.topLeftPosition.longitude
    );
    const topRightLatLng = L.latLng(
      info.topRightPosition.latitude,
      info.topRightPosition.longitude
    );
    const bottomRightLatLng = L.latLng(
      info.bottomRightPosition.latitude,
      info.bottomRightPosition.longitude
    );
    const bottomLeftLatLng = L.latLng(
      info.bottomLeftPosition.latitude,
      info.bottomLeftPosition.longitude
    );

    const bounds = L.latLngBounds(topLeftLatLng, topLeftLatLng);
    bounds
      .extend(topRightLatLng)
      .extend(bottomRightLatLng)
      .extend(bottomLeftLatLng);

    this.basemapMap?.getMapInstance()?.fitBounds(bounds, {
      animate: false,
      duration: 0
    });
  }

  private setRepositionableElementToPictureLocationInfo(
    info: IPictureLocationInfo
  ): void {
    const topLeftVector = this.pictureLocationInfoPositionToVector(
      info.topLeftPosition
    );
    const topRightVector = this.pictureLocationInfoPositionToVector(
      info.topRightPosition
    );
    const bottomLeftVector = this.pictureLocationInfoPositionToVector(
      info.bottomLeftPosition
    );

    this.repositionableElementInfo.top = topLeftVector.getY();
    this.repositionableElementInfo.left = topLeftVector.getX();
    this.repositionableElementInfo.width = topLeftVector
      .clone()
      .substractVector(topRightVector)
      .getLength();
    this.repositionableElementInfo.height = topLeftVector
      .clone()
      .substractVector(bottomLeftVector)
      .getLength();
    this.repositionableElementInfo.ratio =
      this.repositionableElementInfo.width /
      this.repositionableElementInfo.height;
    this.repositionableElementInfo.rotation =
      topRightVector.clone().substractVector(topLeftVector).getAngle() || 0;
  }

  private pictureLocationInfoPositionToVector(
    position: IPictureLocationInfoPosition
  ): Vector {
    const map = this.basemapMap?.getMapInstance();
    assertNotNullOrUndefined(map, 'basemap map should exist');

    const point = map.latLngToContainerPoint(
      new L.LatLng(position.latitude, position.longitude)
    );
    return Vector.createHtmlVector(point.x, point.y);
  }

  private resetRepositionableElementInfo(): void {
    this.repositionableElementInfo = {
      height: 0,
      width: 0,
      ratio: 0,
      top: 0,
      left: 0,
      rotation: 0
    };
  }
}

type PictureMapPositioningDialogNavigationMode = {
  labelTk: string;
  name: 'map' | 'picture' | 'gps'; // internal name
};

type RepositionableElementInfo = {
  height: number;
  width: number;
  ratio: number;
  top: number;
  left: number;
  rotation: number;
};
