import { EntityName } from '../../BaseEntities/EntityName';
import { NfcTokenToPersonDto } from '../NfcTokenToPerson/NfcTokenToPersonDto';
import { NfcTokenToThingDto } from '../NfcTokenToThing/NfcTokenToThingDto';

export class NfcTokenDtoUtils {
  private constructor() {}

  public static getMainEntityInfos({
    nfcTokenToPersonDtos,
    nfcTokenToThingDtos
  }: {
    nfcTokenToPersonDtos: Array<NfcTokenToPersonDto<string, string>>;
    nfcTokenToThingDtos: Array<NfcTokenToThingDto<string, string>>;
  }): Array<MainEntityInfo> {
    return [
      ...nfcTokenToPersonDtos.map<MainEntityInfo>((nfcTokenToPersonDto) => {
        return {
          mainEntityName: EntityName.Person,
          mainEntityId: nfcTokenToPersonDto.personId
        };
      }),
      ...nfcTokenToThingDtos.map<MainEntityInfo>((nfcTokenToThingDto) => {
        return {
          mainEntityName: EntityName.Thing,
          mainEntityId: nfcTokenToThingDto.thingId
        };
      })
    ];
  }
}

export type MainEntityInfo = {
  mainEntityId: string;
  mainEntityName: EntityName.Thing | EntityName.Person;
};
