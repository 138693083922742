import { autoinject, bindable } from 'aurelia-framework';
import { ProcessTaskGroupHelper } from 'common/Operations/ProcessTaskGroupHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskInvoiceDialog } from '../edit-process-task-invoice-dialog/edit-process-task-invoice-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { DefaultInspectorService } from '../../services/DefaultInspectorService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskInvoicesWidget {
  @bindable public processTask: ProcessTask | null = null;

  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  /**
   * null if invoices aren't loaded
   * read only
   */
  @bindable public processTaskInvoiceCount: number | null = null;

  @subscribableLifecycle()
  protected readonly processTaskGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  private defaultInspectorService: DefaultInspectorService;
  private subscriptionManager: SubscriptionManager;

  protected availableInvoices: Array<ProcessTaskInvoice> = [];
  private isAttached = false;
  private invoiceIdToNavigateTo: string | null = null;

  protected invoiceCategoryIdGetter: (
    invoice: ProcessTaskInvoice
  ) => string | null = (i) => i.processConfigurationCategoryId;

  protected positionCategories: Array<PositionCategoryGroup> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    defaultInspectorService: DefaultInspectorService,
    permissionService: PermissionsService
  ) {
    this.defaultInspectorService = defaultInspectorService;
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskGroupPermissionsHandle =
      permissionService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroup,
        context: this as ProcessTaskInvoicesWidget,
        propertyName: 'processTaskGroup'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoice,
      this.updateAvailableInvoices.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      this.updateAvailableInvoices.bind(this)
    );
    this.updateAvailableInvoices();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.updatePositions();

    if (this.invoiceIdToNavigateTo) {
      this.navigateToInvoice(this.invoiceIdToNavigateTo);
    }
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableInvoices();
      this.updatePositions();
    }
  }

  public navigateToInvoice(invoiceId: string): void {
    if (!this.isAttached) {
      this.invoiceIdToNavigateTo = invoiceId;
      return;
    }

    const invoice = this.availableInvoices.find((i) => i.id === invoiceId);

    if (!invoice) {
      throw new Error(`invoice with id "${invoiceId}" not found`);
    }

    this.goToInvoice(invoice);
    this.invoiceIdToNavigateTo = null;
  }

  private updateAvailableInvoices(): void {
    if (this.processTask) {
      const relations =
        this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskId(
          this.processTask.id
        );
      const invoiceIds = relations.map((r) => r.processTaskInvoiceId);
      const invoicesWithRelations =
        this.entityManager.processTaskInvoiceRepository.getByIds(invoiceIds);

      const invoices =
        this.entityManager.processTaskInvoiceRepository.getByProcessTaskGroupId(
          this.processTask.ownerProcessTaskGroupId
        );
      const invoiceWithoutRelations = invoices.filter((i) => {
        return (
          this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
            i.id
          ).length === 0
        );
      });

      this.availableInvoices = invoiceWithoutRelations.concat(
        invoicesWithRelations
      );
      this.processTaskInvoiceCount = this.availableInvoices.length;
    } else {
      this.availableInvoices = [];
      this.processTaskInvoiceCount = null;
    }
  }

  private updatePositions(): void {
    const positions = this.processTask
      ? this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        )
      : [];
    this.updateCategorizedPositions(positions);
  }

  private updateCategorizedPositions(
    positions: Array<ProcessTaskPosition>
  ): void {
    this.positionCategories =
      ProcessTaskPositionUtils.categorizePositions(positions);
  }

  protected getNumberOfPositionsInCategory(
    categoryId: string,
    positionCategories: Array<PositionCategoryGroup>
  ): number {
    const category = positionCategories.find(
      (cat) => cat.categoryId === categoryId
    );
    if (category) {
      return category.positions.length;
    }
    return 0;
  }

  protected async handleAddInvoiceClick(
    categoryId: string | null
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskInvoicesWidget.handleAddInvoiceClick without processTask"
    );
    assertNotNullOrUndefined(
      this.processTaskGroup,
      "can't ProcessTaskInvoicesWidget.handleAddInvoiceClick without processTaskGroup"
    );

    const defaultInspectorUserId =
      await this.defaultInspectorService.getDefaultInspectorUserId(
        this.processTask
      );
    const defaultInvoiceReceiverPersonId =
      ProcessTaskGroupHelper.getInvoiceReceiverPersonIdOrFallback(
        this.processTaskGroup
      );

    const invoice = this.entityManager.processTaskInvoiceRepository.create({
      processConfigurationCategoryId: categoryId,
      inspectorUserId: defaultInspectorUserId,
      invoiceReceiverPersonId: defaultInvoiceReceiverPersonId,
      ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
      ownerUserGroupId: this.processTask.ownerUserGroupId,
      temporaryGroupName: this.processTask.temporaryGroupName
    });

    this.entityManager.processTaskInvoiceToProcessTaskRepository.create({
      ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: this.processTask.id,
      ownerUserGroupId: this.processTask.ownerUserGroupId,
      processTaskInvoiceId: invoice.id
    });

    this.editInvoice(invoice);
  }

  protected handleEditButtonClicked(invoice: ProcessTaskInvoice): void {
    this.editInvoice(invoice);
  }

  private editInvoice(invoice: ProcessTaskInvoice): void {
    void EditProcessTaskInvoiceDialog.open({
      processTaskInvoice: invoice,
      onDialogClosed: (closedByNavigation) => {
        if (!closedByNavigation) {
          this.goToInvoice(invoice);
        }
      }
    });
  }

  private goToInvoice(invoice: ProcessTaskInvoice): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getInvoiceElementId(invoice.id),
      null,
      invoice,
      () => this.isAttached
    );
  }

  protected getInvoiceElementId(invoiceId: string): string {
    return 'process-task-invoices-widget--invoice-' + invoiceId;
  }
}
