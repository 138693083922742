import {
  UserRoleDto,
  userRolePermissionFieldNames,
  UserRolePermissionFields
} from '../../Types/Entities/UserRole/UserRoleDto';
import { UserRoleDtoUtils } from '../../Types/Entities/UserRole/UserRoleDtoUtils';

export class UserRoleCombiner {
  private constructor() {}

  public static combineUserRoles(
    userRoles: Array<UserRoleDto<string, string>>
  ): CombinedUserRole {
    const combinedUserRole: Record<string, boolean> = {};

    for (const fieldName of userRolePermissionFieldNames) {
      combinedUserRole[fieldName] = userRoles.some(
        (userRole) => userRole[fieldName]
      );
    }

    return combinedUserRole as CombinedUserRole;
  }

  /**
   * this creates a combinedUserRole where everything is false
   */
  public static createEmptyCombinedUserRole(): CombinedUserRole {
    return UserRoleDtoUtils.createPermissionFieldsWithNoPermissions();
  }
}

export type CombinedUserRole = UserRolePermissionFields;
