import {
  userRolePermissionFieldNames,
  UserRolePermissionFields
} from './UserRoleDto';

export class UserRoleDtoUtils {
  private constructor() {}

  public static createPermissionFieldsWithNoPermissions(): UserRolePermissionFields {
    return this.createPermissionFields({ value: false });
  }

  public static createPermissionFieldsWithAllPermissions(): UserRolePermissionFields {
    return this.createPermissionFields({ value: true });
  }

  private static createPermissionFields({
    value
  }: {
    value: boolean;
  }): UserRolePermissionFields {
    const baseData: Partial<UserRolePermissionFields> = {};

    for (const fieldName of userRolePermissionFieldNames) {
      baseData[fieldName] = value;
    }

    return baseData as UserRolePermissionFields;
  }
}
