import L from 'leaflet';

import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import { Vector } from 'common/Geometry/Vector';
import {
  IPictureLocationInfoPosition,
  IPictureMarking
} from 'common/Types/Entities/Picture/PictureDto';

import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';
import {
  RepositionableElementPositionAndSize,
  SetPositionAndSizeOptions
} from '../../../aureliaComponents/repositionable-element/repositionable-element';
import { Picture } from '../../../classes/EntityManager/entities/Picture/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import {
  MarkingAddedEvent,
  MarkingRemovedEvent
} from '../../../picture/picture-markable-by-click/picture-markable-by-click';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { GalleryThingPictureOverviewEntry } from '../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';

/**
 * @event {PositionAndSizeChangedEvent} position-and-size-changed
 * @event {BaseMapBoundsChangedEvent} base-map-bounds-changed
 */

@autoinject
export class PictureMapPositioningGpsMode {
  @bindable public thing: Thing | null = null;
  @bindable public picture: Picture | null = null;

  @bindable
  public readonly currentPositionAndSize: RepositionableElementPositionAndSize | null =
    null;

  @bindable
  public firstSelectedPicture: GalleryThingPictureOverviewEntry | null = null;

  @bindable
  public secondSelectedPicture: GalleryThingPictureOverviewEntry | null = null;

  @bindable public pictureLocationInfoPositionToVector:
    | ((position: IPictureLocationInfoPosition) => Vector)
    | null = null;

  private coordinateOneLatitude: number | null = null;
  private coordinateOneLongitude: number | null = null;
  private coordinateTwoLatitude: number | null = null;
  private coordinateTwoLongitude: number | null = null;
  private pictureReferencePointOne: IPictureMarking | null = null;
  private pictureReferencePointTwo: IPictureMarking | null = null;

  private readonly element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected firstSelectedPictureChanged(): void {
    [this.coordinateOneLatitude, this.coordinateOneLongitude] =
      this.getCoordinatesFromSelectedPicture(this.firstSelectedPicture);
  }

  protected secondSelectedPictureChanged(): void {
    [this.coordinateTwoLatitude, this.coordinateTwoLongitude] =
      this.getCoordinatesFromSelectedPicture(this.secondSelectedPicture);
  }

  protected pictureMarkingsAdded(event: MarkingAddedEvent): void {
    if (!this.pictureReferencePointOne)
      this.pictureReferencePointOne = event.detail.marking;
    else this.pictureReferencePointTwo = event.detail.marking;
  }

  protected pictureMarkingsRemoved(event: MarkingRemovedEvent): void {
    if (this.pictureReferencePointOne === event.detail.marking)
      this.pictureReferencePointOne = null;
    else this.pictureReferencePointTwo = null;
  }

  @computed(
    expression('coordinateOneLatitude'),
    expression('coordinateOneLongitude'),
    expression('coordinateTwoLatitude'),
    expression('coordinateTwoLongitude'),
    expression('pictureReferencePointOne'),
    expression('pictureReferencePointTwo')
  )
  protected get allValuesForGpsLocationSet(): boolean {
    return (
      (this.pictureReferencePointOne &&
        this.pictureReferencePointTwo &&
        this.coordinateOneLatitude !== null &&
        this.coordinateOneLongitude !== null &&
        this.coordinateTwoLatitude !== null &&
        this.coordinateTwoLongitude !== null) ||
      false
    );
  }

  protected handleRepositionPictureClicked(): void {
    if (
      !this.pictureReferencePointOne ||
      !this.pictureReferencePointTwo ||
      this.coordinateOneLatitude === null ||
      this.coordinateOneLongitude === null ||
      this.coordinateTwoLatitude === null ||
      this.coordinateTwoLongitude === null
    ) {
      throw new Error(
        'Cannot reposition picture without reference points and coordinates.'
      );
    }

    assertNotNullOrUndefined(
      this.currentPositionAndSize,
      'cannot compute scale for repositionable element without currentPositionAndSize'
    );

    const firstReferenceCoordinate = new Vector(
      this.coordinateOneLatitude,
      this.coordinateOneLongitude
    );
    const secondReferenceCoordinate = new Vector(
      this.coordinateTwoLatitude,
      this.coordinateTwoLongitude
    );

    this.positionMapForPreview(
      firstReferenceCoordinate,
      secondReferenceCoordinate
    );

    assertNotNullOrUndefined(
      this.pictureLocationInfoPositionToVector,
      'cannot reposition picture without pictureLocationInfoPositionToVector'
    );

    const firstReferenceCoordinateInPixel =
      this.pictureLocationInfoPositionToVector({
        latitude: firstReferenceCoordinate.getX(),
        longitude: firstReferenceCoordinate.getY()
      });
    const secondReferenceCoordinateInPixel =
      this.pictureLocationInfoPositionToVector({
        latitude: secondReferenceCoordinate.getX(),
        longitude: secondReferenceCoordinate.getY()
      });

    const firstReferencePointInPixel =
      this.htmlVectorReferencePointInPixelFromMarking(
        this.pictureReferencePointOne
      );
    const secondReferencePointInPixel =
      this.htmlVectorReferencePointInPixelFromMarking(
        this.pictureReferencePointTwo
      );

    const extents = this.computeRepositionablePictureExtents({
      firstReferencePointInPixel,
      secondReferencePointInPixel,
      firstReferenceCoordinateInPixel,
      secondReferenceCoordinateInPixel,
      currentPositionAndSize: this.currentPositionAndSize
    });

    const rotation = this.computeRepositionablePictureRotation({
      firstReferencePointInPixel,
      secondReferencePointInPixel,
      firstReferenceCoordinateInPixel,
      secondReferenceCoordinateInPixel
    });

    const translation = this.computeRepositionablePictureTranslation({
      firstReferencePointInPixel,
      firstReferenceCoordinateInPixel,
      angle: rotation.rotation ?? 0
    });

    this.firePositionAndSizeChangedEvent({
      top: translation.top ?? 0,
      left: translation.left ?? 0,
      rotation: rotation.rotation ?? 0,
      width: extents.width ?? this.currentPositionAndSize.width,
      height: extents.height ?? this.currentPositionAndSize.height
    });
  }

  private positionMapForPreview(
    firstCoordinatesReference: Vector,
    secondCoordinatesReference: Vector
  ): void {
    const coordinatesDistance = secondCoordinatesReference
      .clone()
      .substractVector(firstCoordinatesReference);
    const coordinatesCenter = firstCoordinatesReference
      .clone()
      .addVector(coordinatesDistance.scale(0.5));

    const topLeftLatLng = L.latLng(
      coordinatesCenter.getX() - coordinatesDistance.getX() * 3,
      coordinatesCenter.getY() - coordinatesDistance.getY() * 3
    );
    const bottomRightLatLng = L.latLng(
      coordinatesCenter.getX() + coordinatesDistance.getX() * 3,
      coordinatesCenter.getY() + coordinatesDistance.getY() * 3
    );

    const bounds = L.latLngBounds(topLeftLatLng, topLeftLatLng);
    bounds.extend(bottomRightLatLng);

    this.fireBaseMapBoundsChangedEvent(bounds);
  }

  private computeRepositionablePictureExtents({
    firstReferencePointInPixel,
    secondReferencePointInPixel,
    firstReferenceCoordinateInPixel,
    secondReferenceCoordinateInPixel,
    currentPositionAndSize
  }: {
    firstReferencePointInPixel: Vector;
    secondReferencePointInPixel: Vector;
    firstReferenceCoordinateInPixel: Vector;
    secondReferenceCoordinateInPixel: Vector;
    currentPositionAndSize: RepositionableElementPositionAndSize;
  }): SetPositionAndSizeOptions {
    const referencePointDistance = secondReferencePointInPixel
      .clone()
      .substractVector(firstReferencePointInPixel)
      .getLength();
    const coordinatePointDistance = secondReferenceCoordinateInPixel
      .clone()
      .substractVector(firstReferenceCoordinateInPixel)
      .getLength();

    firstReferencePointInPixel
      .scale(1 / referencePointDistance)
      .scale(coordinatePointDistance);
    secondReferencePointInPixel
      .scale(1 / referencePointDistance)
      .scale(coordinatePointDistance);

    return {
      width:
        (currentPositionAndSize.width / referencePointDistance) *
        coordinatePointDistance,
      height:
        (currentPositionAndSize.height / referencePointDistance) *
        coordinatePointDistance
    };
  }

  private computeRepositionablePictureRotation({
    firstReferencePointInPixel,
    secondReferencePointInPixel,
    firstReferenceCoordinateInPixel,
    secondReferenceCoordinateInPixel
  }: {
    firstReferencePointInPixel: Vector;
    secondReferencePointInPixel: Vector;
    firstReferenceCoordinateInPixel: Vector;
    secondReferenceCoordinateInPixel: Vector;
  }): SetPositionAndSizeOptions {
    const referenceVector = secondReferencePointInPixel
      .clone()
      .substractVector(firstReferencePointInPixel);

    const coordinatesVector = secondReferenceCoordinateInPixel
      .clone()
      .substractVector(firstReferenceCoordinateInPixel);

    const coordsAngle = Vector.createHtmlVector(
      coordinatesVector.getX(),
      coordinatesVector.getY()
    ).getAngle();

    const refAngle = referenceVector.getAngle();
    const angle = coordsAngle && refAngle ? coordsAngle - refAngle : 0;

    return { rotation: angle };
  }

  private computeRepositionablePictureTranslation({
    firstReferencePointInPixel,
    firstReferenceCoordinateInPixel,
    angle
  }: {
    firstReferencePointInPixel: Vector;
    firstReferenceCoordinateInPixel: Vector;
    angle: number;
  }): SetPositionAndSizeOptions {
    const finalCornerPosition = firstReferenceCoordinateInPixel.substractVector(
      firstReferencePointInPixel.rotate(angle)
    );

    return {
      top: finalCornerPosition.getY(),
      left: finalCornerPosition.getX()
    };
  }

  private htmlVectorReferencePointInPixelFromMarking(
    marking: IPictureMarking
  ): Vector {
    assertNotNullOrUndefined(
      this.currentPositionAndSize,
      'cannot compute pictureSize without currentPositionAndSize'
    );

    const pictureSize = new Vector(
      this.currentPositionAndSize.width,
      this.currentPositionAndSize.height
    );
    return Vector.createHtmlVector(
      parseFloat(marking.left),
      parseFloat(marking.top)
    )
      .scale(0.01)
      .multiplyVector(pictureSize);
  }

  private fireBaseMapBoundsChangedEvent(bounds: L.LatLngBounds): void {
    DomEventHelper.fireEvent<BaseMapBoundsChangedEvent>(this.element, {
      name: 'base-map-bounds-changed',
      detail: { bounds }
    });
  }

  private firePositionAndSizeChangedEvent(
    positionAndSize: RepositionableElementPositionAndSize
  ): void {
    DomEventHelper.fireEvent<PositionAndSizeChangedEvent>(this.element, {
      name: 'position-and-size-changed',
      detail: { positionAndSize }
    });
  }

  private getCoordinatesFromSelectedPicture(
    picture: GalleryThingPictureOverviewEntry | null
  ): [number | null, number | null] {
    if (!picture || !picture.coords) {
      return [null, null];
    }

    return [picture.coords.latitude, picture.coords.longitude];
  }
}

export type BaseMapBoundsChangedEvent = NamedCustomEvent<
  'base-map-bounds-changed',
  {
    bounds: L.LatLngBounds;
  }
>;

export type PositionAndSizeChangedEvent = NamedCustomEvent<
  'position-and-size-changed',
  {
    positionAndSize: RepositionableElementPositionAndSize;
  }
>;
