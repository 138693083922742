import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { SocketService } from '../SocketService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';

/**
 * This class has a little bit of magic inside.
 * This only works if the an actualization will be triggered (through the AutoActualizationService) as soon as possible after authentication happened.
 * If no actualization will be triggered, then the GlobalLoadingOverlay will be stuck for ever (or until the next disconnect).
 * Sadly I couldn't find a better way to link everything together (all JoinedXYManagers + AutoActualizationService) without having to restructure everything.
 * So at least this behavior is documented now.
 */
@autoinject()
export class ActualizationOverlayService {
  private readonly subscriptionManager: SubscriptionManager;

  private readonly entityNameToRelativeEntityChanges = new Map<
    EntityName,
    Map<string, { removed: boolean; added: boolean }>
  >();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public init(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding(
        'isAuthenticated',
        (isAuthenticated) => {
          if (isAuthenticated && this.shouldShowGlobalLoadingOverlay()) {
            GlobalLoadingOverlay.setLoadingState(this, true);
          } else {
            GlobalLoadingOverlay.setLoadingState(this, false);
          }
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          if (this.shouldShowGlobalLoadingOverlay() && isActualizing) {
            GlobalLoadingOverlay.setLoadingState(this, true);
          } else {
            GlobalLoadingOverlay.setLoadingState(this, false);
          }
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerHooks({
        relativeSynchronizationEntityIdAdded: ({ entityName, entityId }) => {
          this.relativeSynchronizationEntityAdded({ entityName, entityId });
        },
        relativeSynchronizationEntityIdRemoved: ({ entityName, entityId }) => {
          this.relativeSynchronizationEntityRemoved({ entityName, entityId });
        },
        afterActualization: ({ success }) => {
          if (success) {
            this.entityNameToRelativeEntityChanges.clear();
          }
        }
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private shouldShowGlobalLoadingOverlay(): boolean {
    const lastSuccessfulActualizationInfo =
      this.entityManager.entityActualization.getLastSuccessfulActualizationInfo();

    if (!lastSuccessfulActualizationInfo) {
      return true;
    }

    for (const [
      entityName,
      entityIdToChanges
    ] of this.entityNameToRelativeEntityChanges.entries()) {
      // since the ProcessTaskGroups and Defect are automatically added as relative entities, we don't need to show a loading overlay for them
      if (entityName !== EntityName.Project) {
        continue;
      }

      for (const [entityId, changes] of entityIdToChanges.entries()) {
        const wasIncludedInLastActualization =
          lastSuccessfulActualizationInfo.relativeSynchronizationEntityIdsByEntityName
            .get(entityName)
            ?.has(entityId) ?? false;

        // we show an overlay if an entity was removed but also added
        // this happens when a user makes an offline available project unavailable and then available again
        if (
          wasIncludedInLastActualization &&
          changes.removed &&
          changes.added
        ) {
          return true;
        }

        // There is a new relative entity. We want to show an actualization overlay for it as well
        if (!wasIncludedInLastActualization && changes.added) {
          return true;
        }
      }
    }

    return false;
  }

  private relativeSynchronizationEntityAdded({
    entityName,
    entityId
  }: {
    entityName: EntityName;
    entityId: string;
  }): void {
    let entityIdToChanges =
      this.entityNameToRelativeEntityChanges.get(entityName);

    if (!entityIdToChanges) {
      entityIdToChanges = new Map();
      this.entityNameToRelativeEntityChanges.set(entityName, entityIdToChanges);
    }

    entityIdToChanges.set(entityId, {
      removed: false,
      ...entityIdToChanges.get(entityId),
      added: true
    });
  }

  private relativeSynchronizationEntityRemoved({
    entityName,
    entityId
  }: {
    entityName: EntityName;
    entityId: string;
  }): void {
    let entityIdToChanges =
      this.entityNameToRelativeEntityChanges.get(entityName);

    if (!entityIdToChanges) {
      entityIdToChanges = new Map();
      this.entityNameToRelativeEntityChanges.set(entityName, entityIdToChanges);
    }

    entityIdToChanges.set(entityId, {
      added: false,
      removed: true
    });
  }
}
