import { autoinject, bindable } from 'aurelia-framework';

import { DefectStatus } from 'common/Enums/DefectStatus';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import {
  DefectManagementFilter,
  FilterChangedEvent
} from '../../galleryThing/gallery-thing-filter/gallery-thing-defect-filter/gallery-thing-defect-filter';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ExportStartedEvent } from '../../galleryThing/gallery-thing-filter/gallery-thing-picture-filter/gallery-thing-picture-filter';
import {
  DefectSortOption,
  DefectSortOptions,
  DefectUtils
} from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';
import { DefectFilterHandle } from '../../services/DefectFilterService/DefectFilterHandle';
import { DefectFilterService } from '../../services/DefectFilterService/DefectFilterService';

/**
 * Displays defects in a list.
 *
 * @event export-started {ExportStartedEvent} - triggered when the user starts an export from the export panel.
 */
@autoinject()
export class DefectOverview {
  @bindable public thing: Thing | null = null;

  @bindable public editable = false;

  /**
   * Allows only certain defects to be displayed.
   */
  protected defectFilter: DefectManagementFilter | null = null;

  /**
   * Available defects.
   */
  protected defects: Array<Defect> = [];

  /**
   * Defects filtered according to the `defectFilter`.
   */
  protected filteredDefects: Array<Defect> = [];

  protected sortedDefects: Array<Defect> = [];

  /**
   * Defects limited by the pagination.
   */
  protected currentPageDefects: Array<Defect> = [];

  /**
   * The ref of the list element.
   *
   * Needed to retrieve the children of, so the widget overlay knows
   * which of them it should take the place of.
   */
  protected defectListRef: HTMLElement | null = null;

  /**
   * Whether the detailed view is opened.
   */
  protected isDetailsWidgetOpen = false;

  /**
   * The defect entity and its corresponding html defect-list-item
   */
  protected openedDefectInfo: Readonly<{
    defect: Defect | null;
    listItemElement: HTMLElement | null;
  }> | null = null;

  /**
   * Controls whether the defect filter is visible.
   */
  protected isFilterVisible = true;

  private subscriptionManager: SubscriptionManager;

  private element: HTMLElement;

  private readonly sortOptions: DefectSortOptions;
  private currentSortOption: DefectSortOption;

  // private updateFilteredDefectsRateLimited = Utils.rateLimitFunction(this.updateFilteredDefects.bind(this), 250);

  private readonly filterHandle: DefectFilterHandle;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subManagerService: SubscriptionManagerService,
    private readonly defectCreationService: DefectCreationService,
    defectFilterService: DefectFilterService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subManagerService.create();
    this.sortOptions = DefectUtils.createSortOptions({ entityManager });
    this.currentSortOption = this.sortOptions.sequenceNumber;
    this.filterHandle = defectFilterService.createHandle();
  }

  public openDetailsViewForDefectId(defectId: string): void {
    const defect = this.entityManager.defectRepository.getById(defectId);
    if (defect) this.openDetailsView(defect);
  }

  // ////////// LIFECYCLE //////////

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateDefects.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.filterHandle.subscribe(),
      this.filterHandle.bindFilteredDefects((filteredDefects) => {
        this.filteredDefects = filteredDefects;
      })
    );
    this.updateDefects();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ////////// METHODS //////////

  private openDetailsView(defect: Defect): void {
    this.openedDefectInfo = {
      defect,
      listItemElement:
        this.defectListRef?.querySelector(`#defect-${defect.id}`) || null
    };

    setTimeout(() => {
      this.isDetailsWidgetOpen = true;
    }, 0);
  }

  private closeDetailsView(): void {
    // Don't set openedDefect to null because the widget closing animation plays
    // - The next openDetailsView will set it again anyway
    this.isDetailsWidgetOpen = false;
  }

  private createNewDefect(): void {
    assertNotNullOrUndefined(
      this.thing,
      'Cannot create a new defect without a thing'
    );
    this.defectCreationService.createDefect({
      name: '',
      description: '',
      dueAt: null,
      status: DefectStatus.OPEN,
      ownerThingId: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
  }

  // ////////// ENTITY UPDATERS //////////

  private updateDefects(): void {
    if (!this.thing) {
      this.defects = [];
      return;
    }

    this.defects = this.entityManager.defectRepository.getByOwnerThingId(
      this.thing.id
    );
    this.filterHandle.setDefects(this.defects);
  }

  // ////////// OBSERVABLES //////////

  protected thingChanged(): void {
    this.updateDefects();
  }

  // ////////// EVENT HANDLERS //////////

  protected handleDetailViewButtonClick(defect: Defect): void {
    this.openDetailsView(defect);
  }

  protected handleCreateDefectButtonClick(): void {
    this.createNewDefect();
  }

  protected handleFilterChangedEvent(event: FilterChangedEvent): void {
    this.defectFilter = event.detail.filter;
    this.filterHandle.setFilter(this.defectFilter);
  }

  protected onCloseIconClicked(): void {
    this.closeDetailsView();
  }

  protected handleExportStarted(event: ExportStartedEvent): void {
    DomEventHelper.fireEvent<ExportStartedEvent>(this.element, {
      name: 'export-started',
      detail: event.detail
    });
  }
}
