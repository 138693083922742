import { autoinject, observable } from 'aurelia-framework';
import {
  Router,
  RouteConfig,
  RouterEvent,
  NavigationInstruction
} from 'aurelia-router';
import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';

import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { GalleryThingJoinedProjectsService } from '../../services/GalleryThingJoinedProjectsService';
import { GalleryThingPictureCreatorService } from '../../services/GalleryThingPictureCreatorService';
import {
  EnsureDefaultPropertiesService,
  DefaultPropertiesEnsurer
} from '../../services/EnsureDefaultPropertiesService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { GalleryThingDateRangeSelectionDialog } from '../../galleryThing/gallery-thing-date-range-selection-dialog/gallery-thing-date-range-selection-dialog';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { Dialogs } from '../../classes/Dialogs';
import { defaultTags as wildbachDefaultTags } from '../../data/Wildbach/defaultTags';
import { PictureMapPositioningDialog } from '../../dialogs/picture-map-positioning-dialog/picture-map-positioning-dialog';
import { ParameterPanel } from '../../aureliaComponents/parameter-panel/parameter-panel';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PictureSource } from '../../aureliaComponents/create-picture-button/create-picture-button';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingTag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import {
  PropertyCreationBaseData,
  ThingProperty
} from '../../classes/EntityManager/entities/Property/types';
import { GalleryThingPictureCaptureService } from '../../services/PictureSketchingService/GalleryThingPictureCaptureService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { GalleryThingPlanBasedOverviewOverlay } from '../../galleryThing/gallery-thing-plan-based-overview-overlay/gallery-thing-plan-based-overview-overlay';
import { ThingViewType } from '../../aureliaComponents/switch-thing-type-button/switch-thing-type-button';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { GalleryThingPictureOverviewAndExport } from '../../galleryThing/gallery-thing-picture-overview-and-export/gallery-thing-picture-overview-and-export';

@autoinject()
export class EditGalleryThing {
  private thing: Thing | null = null;

  private thingGroup: ThingGroup | null = null;

  private thingIsEditable = false;
  private userCanUseRegionExtension = false;
  private canUseViaWildbach = false;
  protected canUseDefectManagement = false;

  private permissionBindingHandle: PermissionBindingHandle;

  private subscriptionManager: SubscriptionManager;

  @observable private isMobile;

  private showThingProperties = false;
  private parameterPanelOpen = false;
  private parameterPanelViewModel: ParameterPanel | null = null;
  private thingTags: Array<ThingTag> = [];

  private selectedPicture: Picture | null = null;

  private entryProperties: Array<PropertyCreationBaseData> = [];

  private isActualizing: boolean = true;

  private useUltraRapidFireWidget = false;

  private defaultPropertiesEnsurer: DefaultPropertiesEnsurer<ThingProperty> | null =
    null;

  protected switchThingTypeButtonIsShown = false;

  protected ThingViewType = ThingViewType;

  protected DeviceInfoHelper = DeviceInfoHelper;

  @observable private predefinedPropertiesConfiguration: {
    properties: Array<TDefaultPropertyConfig>;
  } | null;

  private currentUser: User | null = null;

  private ThingGroupHelper = ThingGroupHelper;

  protected galleryThingPictureOverviewAndExportViewModel: GalleryThingPictureOverviewAndExport | null =
    null;

  private defectIdToNavigateTo: string | null = null;

  private isAttached = false;

  constructor(
    private router: Router,
    private entityManager: AppEntityManager,
    private activeEntitiesService: ActiveEntitiesService,
    private galleryThingJoinedProjectsService: GalleryThingJoinedProjectsService,
    private galleryThingPictureCreatorService: GalleryThingPictureCreatorService,
    private galleryThingPictureCaptureService: GalleryThingPictureCaptureService,
    private activeUserCompanySettingService: ActiveUserCompanySettingService,
    private ensureDefaultPropertiesService: EnsureDefaultPropertiesService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService,
    private currentUserService: CurrentUserService,
    private readonly i18n: I18N,
    private readonly eventAggregator: EventAggregator
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'thing',
        userGroupPropertyOfEntity: 'usergroup',
        editableProperty: 'thingIsEditable'
      },
      permissionProperties: {
        canUseRegionExtension: 'userCanUseRegionExtension',
        canUseViaWildbach: 'canUseViaWildbach',
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });

    this.isMobile = false;
    this.predefinedPropertiesConfiguration = null;
  }

  protected activate(
    params: { thing_id: string; defect_id?: string },
    routeConfig: RouteConfig
  ): void {
    this.thing =
      this.entityManager.thingRepository.getById(params.thing_id) || null;
    assertNotNullOrUndefined(this.thing, 'thing was not found');

    this.activeEntitiesService.setActiveThing(this.thing);

    if (routeConfig.navModel && this.thing?.name)
      routeConfig.navModel.title =
        this.i18n.tr(routeConfig.navModel.title) + ' - ' + this.thing.name;

    if (
      !this.galleryThingJoinedProjectsService.lastDaysRangeIsSet(this.thing.id)
    ) {
      this.galleryThingJoinedProjectsService.setLastDaysRange(this.thing.id, 0);
    }
    this.updateThingTags();

    this.setupDefaultPropertiesEnsurer();
    this.updateProperties();

    this.updateThingGroup();

    this.defectIdToNavigateTo = params.defect_id ?? null;
    this.tryToNavigateToDefect();
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveThing(null);
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          this.isActualizing = isActualizing;
          this.checkAndSyncViaDefaultSettings();
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateThingTags.bind(this),
      150
    );
    this.subscriptionManager.subscribeToPropertyChange(
      this,
      'canUseViaWildbach',
      this.checkAndSyncViaDefaultSettings.bind(this)
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.permissionBindingHandle.subscribe();

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged((currentUser) => {
        this.currentUser = currentUser;
      })
    );
    this.currentUser = this.currentUserService.getCurrentUser();

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'ultraRapidFireWidget.useUltraRapidFireWidget',
        (useUltraRapidFireWidget) => {
          this.useUltraRapidFireWidget = useUltraRapidFireWidget;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.predefinedPropertiesConfiguration',
        (predefinedPropertiesConfiguration) => {
          this.predefinedPropertiesConfiguration =
            predefinedPropertiesConfiguration;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.predefinedEntryProperties',
        (predefinedEntryProperties) => {
          this.entryProperties = predefinedEntryProperties?.properties ?? [];
        }
      )
    );

    this.tryToNavigateToDefect();
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
    this.subscriptionManager.disposeSubscriptions();

    this.isAttached = false;
  }

  protected predefinedPropertiesConfigurationChanged(): void {
    this.setupDefaultPropertiesEnsurer();
    this.updateProperties();
  }

  private updateProperties(): void {
    let properties: Array<ThingProperty> = [];
    if (this.thing) {
      properties = this.entityManager.propertyRepository.getByThingId(
        this.thing.id
      );
    }
    this.defaultPropertiesEnsurer &&
      this.defaultPropertiesEnsurer.setProperties(properties);
  }

  private setupDefaultPropertiesEnsurer(): void {
    const thing = this.thing;
    if (!thing) return;

    if (this.defaultPropertiesEnsurer) {
      this.defaultPropertiesEnsurer.unsubscribe();
    }

    const defaultPropertyConfigs =
      this.predefinedPropertiesConfiguration &&
      this.predefinedPropertiesConfiguration.properties
        ? this.predefinedPropertiesConfiguration.properties
        : [];

    this.defaultPropertiesEnsurer =
      this.ensureDefaultPropertiesService.createEnsurer({
        defaultPropertyConfigs: defaultPropertyConfigs,
        propertyCreationPostProcessor: (propertyConfig) => {
          return {
            ownerUserGroupId: thing.ownerUserGroupId,
            thing: thing.id,
            ...propertyConfig
          };
        }
      });

    this.defaultPropertiesEnsurer.subscribe();
  }

  private checkAndSyncViaDefaultSettings(): void {
    if (this.isActualizing) return;
    if (this.canUseViaWildbach) {
      this.syncWildbachDefaultTags();
    }
  }

  private syncWildbachDefaultTags(): void {
    if (!this.thing) return;

    for (const [position, tagName] of wildbachDefaultTags.entries()) {
      this.entityManager.tagRepository.getOrCreateThingTag(
        tagName,
        this.thing.id,
        this.thing.ownerUserGroupId,
        position
      );
    }
  }

  private updateThingTags(): void {
    if (this.thing) {
      this.thingTags = this.entityManager.tagRepository.getByThingId(
        this.thing.id
      );
    } else {
      this.thingTags = [];
    }
  }

  private updateThingGroup(): void {
    if (this.thing && this.thing.thingGroupId) {
      this.thingGroup =
        this.entityManager.thingGroupRepository.getById(
          this.thing.thingGroupId
        ) || null;
    } else {
      this.thingGroup = null;
    }
  }

  protected isMobileChanged(): void {
    GlobalMenu.setVisible(this, this.isMobile);
  }

  private handleToggleParameterPanelClick(): void {
    if (this.parameterPanelViewModel) {
      void this.parameterPanelViewModel.toggle();
    }
  }

  private async handleCreateNewPictureClick(
    pictureSource: PictureSource
  ): Promise<void> {
    if (!this.thing) return;

    const pictureData = {
      thing: this.thing,
      properties: this.entryProperties
    };

    switch (pictureSource.name) {
      case 'urfm':
        this.galleryThingPictureCreatorService.capturePictureWithUltraRapidFireWidget(
          pictureData
        );
        break;

      case 'camera':
        this.galleryThingPictureCaptureService.capturePictureWithCaptureWidget(
          pictureData
        );
        break;

      case 'sketch':
        await this.galleryThingPictureCreatorService.createWhitePicture(
          pictureData
        );
        break;

      default:
        throw new Error(`unknown pictureSource "${pictureSource.name}"`);
    }
  }

  private handleEditDateRangeButtonClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't EditGalleryThing.handleEditDateRangeButtonClick without a thing"
    );

    void GalleryThingDateRangeSelectionDialog.open(this.thing.id);
  }

  private handleTagCreated(tagName: string): void {
    if (!this.thing) return;
    this.entityManager.tagRepository.getOrCreateThingTag(
      tagName,
      this.thing.id,
      this.thing.ownerUserGroupId
    );
  }

  protected handleTagRemoved(tag: ThingTag): void {
    void Dialogs.deleteEntityDialog(tag).then(() => {
      this.entityManager.tagRepository.delete(tag);
    });
  }

  private handlePositionPictureClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't EditGalleryThing.handleEditDateRangeButtonClick without a thing"
    );

    assertNotNullOrUndefined(
      this.selectedPicture,
      "can't EditGalleryThing.handleEditDateRangeButtonClick without a selectedPicture"
    );

    void PictureMapPositioningDialog.open(this.thing, this.selectedPicture);
  }

  protected handleOpenPlanBasedOverviewClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleOpenPlanBasedOverviewClick without thing'
    );
    void GalleryThingPlanBasedOverviewOverlay.open({
      thing: this.thing
    });
  }

  private tryToNavigateToDefect(): void {
    if (
      this.isAttached &&
      this.defectIdToNavigateTo &&
      this.galleryThingPictureOverviewAndExportViewModel
    ) {
      this.galleryThingPictureOverviewAndExportViewModel.openDetailsViewForDefectId(
        this.defectIdToNavigateTo
      );
    }
  }
}
