import { bindable, autoinject, computedFrom } from 'aurelia-framework';

import { GalleryThingExportOverview } from '../gallery-thing-export-overview/gallery-thing-export-overview';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { ExportStartedEvent } from '../gallery-thing-filter/gallery-thing-picture-filter/gallery-thing-picture-filter';
import { computed } from '../../hooks/computed';
import { activeUserCompanySetting } from '../../hooks/dependencies';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DefectOverview } from '../../defectComponents/defect-overview/defect-overview';

@autoinject()
export class GalleryThingPictureOverviewAndExport {
  @bindable public thing: Thing | null = null;

  @bindable public editable = false;

  protected readonly GalleryThingViewAvailableNames =
    GalleryThingViewAvailableNames;

  protected availableViews: Record<
    GalleryThingViewAvailableNames,
    GalleryThingView
  > = {
    picture: {
      labelTk: 'galleryThing.pictureOverviewAndExport.pictureViewLabel',
      name: GalleryThingViewAvailableNames.PICTURE
    },
    defects: {
      labelTk: 'galleryThing.pictureOverviewAndExport.defectsViewLabel',
      name: GalleryThingViewAvailableNames.DEFECTS
    },
    export: {
      labelTk: 'galleryThing.pictureOverviewAndExport.exportViewLabel',
      name: GalleryThingViewAvailableNames.EXPORT
    },
    plan: {
      labelTk: 'galleryThing.pictureOverviewAndExport.planViewLabel',
      name: GalleryThingViewAvailableNames.PLAN
    }
  };

  protected selectedView: GalleryThingView;

  protected pictureOverviewElement: HTMLElement | null = null;

  protected defectOverviewViewModel: DefectOverview | null = null;
  protected exportOverviewViewModel: GalleryThingExportOverview | null = null;

  private canUseDefectManagement = false;

  private permissionBindingHandle: PermissionBindingHandle;

  private subscriptionManager: SubscriptionManager;

  constructor(
    permissionBindingService: PermissionBindingService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.selectedView = this.availableViews.picture;

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public goToView(viewName: GalleryThingViewAvailableNames): void {
    this.selectedView = this.availableViews[viewName];
  }

  public openDetailsViewForDefectId(defectId: string): void {
    this.goToView(this.availableViews.defects.name);
    this.defectOverviewViewModel?.openDetailsViewForDefectId(defectId);
  }

  protected attached(): void {
    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleExportPanelExportStarted(event: ExportStartedEvent): void {
    assertNotNullOrUndefined(
      this.exportOverviewViewModel,
      "Can't handle an export without an ExportOverviewViewModel!"
    );

    this.selectedView = this.availableViews.export;

    if (event.detail.reportId) {
      this.exportOverviewViewModel.autoScrollToReportId(event.detail.reportId);
    }
  }

  @computedFrom('availableViews', 'usesPlanOverview', 'canUseDefectManagement')
  protected get allowedViewsList(): Array<GalleryThingView> {
    const allowedViews = [];
    for (const [id, view] of Object.entries(this.availableViews)) {
      const enumId = id as GalleryThingViewAvailableNames;
      switch (enumId) {
        case GalleryThingViewAvailableNames.DEFECTS:
          if (this.canUseDefectManagement) allowedViews.push(view);
          break;
        case GalleryThingViewAvailableNames.PLAN:
          if (this.usesPlanOverview) allowedViews.push(view);
          break;
        default:
          allowedViews.push(view);
      }
    }
    return allowedViews;
  }

  @computed(activeUserCompanySetting('via.usePlanOverview'))
  protected get usesPlanOverview(): boolean {
    return (
      this.activeUserCompanySettingService.getSettingProperty(
        'via.usePlanOverview'
      ) ?? false
    );
  }
}

type GalleryThingView = {
  labelTk: string;
  name: GalleryThingViewAvailableNames;
};

enum GalleryThingViewAvailableNames {
  PICTURE = 'picture',
  DEFECTS = 'defects',
  EXPORT = 'export',
  PLAN = 'plan'
}
