import { EntityInfo } from '@record-it-npm/synchro-common';
import { GeneralFileDtoUtils } from 'common/Types/Entities/GeneralFile/GeneralFileDtoUtils';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityRepository } from '../../../../classes/EntityManager/base/AppEntityRepository';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { generalFileEntityInfo } from '../../../../classes/EntityManager/entities/GeneralFile/generalFileEntityInfo';
import { GeneralFile } from '../../../../classes/EntityManager/entities/GeneralFile/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { BoundAdaptersContainer } from '../../utils/BoundAdaptersContainer/BoundAdaptersContainer';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class GeneralFileAdapter implements EntityAdapter<GeneralFile> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private readonly parentEntityAdaptersContainer =
    BoundAdaptersContainer.createCreationFunction<EntityAdapterWithCanEditGeneralFiles>()(
      [
        EntityName.DefectComment,
        EntityName.MapLayer,
        EntityName.ProcessTaskAppointment,
        EntityName.ProcessTaskComment,
        EntityName.ProcessTaskChecklistEntry,
        EntityName.Property
      ]
    );

  constructor(options: GeneralFileAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.parentEntityAdaptersContainer.subscribe({
        bindAdapter,
        updateBindings
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return this.parentEntityAdaptersContainer.getDependenciesAreLoaded();
  }

  public canDeleteEntity(generalFile: GeneralFile): boolean {
    return this.canEditGeneralFile(generalFile);
  }

  public canEditField(generalFile: GeneralFile): boolean {
    return this.canEditGeneralFile(generalFile);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.GeneralFile,
    GeneralFile
  > {
    return generalFileEntityInfo;
  }

  private canEditGeneralFile(generalFile: GeneralFile): boolean {
    const parentEntityInfo = GeneralFileDtoUtils.getParentEntityInfo({
      generalFileDto: generalFile
    });

    const parentEntity = (
      this.entityManager.entityRepositoryContainer.getByEntityName(
        parentEntityInfo.entityName
      ) as AppEntityRepository<any>
    ).getById(parentEntityInfo.id);

    if (!parentEntity) {
      return false;
    }

    const adapter =
      this.parentEntityAdaptersContainer.getAdapterForAnyEntityName(
        parentEntityInfo.entityName
      );

    if (!adapter) {
      return false;
    }

    return adapter.canEditGeneralFiles(parentEntity);
  }
}

export type GeneralFileAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};

type EntityAdapterWithCanEditGeneralFiles = EntityAdapter<any> & {
  canEditGeneralFiles: (entity: any) => boolean;
};
