import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { PlainVector, Vector } from 'common/Geometry/Vector';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';

import { TZoomBoxResizerHelperPicturePositionInfo } from '../../../aureliaComponents/zoom-box/ZoomBoxResizerHelper';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

/**
 * @event marking-clicked
 */
@autoinject()
export class PictureMarkingOverlayMarking {
  @bindable public marking: IPictureMarking | null = null;

  @bindable
  public picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  @bindable public isCurrentMarking = false;

  @bindable public isReferenceMarking = false;

  protected shownMarking: IPictureMarking | null = null;

  private readonly domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected markingChanged(): void {
    this.updateMarkingPosition();
  }

  protected picturePositionInfoChanged(): void {
    this.updateMarkingPosition();
  }

  private updateMarkingPosition(): void {
    if (!this.marking || !this.picturePositionInfo) {
      this.shownMarking = null;
      return;
    }

    const markingPosition = this.getPosition({
      x: parseFloat(this.marking.left),
      y: parseFloat(this.marking.top)
    });

    let zoomBoxTopLeft = null;
    let zoomBoxBottomRight = null;

    if (
      this.marking.boxBottom != null &&
      this.marking.boxLeft != null &&
      this.marking.boxRight != null &&
      this.marking.boxTop != null
    ) {
      zoomBoxTopLeft = this.getPosition({
        x: this.marking.boxLeft,
        y: this.marking.boxTop
      });

      zoomBoxBottomRight = this.getPosition({
        x: this.marking.boxRight,
        y: this.marking.boxBottom
      });
    }

    this.shownMarking = {
      top: markingPosition?.getY() + '%',
      left: markingPosition?.getX() + '%',
      boxTop: zoomBoxTopLeft?.getY(),
      boxLeft: zoomBoxTopLeft?.getX(),
      boxBottom: zoomBoxBottomRight?.getY(),
      boxRight: zoomBoxBottomRight?.getX()
    };
  }

  private getPosition(position: PlainVector): Vector | null {
    if (!this.picturePositionInfo) return null;

    const relativeX = position.x / 100;
    const relativeY = position.y / 100;
    const relativePosition = new Vector(relativeX, relativeY);

    const returnValue = this.picturePositionInfo.size
      .clone()
      .multiplyVector(relativePosition) // convert % to px
      .addVector(this.picturePositionInfo.topLeftPosition) // add the offset
      .divideVector(this.picturePositionInfo.containerSize) // get a percentual value relative to the container
      .scale(100); // convert to percent

    return returnValue;
  }

  @computedFrom(
    'marking.boxBottom',
    'marking.boxLeft',
    'marking.boxRight',
    'marking.boxTop'
  )
  protected get hasZoomBoxExtents(): boolean {
    return (
      this.marking?.boxBottom != null &&
      this.marking.boxLeft != null &&
      this.marking.boxRight != null &&
      this.marking.boxTop != null
    );
  }

  protected handleMarkingClicked(): void {
    DomEventHelper.fireEvent<MarkingClickedEvent>(this.domElement, {
      name: 'marking-clicked',
      detail: null
    });
  }
}

export type MarkingClickedEvent = NamedCustomEvent<'marking-clicked', null>;
