import { bindable } from 'aurelia-framework';

import { GalleryThingPictureOverviewEntry } from '../../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';

export class PictureMapPositioningGpsModePictureSelection {
  @bindable
  public picturesForSelection: Array<GalleryThingPictureOverviewEntry> = [];

  /**
   * read only
   */
  @bindable
  public selectedPicture: GalleryThingPictureOverviewEntry | null = null;

  protected handlePictureSelectionPictureClick(
    picture: GalleryThingPictureOverviewEntry
  ): void {
    if (this.selectedPicture === picture) this.selectedPicture = null;
    else this.selectedPicture = picture;
  }
}
