import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import {
  FilterResult,
  PersonFilterer
} from '../../classes/EntityManager/entities/Person/PersonFilterer';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import {
  Person,
  PersonCreationEntity
} from '../../classes/EntityManager/entities/Person/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';

@autoinject()
export class CreatePersonDialog {
  public static async open(
    options: CreatePersonDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private static TEMPORARY_GROUP_NAME = 'CreatePersonDialog';

  private static PAGE_SEARCH = 'search' as const;
  private static PAGE_CREATE = 'create' as const;

  private readonly personFilterer: PersonFilterer;

  private onDialogClosed: OnDialogClosed | null = null;
  private personToCreate: Person | null = null;
  private lastSelectedPerson: Person | null = null;
  private searchResults: Array<FilterResult> = [];
  private currentPageName: 'search' | 'create' = CreatePersonDialog.PAGE_SEARCH;

  protected dialog: RecordItDialog | null = null;
  protected readonly PersonUtils = PersonUtils;
  protected readonly CreatePersonDialog = CreatePersonDialog;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.personFilterer = new PersonFilterer();
  }

  public open(options: CreatePersonDialogOpenOptions): void {
    this.personToCreate = this.entityManager.personRepository.create({
      temporaryGroupName: CreatePersonDialog.TEMPORARY_GROUP_NAME,
      ownerUserGroupId: options.userGroupId,
      ...options.personToCreate,
      shadowEntity: true
    });

    if (options.relatedPersonIds) {
      void this.createRelations(this.personToCreate, options.relatedPersonIds);
    }

    this.onDialogClosed = options.onDialogClosed ?? null;

    this.currentPageName = options.personToCreate
      ? CreatePersonDialog.PAGE_CREATE
      : CreatePersonDialog.PAGE_SEARCH;

    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const person = this.lastSelectedPerson;

    this.onDialogClosed = null;
    this.personToCreate = null;
    this.lastSelectedPerson = null;
    this.currentPageName = CreatePersonDialog.PAGE_SEARCH;
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreatePersonDialog.TEMPORARY_GROUP_NAME
    );

    onClosed?.(person);
  }

  private async createRelations(
    personToCreate: Person,
    relatedPersonIds: Array<string>
  ): Promise<void> {
    const canCreatePersonToPersons =
      await this.permissionsService.useAdapterOnce({
        entityName: EntityName.Person,
        useAdapter: (adapter) =>
          adapter.canCreatePersonToPersons(personToCreate)
      });

    if (canCreatePersonToPersons && personToCreate) {
      for (const relatedPersonId of relatedPersonIds) {
        this.entityManager.personToPersonRepository.create({
          person1Id: personToCreate.id,
          person2Id: relatedPersonId,
          ownerUserGroupId: personToCreate.ownerUserGroupId,
          temporaryGroupName: personToCreate.temporaryGroupName,
          shadowEntity: personToCreate.shadowEntity
        });
      }
    }
  }

  protected handleSearchTermChanged(): void {
    if (!this.personToCreate) {
      return;
    }

    this.personFilterer.clear();
    this.personFilterer.addPersonToIgnore(this.personToCreate);

    if (this.personToCreate.companyName) {
      this.personToCreate.company = true;
      this.personFilterer.addFilterText(this.personToCreate.companyName);
    } else {
      this.personToCreate.company = false;
    }

    if (this.personToCreate.firstName) {
      this.personFilterer.addFilterText(this.personToCreate.firstName);
    }

    if (this.personToCreate.lastName) {
      this.personFilterer.addFilterText(this.personToCreate.lastName);
    }

    const availablePersons =
      this.entityManager.personRepository.getByUserGroupId(
        this.personToCreate.ownerUserGroupId
      );
    this.searchResults = this.personFilterer.filter(availablePersons);
  }

  protected handleCreatePersonButtonClick(): void {
    this.currentPageName = CreatePersonDialog.PAGE_CREATE;
  }

  protected handleBackToSearchButtonClick(): void {
    this.currentPageName = CreatePersonDialog.PAGE_SEARCH;
  }

  protected handleAcceptButtonClicked(): void {
    if (this.currentPageName === CreatePersonDialog.PAGE_CREATE) {
      this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
        CreatePersonDialog.TEMPORARY_GROUP_NAME
      );
      this.lastSelectedPerson = this.personToCreate;
    }
  }

  protected handleSearchResultClick(result: FilterResult): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CreatePersonDialog.handleSearchResultClick without dialog"
    );

    this.lastSelectedPerson = result.person;
    this.dialog.close();
  }
}

export type CreatePersonDialogOpenOptions = {
  userGroupId: string;
  personToCreate?: PersonCreationEntity;
  /**
   * automatically create relations to these personIds
   */
  relatedPersonIds?: Array<string>;
  /**
   * receives the created/selected person or null if the dialog has just been closed
   */
  onDialogClosed?: OnDialogClosed | null;
};

type OnDialogClosed = (person: Person | null) => void;
