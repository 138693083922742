import { assertNotNullOrUndefined } from 'common/Asserts';

import { Pagination } from '../aureliaComponents/pagination/pagination';
import { RecordItHeader } from '../aureliaComponents/record-it-header/record-it-header';
import { Utils } from './Utils/Utils';

export class ScrollHelper {
  private static defaultScrollToItemOptions = {
    topOffset: 0
  };

  /**
   * @param element
   * @param duration - 0 for no animation, null for default duration
   * @param options
   * @returns {Promise<void>}
   */
  public static scrollToItem(
    element: HTMLElement,
    duration?: number | null,
    options?: ScrollToItemOptions | null
  ): Promise<void> {
    options = Object.assign({}, this.defaultScrollToItemOptions, options);
    const headerHeight = RecordItHeader.getHeaderHeight();
    const scrollTop =
      Utils.getElementOffset(element).top -
      (headerHeight != null ? headerHeight : 0) -
      options.topOffset;

    return this.scrollToPosition(scrollTop, duration);
  }

  /**
   * @param element
   * @param duration - 0 for no animation, null for default duration
   * @returns {Promise<void>}
   */
  public static scrollToItemCentered(
    element: HTMLElement,
    duration?: number | null
  ): Promise<void> {
    const scrollTop = this.getCenteredScrollTopForElement(element);
    return this.scrollToPosition(scrollTop, duration);
  }

  /**
   * returns the scrollTop position for the element to be centered
   */
  public static getCenteredScrollTopForElement(element: HTMLElement): number {
    const headerHeight = RecordItHeader.getHeaderHeight() || 0;
    const elementTopOffset = Utils.getElementOffset(element).top;

    const viewHeight = document.documentElement.clientHeight - headerHeight;

    const elementHeight = parseFloat(getComputedStyle(element).height);

    return elementTopOffset - headerHeight - viewHeight / 2 + elementHeight / 2;
  }

  /**
   * @param scrollTop
   * @param duration - 0 for no animation, null for default duration
   * @returns {Promise<void>}
   */
  public static scrollToPosition(
    scrollTop: number,
    duration?: number | null
  ): Promise<void> {
    return new Promise((resolve) => {
      const scrollElement = this.getMainScrollingElement();

      // if we are already at the correct position we don't have to animate it
      // also the same start value and end value for the tween will result in a velocity bug
      // which will pass null instead of the actual value in the first run
      if (scrollTop === scrollElement.scrollTop) {
        resolve();
        return;
      }

      if (duration == null || duration > 0) {
        // eslint-disable-next-line new-cap
        $.Velocity(
          scrollElement,
          {
            tween: [scrollTop, scrollElement.scrollTop]
          },
          {
            duration: duration || 1000,
            progress: (elements, complete, remaining, start, tweenValue) => {
              scrollElement.scrollTop = tweenValue;
            },
            complete: () => resolve(),
            mobileHA: false
          }
        );
      } else {
        scrollElement.scrollTop = scrollTop;
        resolve();
      }
    });
  }

  public static getMainScrollingElement(): HTMLElement {
    if (!document.scrollingElement) {
      throw new Error('no scrolling element found');
    }

    return document.scrollingElement as HTMLElement;
  }

  public static scrollElement(
    element: HTMLElement,
    direction: 'top' | 'left',
    value: number
  ): void {
    $(element).animate({
      scrollTop: direction === 'top' ? value : undefined,
      scrollLeft: direction === 'left' ? value : undefined
    });
  }

  public static scrollElementToBottom(
    element: HTMLElement,
    direction: 'top' | 'left'
  ): void {
    const computed = window.getComputedStyle(element);

    const maxScrollTop = Math.max(
      element.scrollHeight - parseFloat(computed.height)
    );

    this.scrollElement(element, direction, maxScrollTop);
  }

  public static async autoScrollToListItem<T>(
    querySelector: string,
    pagination?: Pagination<T> | null,
    item?: T,
    checkAttachedCallback?: () => boolean
  ): Promise<void> {
    const element = await this.autoScrollToElementWithPagination(
      querySelector,
      pagination,
      item,
      checkAttachedCallback
    );

    assertNotNullOrUndefined(element, 'cannot highlight non-existing element');

    const vm = Utils.getViewModelOfElement<T>(element);
    assertNotNullOrUndefined(vm, 'cannot highlight without a view model');

    (vm as T & { highlight?: () => void }).highlight?.();
  }

  public static async autoScrollToSubtleListItem<T>(
    querySelector: string,
    pagination?: Pagination<T> | null,
    item?: T,
    checkAttachedCallback?: () => boolean
  ): Promise<void> {
    const element = await this.autoScrollToElementWithPagination(
      querySelector,
      pagination,
      item,
      checkAttachedCallback
    );

    assertNotNullOrUndefined(element, 'cannot highlight non-existing element');

    element.classList.add('record-it-background-highlighted');

    setTimeout(() => {
      element.classList.remove('record-it-background-highlighted');
    }, 500);
  }

  public static autoScrollToElementWithPagination<T>(
    querySelector: string,
    pagination?: Pagination<T> | null,
    item?: T,
    checkAttachedCallback?: () => boolean
  ): Promise<HTMLElement | null> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (checkAttachedCallback && !checkAttachedCallback()) return;

        if (pagination && item) pagination.goToItem(item);

        setTimeout(() => {
          const element = document.querySelector(
            querySelector
          ) as HTMLElement | null;

          if (element) {
            void ScrollHelper.scrollToItemCentered(element);
            resolve(element);
          } else {
            reject(new Error('[ScrollHelper] element to scroll to not found'));
          }
        }, 5);
      }, 10);
    });
  }
}

type ScrollToItemOptions = {
  /** offset of the element to the header in px, default 0 (it will touch the header) */
  topOffset: number;
};
