import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskAppointmentContactEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentContact/processTaskAppointmentContactEntityInfo';
import { ProcessTaskAppointmentContact } from '../../../../classes/EntityManager/entities/ProcessTaskAppointmentContact/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskAppointmentContactAdapter
  implements EntityAdapter<ProcessTaskAppointmentContact>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private userGroupIdsWithSmsCredentials: Set<string> | null = null;

  constructor(options: ProcessTaskAppointmentContactAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ userGroupIdsWithSmsCredentials }) => {
          this.userGroupIdsWithSmsCredentials = userGroupIdsWithSmsCredentials;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.userGroupIdsWithSmsCredentials != null
    );
  }

  public canDeleteEntity(appointment: ProcessTaskAppointmentContact): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanDeleteProcessTaskAppointmentContacts();
  }

  public canEditField(appointment: ProcessTaskAppointmentContact): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(appointment.ownerUserGroupId)
      .getCanUpdateProcessTaskAppointmentContacts();
  }

  public canSeeSmsNotificationSetting(
    appointment: ProcessTaskAppointmentContact
  ): boolean {
    if (!this.userGroupIdsWithSmsCredentials) {
      return false;
    }

    return this.userGroupIdsWithSmsCredentials.has(
      appointment.ownerUserGroupId
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskAppointmentContact,
    ProcessTaskAppointmentContact
  > {
    return processTaskAppointmentContactEntityInfo;
  }
}

export type ProcessTaskAppointmentContactAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
