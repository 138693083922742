import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { PersonContact } from '../../../../classes/EntityManager/entities/PersonContact/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { personContactEntityInfo } from '../../../../classes/EntityManager/entities/PersonContact/personContactEntityInfo';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { PersonIdToThingIdsMap } from '../../../../computedValues/computers/PersonIdToThingIdsComputer/PersonIdToThingIdsComputerUtils';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { PersonIdToThingIdsComputer } from '../../../../computedValues/computers/PersonIdToThingIdsComputer/PersonIdToThingIdsComputer';
import { ThingAuthorizationComputer } from '../../../../computedValues/computers/ThingAuthorizationComputer/ThingAuthorizationComputer';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PersonCategories } from 'common/Types/PersonCategories';

export class PersonContactAdapter implements EntityAdapter<PersonContact> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private personIdToThingIds: PersonIdToThingIdsMap | null = null;
  private thingIdsWhereUserIsAuthorized: Set<string> | null = null;
  private groupAdminUserGroupIds: Set<string> | null = null;
  private groupAdminOnlyPersonCategoryNames: Set<string> | null = null;

  constructor(options: PersonContactAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ groupAdminUserGroupIds }) => {
          this.groupAdminUserGroupIds = groupAdminUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: PersonIdToThingIdsComputer,
        computeData: {},
        callback: ({ personIdToThingIds }) => {
          this.personIdToThingIds = personIdToThingIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ThingAuthorizationComputer,
        computeData: {},
        callback: ({ thingIdsWhereUserIsAuthorized }) => {
          this.thingIdsWhereUserIsAuthorized = thingIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.personCategories',
        (personCategories: PersonCategories | null) => {
          const groupAdminOnlyPersonCategoryNames = new Set<string>();

          if (personCategories) {
            for (const category of personCategories) {
              if (category.onlyEditableByGroupAdmins) {
                groupAdminOnlyPersonCategoryNames.add(category.value);
              }
            }
          }

          this.groupAdminOnlyPersonCategoryNames =
            groupAdminOnlyPersonCategoryNames;
          updateBindings();
        }
      )
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getDependenciesAreLoaded(): boolean {
    return (
      this.roleBasedPermissions != null &&
      this.personIdToThingIds != null &&
      this.thingIdsWhereUserIsAuthorized != null &&
      this.groupAdminUserGroupIds != null &&
      this.groupAdminOnlyPersonCategoryNames != null
    );
  }

  public canDeleteEntity(personContact: PersonContact): boolean {
    return this.checkPermission({
      personContact,
      checkRolePermission: (permissions) =>
        permissions.getCanDeletePersonContacts()
    });
  }

  public canEditField(personContact: PersonContact): boolean {
    return this.checkPermission({
      personContact,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdatePersonContacts()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.PersonContact,
    PersonContact
  > {
    return personContactEntityInfo;
  }

  private checkPermission({
    personContact,
    checkRolePermission
  }: {
    personContact: PersonContact;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    const person = this.entityManager.personRepository.getById(
      personContact.personId
    );

    if (!person) {
      return false;
    }

    return EntityAdapterUtils.checkPermissionBasedOnPerson({
      person,
      personIdToThingIds: this.personIdToThingIds,
      groupAdminOnlyPersonCategoryNames: this.groupAdminOnlyPersonCategoryNames,
      groupAdminUserGroupIds: this.groupAdminUserGroupIds,
      roleBasedPermissions: this.roleBasedPermissions,
      thingIdsWhereUserIsAuthorized: this.thingIdsWhereUserIsAuthorized,
      checkRolePermission
    });
  }
}

export type PersonContactAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
};
