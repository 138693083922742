import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Vector } from 'common/Geometry/Vector';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { PointerEventHelper } from '../../classes/PointerEventHelper';

/**
 * @event marking-added - MarkingAddedEvent
 * @event marking-removed - MarkingRemovedEvent
 */

@autoinject()
export class PictureMarkableByClick {
  @bindable public picture: Picture | null = null;
  @bindable public requiredNumberOfMarkings: number = 0;

  /**
   * read only
   */
  @bindable public markings: Array<IPictureMarking> = [];

  private pictureElement: HTMLElement | null = null;
  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handlePictureClick(event: PointerEvent): void {
    if (
      this.requiredNumberOfMarkings &&
      this.markings.length === this.requiredNumberOfMarkings
    )
      return;

    const picturePosition = this.getPicturePositionForMouseEvent(event);

    assertNotNullOrUndefined(
      picturePosition,
      "can't handleGpsPictureClick without picturePosition"
    );

    const marking: IPictureMarking = {
      top: picturePosition.getY().toString() + '%',
      left: picturePosition.getX().toString() + '%'
    };

    this.markings.push(marking);
    this.fireMarkingAddedEvent(marking);
  }

  protected handleMarkingClick(marking: IPictureMarking): void {
    ArrayUtils.remove(this.markings, marking);
    this.fireMarkingRemovedEvent(marking);
  }

  private fireMarkingAddedEvent(marking: IPictureMarking): void {
    if (!this.domElement) return;

    DomEventHelper.fireEvent<MarkingAddedEvent>(this.domElement, {
      name: 'marking-added',
      detail: {
        marking: marking
      }
    });
  }

  private fireMarkingRemovedEvent(marking: IPictureMarking): void {
    if (!this.domElement) return;

    DomEventHelper.fireEvent<MarkingRemovedEvent>(this.domElement, {
      name: 'marking-removed',
      detail: {
        marking: marking
      }
    });
  }

  private getPicturePositionForMouseEvent(event: MouseEvent): Vector | null {
    assertNotNullOrUndefined(
      this.pictureElement,
      "can't getContainerPointForMouseEvent without scrollContainer"
    );

    const clientRect = this.pictureElement.getBoundingClientRect();

    if (!PointerEventHelper.mouseIsInsideClientRect(event, clientRect))
      return null;

    const pictureSize = new Vector(clientRect.width, clientRect.height);
    const pictureClickPosition = new Vector(
      event.clientX - clientRect.left,
      event.clientY - clientRect.top
    );

    return pictureClickPosition.divideVector(pictureSize).scale(100);
  }
}

export type MarkingAddedEvent = NamedCustomEvent<
  'marking-added',
  { marking: IPictureMarking }
>;

export type MarkingRemovedEvent = NamedCustomEvent<
  'marking-removed',
  { marking: IPictureMarking }
>;
